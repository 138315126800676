import { MaybeRef } from '@vueuse/shared';
import { computed, ComputedRef, unref } from 'vue';
import { FeedbackItem, FeedbackType } from '../api/schema/settings';
import { useProjectSettings } from './store';

/**
 * Возвращает массив FeedbackItem[] для выбранного типа.
 * Если тип не выбран, то отдаются все типы.
 */
export function useFeedbackItems(
  type?: MaybeRef<FeedbackType>
): ComputedRef<FeedbackItem[]> {
  const { feedback } = useProjectSettings();

  return computed(() => {
    let items = feedback.value.items;

    if (typeof unref(type) !== undefined) {
      items = items.filter((item) => item.type === unref(type));
    }
    return items;
  });
}
