
import { defineComponent, PropType } from 'vue';
import { ProductPropertyValues, ProductType } from '@spa/api/schema/catalog';

export default defineComponent({
  name: 'PropertyValues',
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
  },
  setup(props: { product: ProductType }) {
    let flatProps = props.product.property_values || [];
    flatProps = flatProps.sort((a, b) => b.priority - a.priority);
    const propertyValues: KeyVal<ProductPropertyValues[]> = {};
    const groups: KeyVal<string> = {};
    flatProps.forEach((prop) => {
      const groupID = prop.group_id || 0;
      const props: ProductPropertyValues[] = propertyValues[groupID] || [];
      props.push(prop);
      propertyValues[groupID] = props;
      groups[groupID] = prop.group_name || gettext('Другие');
    });
    return {
      groups: Object.entries(groups).sort(([a], [b]) => +b - +a),
      propertyValues,
    };
  },
});
