import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a5195b3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "actions-block__price w-100 d-flex flex-column align-items-end align-items-lg-start"
}
const _hoisted_2 = {
  key: 0,
  class: "actions-block__price__nds"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  class: "actions-block__price__rrc-price"
}
const _hoisted_5 = {
  key: 1,
  class: "actions-block__actions"
}
const _hoisted_6 = {
  key: 0,
  class: "actions-block__actions__warehouses-title ml-3"
}
const _hoisted_7 = {
  key: 1,
  class: "actions-block__actions__warehouses-select"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_price_with_coupon = _resolveComponent("price-with-coupon")!
  const _component_warehouses_select = _resolveComponent("warehouses-select")!
  const _component_add_to_cart_button = _resolveComponent("add-to-cart-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showActions)
      ? _renderSlot(_ctx.$slots, "top", { key: 0 }, undefined, true)
      : _createCommentVNode("", true),
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["actions-block align-items-start", { 'actions-block-card': _ctx.asCard, 'mt-0': _ctx.isModal }])
        }, [
          (_ctx.modulesEnabled.price)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", null, [
                  (_ctx.showPriceRange)
                    ? (_openBlock(), _createBlock(_component_price_with_coupon, {
                        key: 0,
                        class: "price-large price-large-dark",
                        product: _ctx.detailProduct,
                        "has-price-offer": _ctx.hasPriceOffer
                      }, null, 8, ["product", "has-price-offer"]))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.isShowNds)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.ndsToShow), 1))
                  : _createCommentVNode("", true),
                (_ctx.detailProduct.is_item_on_order)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.gettext('Цена по запросу')), 1)
                    ]))
                  : (_ctx.hasRrcPrice && _ctx.modification && _ctx.modification.warehouses.length && _ctx.modulesEnabled.rest)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.gettext('РРЦ')) + ": " + _toDisplayString(_ctx.transformMoney(_ctx.detailProduct.rrc_price)) + " " + _toDisplayString(_ctx.detailProduct.currency), 1))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.catalogConfig.is_reference_mode && (_ctx.user.is_contractor || !_ctx.user.is_auth))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.modification && _ctx.modification.warehouses.length && _ctx.modulesEnabled.rest)
                  ? (_openBlock(), _createElementBlock("label", _hoisted_6, _toDisplayString(_ctx.gettext('Склад')), 1))
                  : _createCommentVNode("", true),
                (_ctx.modulesEnabled.rest)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_ctx.modification && _ctx.modification.warehouses.length)
                        ? (_openBlock(), _createBlock(_component_warehouses_select, {
                            key: 0,
                            modification_id: _ctx.baseModification.id,
                            warehouses: _ctx.detailProduct.warehouses,
                            measure_unit_name: _ctx.detailProduct.measure_unit_name,
                            selected: _ctx.detailProduct.default_warehouse
                          }, null, 8, ["modification_id", "warehouses", "measure_unit_name", "selected"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.modulesEnabled.buy)
                  ? _withDirectives((_openBlock(), _createBlock(_component_add_to_cart_button, {
                      key: 2,
                      product: _ctx.detailProduct,
                      "stock-count": _ctx.detailProduct.stock_count,
                      class: "actions-block__actions__add-to-cart"
                    }, null, 8, ["product", "stock-count"])), [
                      [_vShow, _ctx.canAddToCart]
                    ])
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.showActions)
      ? _renderSlot(_ctx.$slots, "bottom", { key: 2 }, undefined, true)
      : _createCommentVNode("", true)
  ], 64))
}