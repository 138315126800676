import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "brands-slider" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      style: {
        '--swiper-pagination-color': 'black',
        '--swiper-navigation-color': 'var(--color_theme_btn_primary_bg, #4d83d6)',
        '--swiper-pagination-bullet-horizontal-gap': '15px',
        '--swiper-pagination-bullet-size': '6px',
      },
      modules: _ctx.modules,
      "slides-per-view": 1,
      "space-between": 30,
      navigation: true,
      loop: _ctx.brands?.length > 3,
      pagination: false,
      scrollbar: { draggable: true },
      direction: "horizontal",
      breakpoints: {
        '640': {
          slidesPerView: 2,
        },
        '1024': {
          slidesPerView: 3,
        },
      },
      autoplay: false
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedBrands, (brand, i) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: i }, {
            default: _withCtx(() => [
              (brand.external_url)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: brand.external_url,
                    rel: "noreferrer nofollow",
                    target: "_blank"
                  }, [
                    _createElementVNode("img", {
                      src: brand.image?.contain_small,
                      alt: _ctx.gettext('Бренд')
                    }, null, 8, _hoisted_3)
                  ], 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: brand.image?.contain_small,
                    alt: _ctx.gettext('Бренд')
                  }, null, 8, _hoisted_4))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "loop"])
  ]))
}