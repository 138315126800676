export const XXL_WIDTH = 1920;
export const XL_WIDTH = 1440;
export const LG_WIDTH = 1280;
export const MD_WIDTH = 960;
export const SM_WIDTH = 768;
export const XM_WIDTH = 576;

export const SCREEN_MODE_NAMES = {
  XM: 'XM',
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL',
  XXL: 'XXL',
};

export const getScreenMode = (width) => {
  if (width >= XL_WIDTH) {
    return SCREEN_MODE_NAMES.XXL;
  }
  if (width <= XL_WIDTH && width > LG_WIDTH) {
    return SCREEN_MODE_NAMES.XL;
  }
  if (width <= LG_WIDTH && width > MD_WIDTH) {
    return SCREEN_MODE_NAMES.LG;
  }
  if (width <= MD_WIDTH && width > SM_WIDTH) {
    return SCREEN_MODE_NAMES.MD;
  }
  if (width <= SM_WIDTH && width > XM_WIDTH) {
    return SCREEN_MODE_NAMES.SM;
  }
  if (width <= XM_WIDTH) {
    return SCREEN_MODE_NAMES.XM;
  }
};

/**
 *
 * @param config {[key: Keyof SCREEN_MODE_NAMES]: () => void}
 * @returns {(function(width: number): void)|*}
 */
export function createResizeModeHandler(config) {
  let currentMode = SCREEN_MODE_NAMES.XXL;
  let modes = Object.values(SCREEN_MODE_NAMES);
  return (newWidth) => {
    const mode = getScreenMode(newWidth);
    let applyModeClb = config[mode];
    if (!applyModeClb) {
      for (let key of modes.slice(modes.indexOf(mode))) {
        applyModeClb = config[key];
        if (applyModeClb) {
          if (key === currentMode) {
            return;
          }
          currentMode = key;
          applyModeClb();
          return;
        }
      }
    }
    if (mode === currentMode) {
      return;
    }
    currentMode = mode;
    applyModeClb && applyModeClb();
  };
}
