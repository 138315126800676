import { defineComponent } from 'vue';
import { PRODUCT_TYPES } from '../common/config';
import {
  SELECT_SUPPLIER,
  TOGGLE_SELECTED_PRODUCT,
} from '../store/actions.type';
import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import {
  changeLocationQuery,
  num2word,
  parseQuerySting,
} from '@spa/common/utils';
import { ProductType } from '@spa/api/schema/catalog';

export const ProductItemMixin = defineComponent({
  data() {
    return {
      PRODUCT_TYPES,
    };
  },
  methods: {
    productCountDecline(count: Nullable<number>) {
      return num2word(count, [
        gettext('товар'),
        gettext('товара'),
        gettext('товаров'),
      ]);
    },
    showPopover(event: any, content: any, placement: any) {
      $(event.target).popover({ trigger: 'manual', content: content, placement: placement, container: 'body' });
      $(event.target).popover("show");
    },
    hidePopover(event: any) {
      $(event.target).popover("hide");
    },
    onSelectProduct(e: Event, product_id: number) {
      e.preventDefault();
      this.$store.dispatch('catalog/' + TOGGLE_SELECTED_PRODUCT, product_id);
    },
    onSelectSticker(e: Event, stickerCode: string) {
      e.preventDefault();
      stickerCode &&
        this.$router.replace({
          name: 'CategoryStickers',
          params: { code: stickerCode },
        });
    },
    navigateToSupplierProducts(e: Event, supplier_id: number) {
      e.preventDefault();

      const fd = parseQuerySting();
      fd.set('supplier', supplier_id.toString());
      changeLocationQuery(fd);
      if (Number(supplier_id)) {
        this.$store.dispatch('catalog/' + SELECT_SUPPLIER, supplier_id);
      }
    },
  },
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
  },
  computed: {
    ...mapGetters('catalog', [
      'selectedProducts',
      'productSelectedWarehouses',
      'productsCountBySupplier',
      'selectedSupplier',
      'showDemo',
    ]),
    isSelected() {
      return !!this.selectedProducts[this.product.id];
    },
    allowSelectWarehouse() {
      return (
        !this.isReferenceMode &&
        this.catalogConfig.multi_warehouse_in_catalog &&
        this.product.warehouses.length > 1 &&
        this.product.default_warehouse
      );
    },
    /**
     * Договорная цена
     */
    hasPriceOffer() {
      return (
        !this.isReferenceMode &&
        !this.showDemo &&
        this.product.has_zero_price &&
        !this.product.hide_price_in_open_catalog
      );
    },
    /**
     * Показыавть цену
     */
    showPriceRange() {
      return true;
    },
    hiddenPriceTypesNamesText() {
      return 'Товар скрыт на регионах поставки: ' + this.product.hidden_price_types_names.join(", ");
    },
    hiddenPriceOnRequest() {
      return 'Цена будет указана на этапе обработки заказа поставщиком';
    },
    productNvlText() {
      return '1 или более предложений с НВЛ товаром';
    },
    productNvlLabel() {
      return 'Невостребованные ликвидные товары';
    },
    /**
     * Может добавлять товары в корзину
     */
    canAddToCart() {
      return (
        this.catalogConfig.can_use_cart &&
        !this.product.hide_price_in_open_catalog
      );
    },
    warehouseStockCount() {
      if (
        this.catalogConfig.multi_warehouse_in_catalog &&
        this.productSelectedWarehouses[this.product.base_modification_id]
      ) {
        return this.productSelectedWarehouses[this.product.base_modification_id]
          .rest;
      } else {
        return this.product.stock_count;
      }
    },
    showRestIndicator() {
      return (
        this.catalogConfig.show_rest_indicator &&
        !this.isServiceProductType
      );
    },
    isReferenceMode() {
      return this.catalogConfig.is_reference_mode;
    },
    stockCount() {
      const filter_params = this.$store.state.catalog.filter_params;
      if (filter_params.from_supplier_catalog && filter_params.warehouses) {
        var stock_count = 0;
        for (const wh of this.product.warehouses) {
          if (filter_params.warehouses.includes(wh.id)) {
            stock_count += wh.rest;
          }
        }
        return stock_count;
      }
      return this.product.stock_count;
    },
    hasCoupon() {
      return !!this.product.price_range?.coupon;
    },
    hasShowProductSupplier() {
      return (
        !this.projectSettings.single_supplier_installation &&
        !this.catalogConfig.is_reference_mode &&
        (this.projectSettings.user.is_contractor
          || this.projectSettings.user.is_control_manager
          || !this.projectSettings.user.is_auth
        )
      );
    },
    hasShowFavoriteBtn() {
      return this.catalogConfig.wishlist_enabled &&
        !this.projectSettings.user.is_supplier;
    },
    hasShowCompareBtn() {
      return this.catalogConfig.compare_enabled;
    },
    hasRrcPrice() {
      return !!parseFloat(this.product.rrc_price?.toString());
    },
    hasShowDescription() {
      return (
        this.catalogConfig.show_description_in_catalog &&
        this.product.description
      );
    },
    isSupplierCatalog() {
      return (
        ['SupplierCatalog', 'SupplierCatalogCategory', 'SupplierCatalogSearch', 'SupplierCatalogWithSupplierCategory'].indexOf(
          this.$route.name as string
        ) > -1
      );
    },
    // isModeratorCatalog() {
    //   return (
    //     ['ModeratorCatalog', 'ModeratorCatalogCategory'].indexOf(
    //       this.$route.name as string
    //     ) > -1
    //   );
    // },
    allowEditProduct() {
      return this.product.has_edit && this.isSupplierCatalog;
    },
    /**
     * Проверка на то что есть новые сообщения о модерации товаров
     * (Только для поставщика или модератора)
     */
    hasNewModerationMessage() {
      const hasSupplierComments = this.user.is_moderator && this.product.supplier_comments_count;
      const hasModerationComments =
        (this.user.is_marketing_manager && this.product.makreting_manager_comments_count)
        || (this.user.is_brand_manager && this.product.brand_manager_comments_count);

      return (
        this.isSupplierCatalog && (hasSupplierComments || hasModerationComments)
      );
    },
    /**
     * Проверка на необходимость показать статус модерации товара
     * (Только для поставщика или модератора)
     */
    showModerationStatus() {
      return (
        this.catalogConfig.show_product_moderation_status &&
        this.product.moderation_status &&
        this.isSupplierCatalog
      );
    },
    isServiceProductType() {
      return this.product.product_type === PRODUCT_TYPES.service;
    },
  },
});
