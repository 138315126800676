
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import {
  ADD_PRODUCT_TO_FAVORITE,
  DELETE_PRODUCT_FROM_FAVORITE,
} from '../store/actions.type';
import { ProductType } from '../../../api/schema/catalog';

export default defineComponent({
  name: 'FavoriteButton',
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
  },
  methods: {
    toggleFavorite(e: Event) {
      if (this.isLoading) return;

      if (!this.inFavorite) {
        this.$store.dispatch(
          'catalog/' + ADD_PRODUCT_TO_FAVORITE,
          this.product.id
        );
      } else {
        this.$store.dispatch(
          'catalog/' + DELETE_PRODUCT_FROM_FAVORITE,
          this.product.id
        );
      }
    },
  },
  computed: {
    ...mapGetters('settings', ['settings']),
    ...mapGetters('catalog', ['isLoading', 'productWaitHandlingForFavorite']),
    inFavorite() {
      return !!this.product.in_favorite;
    },
    waitHandle() {
      return !!this.productWaitHandlingForFavorite[this.product.id];
    },
  },
});
