import events from '../events/events';

events.on('dropdown.toggle', (el, dropdown, visible) => {
  const event = new CustomEvent('dropdown.toggle', {
    detail: { visible },
  });
  el.dispatchEvent(event);
});

export function hideVisibleDropdowns(exclude = []) {
  document
    .querySelectorAll('[data-dropdown-toggle].dropdown-visible')
    .forEach((el) => {
      if (exclude.indexOf(el.dataset.dropdownToggle) < 0) {
        el.classList.remove('dropdown-visible');
        document
          .querySelectorAll(`[data-dropdown="${el.dataset.dropdownToggle}"]`)
          .forEach((dropdown) => {
            dropdown.classList.remove('dropdown-visible');
            events.emit(
              'dropdown.toggle',
              dropdown,
              dropdown.dataset.dropdown || dropdown.dataset.dropdownToggle,
              false
            );
          });
      }
    });
}

export function toggleDropdown(thisEl, hideOthers = true) {
  const dropdown = thisEl.dataset.dropdownToggle;
  if (hideOthers) {
    const exclude = [dropdown];
    let parent = thisEl.closest('[data-dropdown]');
    while (parent) {
      exclude.push(parent.dataset.dropdown);
      const newParent = parent.closest('[data-dropdown]');
      parent = newParent === parent ? false : newParent;
    }
    hideVisibleDropdowns(exclude);
  }

  let isVisible = false;
  document
    .querySelectorAll(
      `[data-dropdown-toggle="${dropdown}"], [data-dropdown="${dropdown}"]`
    )
    .forEach((el) => {
      el.classList.toggle('dropdown-visible');
      isVisible = el.classList.contains('dropdown-visible');
      events.emit(
        'dropdown.toggle',
        el,
        dropdown,
        el.classList.contains('dropdown-visible')
      );
    });

  if (isVisible) {
    document
      .querySelectorAll(`[data-dropdown] [data-dropdown-toggle="${dropdown}"]`)
      .forEach((el) => {
        if (thisEl === el) {
          return;
        }
        const parent = el.closest('[data-dropdown]');
        const parentDropdown = parent.dataset.dropdown;
        const parentTrigger = document.querySelector(
          `[data-dropdown-toggle="${parentDropdown}"]`
        );
        if (parentTrigger) {
          toggleDropdown(parentTrigger, false);
        }
      });
  }
}

export function dropdownOnToggle(event) {
  event.preventDefault();
  toggleDropdown(this);
}

export function bodyClickHandler(event) {
  const target = event.target;
  if (
    !target.closest('[data-dropdown]') &&
    !target.closest('[data-dropdown-toggle]') &&
    !target.closest('.select2-dropdown') &&
    !target.closest('.select2-selection__choice')
  ) {
    document.querySelectorAll('.dropdown-visible').forEach((el) => {
      el.classList.remove('dropdown-visible');
      events.emit(
        'dropdown.toggle',
        el,
        el.dataset.dropdown || el.dataset.dropdownToggle,
        false
      );
    });
  }
}

export default function initDropdown() {
  document.querySelectorAll('[data-dropdown-toggle]').forEach((el) => {
    el.removeEventListener('click', dropdownOnToggle);
  });
  document.querySelectorAll('[data-dropdown-toggle]').forEach((el) => {
    el.addEventListener('click', dropdownOnToggle);
  });

  document.querySelector('body').removeEventListener('click', bodyClickHandler);
  document.querySelector('body').addEventListener('click', bodyClickHandler);
}

events.on('main.inited', () => {
  initDropdown();
});
