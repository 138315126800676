import { defineComponent } from 'vue';
import { CHANGE_FILTER_PARAMS } from '../store/actions.type';
import catalogEvents from '../common/catalog-events';
import CatalogGetDataMixin from './catalog-get-data.mixin';
import { mapGetters } from 'vuex';
import { PropertyValuesFilterParam } from '../../../api/schema/catalog';
import { useCheckCatalogRoute } from '../composables';
import { useBreakpoints } from '@/entries/spa/composables';

export const ProductFilterMixin = defineComponent({
  mixins: [CatalogGetDataMixin],
  methods: {
    onChangePropertyValues(property_id: number, values: Nullable<number[]>) {
      const properties: PropertyValuesFilterParam[] = this.filterProperties;
      const idx = properties.findIndex((x) => x.property_id === property_id);
      if (idx > -1) {
        if (values?.length) {
          properties[idx] = {
            property_id,
            values,
          };
        } else {
          properties.splice(idx, 1);
        }
      } else if (values?.length) {
        properties.push({
          property_id,
          values,
        });
      }
      this.setSelectedPropertyValues(properties);
      this.applyFilter();
    },
    clearFilterData(e: UIEvent) {
      e.preventDefault();
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        properties: [],
        search_text: this.filterParams.search_text,
        product_ids: [],
        price_range: null,
        supplier_id: null,
        warehouses: null,
        in_stock: null,
        in_stock_for_warehouses: null,
        is_item_on_order: null,

      });
      this.setSelectedSupplier(this.selectedSupplier);
      this.setSelectedWarehouses(this.selectedWarehouses);
      this.getProducts();
      this.getPropertyValues();
      this.getPriceMinMax();
      catalogEvents.emit('onResetFilterProps');
      this.scrollTop();
    },
    /**
     * Запрашивает товары, только если не мобилка.
     * Нужно для того, чтобы вешать на ввод инпутов в сайдбаре
     */
    applySidebarFilter() {
      this.gtSM && this.applyFilter();
    },
    setInStock(value: boolean | null) {
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        in_stock: value || null,
      });
    },
    setSelectedPropertyValues(value: Nullable<PropertyValuesFilterParam[]>) {
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        properties: value || null,
      });
    },
    setPriceRange(min_value: number, max_value: number) {
      let price_range: [number, number] | null = null;
      if (
        min_value !== this.priceMinMax?.price_min ||
        max_value !== this.priceMinMax?.price_max
      ) {
        price_range = [min_value, max_value];
      }
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        price_range,
      });
    },
    setPriceFrom(min_value: number) {
      const max_value = this.filterParams.price_range?.length
        ? this.filterParams.price_range[1]
        : this.priceMinMax.price_max;
      this.setPriceRange(min_value, max_value);
    },
    setPriceTo(max_value: number) {
      const min_value = this.filterParams.price_range?.length
        ? this.filterParams.price_range[0]
        : this.priceMinMax.price_min;
      this.setPriceRange(min_value, max_value);
    },
    setSelectedSupplier(supplier_id: Array<number> | number | null) {
      if (supplier_id && !Array.isArray(supplier_id)) {
        supplier_id = [supplier_id];
      }
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        supplier_id,
      });
    },
    setSelectedVendorBrands(brands: string[]) {
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        brands,
      });
    },
    setSelectedWarehouses(warehouses: Array<string | number>) {
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        warehouses,
      });
    },
    setSelectPricesOnOrder(value: boolean | null) {
    this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
      is_item_on_order: value,
      });
    },
    setSelectPricesIsLiquid(value: boolean | null) {
    this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
      is_liquid: value,
      });
    },
    /**
     * Загружает товары для каталога, сбрасывает страницу.
     * @note В сайдбаре вместо этого метода использовать __this.applySidebarFilter__
     */
    applyFilter() {
      this.$store.dispatch('catalog/' + CHANGE_FILTER_PARAMS, {
        page: 1,
      });
      this.getSuppliers();
      this.getPriceMinMax();
      this.getProducts();
      this.getPropertyValues();
      catalogEvents.emit('onApplyCatalogFilter');
      this.scrollTop();
    },
  },
  watch: {
    selectedSupplier(mutation: Nullable<number>) {
      if (
        mutation &&
        !this.suppliers?.find(({ id }) => id.toString() === mutation.toString())
      ) {
        this.getSuppliers(mutation);
      }
    },
  },
  computed: {
    ...mapGetters('catalog', [
      'propertyValues',
      'promotions',
      'priceMinMax',
      'filterParams',
      'selectedCategory',
      'filterProperties',
      'filterPriceRange',
      'isLoadingPropertyValues',
      'suppliers',
      'warehouses',
      'selectedSupplier',
      'selectedWarehouses',
      'inStock',
      'selectedItemOnOrder'
    ]),
    priceRangeFilterChanged() {
      return (
        this.filterParams.price_range &&
        (this.filterParams.price_range[0] !== this.priceMinMax.price_min ||
          this.filterParams.price_range[1] !== this.priceMinMax.price_max)
      );
    },
  },
  setup() {
    const { isCatalog } = useCheckCatalogRoute();
    const { gtSM } = useBreakpoints();

    return {
      gtSM,
      isCatalog,
    };
  },
});
