
import { defineComponent, ref } from 'vue';
import { ProductItemMixin } from '../../mixins/product-item.mixin';
import FavoriteButton from '../favorite-button.component.vue';
import RestIndicator from '../rest-indicator.component.vue';
import WarehousesSelect from '../warehouses-select.component.vue';
import AddToCartButton from '../add-to-cart-button.component.vue';
import AddToCompareButton from '../add-to-compare-button.component.vue';
import AddToWaitingListButton from '../add-to-waiting-list-button.component.vue';
import EditButton from '../edit-button.component.vue';
import PercentOfFullness from '../percent-of-fullness.component.vue';
import FastViewButton from './../fast-view-button.component.vue';
import { useCatalogModals } from '../../composables/modals';
import { useShowActionButtonsAndCheckBox } from '@catalog/composables';
import PriceWithCoupon from '../price-with-coupon.vue';
import { API_V1_HOST_OUTER } from '@spa/api/urls';
import { useUser } from '@composables/store';
import DefaultImage from '@components/default-image.vue';

export default defineComponent({
  name: 'ProductItemGrid',
  methods: {},
  components: {
    DefaultImage,
    FavoriteButton,
    EditButton,
    RestIndicator,
    WarehousesSelect,
    AddToCartButton,
    AddToCompareButton,
    AddToWaitingListButton,
    PercentOfFullness,
    FastViewButton,
    PriceWithCoupon,
  },
  mixins: [ProductItemMixin],
  setup() {
    const EXTERNAL_HOST = API_V1_HOST_OUTER ? API_V1_HOST_OUTER : '';
    const { showProductDetailModal } = useCatalogModals();
    const showActions = useShowActionButtonsAndCheckBox();
    const isSupplierUser = useUser().value.is_supplier;
    const imgStyle = 'max-width: 100%; max-height: 100%; border-radius: 4px';
    const useNSI = window.user.contractor.useNSI;
    return {
      showProductDetailModal,
      showActions,
      isSupplierUser,
      EXTERNAL_HOST,
      imgStyle,
      useNSI,
    };
  },
});
