
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { CategoryNodeType } from '../../../../../types/app.types';

export default defineComponent({
  name: 'CategoryRootItem',
  props: {
    category: {
      type: Object as PropType<CategoryNodeType>,
      required: true,
    },
    isActive: {
      required: false,
      type: Boolean as PropType<boolean>,
    },
    onToggleCategory: {
      required: false,
      type: Function as PropType<
        (category: CategoryNodeType, opened: boolean) => void
      >,
    },
  },
  created() {
    if (this.category.id === this.selectedCategory) {
      this.onToggleCategory && this.onToggleCategory(this.category, true);
    }
  },
  methods: {
    onClick(e: Event) {
      e.preventDefault();
      if (!this.isActive) {
        this.$router.replace({
          name: 'Category',
          params: { id: this.category.id },
        });
        this.onToggleCategory && this.onToggleCategory(this.category, true);
      }
    },
    getCategoryText(category: CategoryNodeType, limit = 50) {
      if (!this.catalogConfig.show_count_products_category_in_sidebar) {
        return this.truncateString(category.name, limit);
      }
      return `${this.truncateString(category.name, limit)} (${
        category.product_count
      })`;
    },
  },
  computed: {
    ...mapGetters('catalog', ['selectedCategory']),
  },
});
