
import { defineComponent, PropType } from 'vue';
import { num2word } from '@/entries/spa/common/utils';
import { DISPLAY_RESTS_MODES } from '@catalog/common/config';

export default defineComponent({
  name: 'RestIndicator',
  props: {
    stockCount: {
      required: true,
      type: [Number, String] as PropType<number | string>,
    },

    measureUnitName: String as PropType<string>,
    supplierId: Number as PropType<number>,
    hideIcon: Boolean,

    warehouseName: {
      required: false,
      type: String as PropType<string> | null,
      default: null,
    },
  },
  computed: {
    restIndicator() {
      if (!this.stockCount || this.stockCount == 0) {
        return 0;
      }
      if (this.stockCount <= 5) {
        return 1;
      }
      if (this.stockCount <= 10) {
        return 2;
      }
      if (this.stockCount <= 50) {
        return 3;
      }
      if (this.stockCount <= 100) {
        return 4;
      }
      return 5;
    },
    parseStockCount() {
      if (this.stockCount === null) {
        return null;
      }
      return Number(this.stockCount) || 0;
    },
    showRestIndicaor() {
      // Проверка на отображение RESTS_BINARY_ALT:
      // 'Показывать только склад, если на нем присутствуют остатки'
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_BINARY_ALT']) {
        return this.parseStockCount > 0;
      }
      return true;
    },

    suppliersRestsModesFromConfig(): { [key: string]: number } {
      return this.catalogConfig.suppliers_rests_modes || {};
    },
    suppliersThresholdModesFromConfig(): { [key: string]: number } {
      return this.catalogConfig.suppliers_rests_modes || {};
    },

    displayMode() {
      const supplierId = this.supplierId;
      if (supplierId && this.suppliersRestsModesFromConfig) {
        return this.suppliersRestsModesFromConfig[supplierId];
      }
      return null;
    },
    thresholdMode() {
      const supplierId = this.supplierId;
      if (supplierId && this.suppliersThresholdModesFromConfig) {
        return this.suppliersThresholdModesFromConfig[supplierId];
      }
      return null;
    },

    textDanger() {
      // Определяет необходимость оборачивания в класс text-danger
      let stockCount = this.parseStockCount;
      return (
        stockCount === 0 ||
        (this.displayMode === DISPLAY_RESTS_MODES['RESTS_MUCHLESS'] &&
          stockCount <= this.thresholdMode)
      );
    },
    displayStockCount() {
      // Аналог метода format_rest, для рассчета в каком виде отображать остаток
      let stockCount = this.parseStockCount;
      let stockCountStr;
      if (stockCount) {
        const fractionDigits = this.catalogConfig.allow_use_floats ? 2 : 0;
        stockCountStr = stockCount.toFixed(fractionDigits);
      } else {
        stockCountStr = '0';
      }

      if (
        this.catalogConfig.show_rests_limit &&
        stockCount > this.catalogConfig.show_rests_limit
      ) {
        let rest_string = '> ' + this.catalogConfig.show_rests_limit;
        return rest_string + this.measureUnitName;
      }
      if (
        this.catalogConfig.infinity_rests &&
        this.catalogConfig.infinity_rests != 2
      ) {
        return '';
      }

      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_BINARY_ALT']) {
        return '';
      }
      if (stockCount === null) {
        return gettext('Остатки неизвестны');
      }
      if (stockCount < 1) {
        return gettext('Под заказ');
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_BINARY']) {
        return gettext('В наличии');
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_MUCHLESS']) {
        if (stockCount > this.thresholdMode) {
          return gettext('Много');
        }
        if (stockCount <= this.thresholdMode) {
          return gettext('Мало');
        }
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_MUCH']) {
        if (stockCount > this.thresholdMode) {
          return gettext('Много');
        } else {
          return `${stockCountStr} ${this.measureUnitName}`;
        }
      }
      return `${stockCountStr} ${this.measureUnitName}`;
    },
    titleStockCount() {
      if (!this.displayStockCount) {
        return gettext('Под заказ');
      }
      return `${gettext('Доступно')} ${this.displayStockCount} ${num2word(
        Number(this.displayStockCount),
        [gettext('единица'), gettext('единицы'), gettext('единиц')]
      )} ${gettext('на складе')}`;
    },
  },
});
