
import { defineComponent, PropType } from 'vue';
import {
  AddProductsToCarItem,
  ProductType,
} from '@/entries/spa/api/schema/catalog';
import { ThumbnailType } from '@/entries/spa/api/schema/common';
import { FeedbackType } from '@/entries/spa/api/schema/settings';
import { ProductItemMixin } from '../../mixins/product-item.mixin';

import ProductDetailSwiper from './product-detail-swipper.component.vue';
import ProductDetailPropertyValues from './product-detail-property-values.component.vue';
import ProductDetailActions from './product-detail-actions.component.vue';
import InFavoriteButton from '../in-favorite-button.component.vue';
import InComparisonButton from '../in-comparison-button.component.vue';
import RestIndicator from '../rest-indicator.component.vue';
import FeedbackButton from '../feedback-button.component.vue';

import Tab from 'bootstrap/js/dist/tab';
import { useBreakpoints } from '@/entries/spa/composables';
import { containsHTML } from '@/entries/spa/common/utils';
import { useFeedbackItems } from '@/entries/spa/composables/feedback';
import { ADD_PRODUCTS_TO_CART } from '@catalog/store/actions.type';
import axios from 'axios';
import { CatalogService } from '@catalog/api/api.catalog.service';
import catalogEvents from '@catalog/common/catalog-events';
import { useCatalogModals } from '@catalog/composables/modals';

export default defineComponent({
  name: 'ProductDetailData',

  components: {
    ProductDetailSwiper,
    ProductDetailPropertyValues,
    ProductDetailActions,

    InFavoriteButton,
    InComparisonButton,
    FeedbackButton,
    RestIndicator,
  },

  props: {
    detailProduct: {
      type: Object as PropType<ProductType> | null,
      default: null,
    },
    showBackBtn: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isActionsBlock: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isLinkToDetailPage: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    horizontalOriented: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  mixins: [ProductItemMixin],

  created() {
    if (!this.detailProduct.exists) {
      window.location.href = '/catalog/?product_not_exists=1';
    }
  },

  mounted() {
    this.updateOnButtonClieck();
  },

  setup() {
    const { gtLg, ltLg, gteLg, lteLg } = useBreakpoints();

    const { showPhoneNumberModal } = useCatalogModals();

    const productFeedbackItems = useFeedbackItems(FeedbackType.PRODUCT);
    const useNSI = window.user.contractor.useNSI;
    return {
      showPhoneNumberModal,
      gtLg,
      ltLg,
      gteLg,
      lteLg,
      containsHTML,
      productFeedbackItems,
      useNSI,
    };
  },

  data() {
    return {
      maxDescriptionLength: 230,
      realProductsTable: this.detailProduct.real_products_html,
      showFullDescription: false,
      exists: this.detailProduct.exists,
    };
  },

  methods: {
    updateRealProductsTableStyle() {
      const warehouseSelect = this.$el.querySelector(
        '.warehouse-catalog-detail'
      );
      if (warehouseSelect) {
        warehouseSelect.classList.remove('warehouse-catalog-detail');
        warehouseSelect.classList.add('warehouse-catalog-detail-small');
      }
    },

    updateOnButtonClieck() {
      const $th = this;
      const buyBtnSelect = this.$el.querySelectorAll(
        '.js-buy_from_modifications_list'
      );
      const btnIsOnOrder = document.getElementById('btnIsOnOrder');

      if (btnIsOnOrder) {
        btnIsOnOrder.addEventListener('mouseenter', (event) =>
          this.showPopover(event, this.hiddenPriceOnRequest, 'right')
        );
        btnIsOnOrder.addEventListener('mouseleave', (event) =>
          this.hidePopover(event)
        );
      }

      if (
        !this.user.ad_contact_phone_number &&
        !this.user.phone &&
        !this.user.is_supplier
      ) {
        if (buyBtnSelect) {
          $(buyBtnSelect).prop('disabled', true);
        }
        this.showPhoneNumberModal();
        return;
      }

      if (buyBtnSelect) {
        $(buyBtnSelect).on('click', function (e) {
          e.preventDefault();
          const $this = $(this);
          const $form = $($this.parents('form')[0]);
          let count: number = parseFloat(
            $form.find('input[type="number"]').val().toString()
          );
          const modificationID = $this.data('modification');
          const productID = $this.data('product');
          const product: AddProductsToCarItem = {
            modification_id: modificationID,
            product_id: productID,
            count: count,
            warehouse_id: null,
          };
          const payload = {
            products: [product],
          }
          CatalogService.addProductsToCart(payload, true).then(function (
            result
          ) {
            catalogEvents.emit(
              'onUpdateCartItemCount',
              result.data.order_items_count
            );
            $this.find('span').text(gettext('В корзине'));
            $this.removeClass('btn-primary').addClass('btn-success');
          });
        });
      }
    },

    scrollToTab(tabName: string) {
      const tabsElement = document.getElementById('product-detail-tabs');
      if (!tabsElement) return;

      const currentTab = tabsElement.querySelector(
        `[data-target="#${tabName}"]`
      );
      if (!currentTab) return;

      new Tab(currentTab).show();

      setTimeout(() => {
        const tabsElementCoords = tabsElement.getBoundingClientRect();
        window.scroll({
          top: tabsElementCoords.top,
          behavior: 'smooth',
        });
      }, 100);
    },
    goBack(e: Event) {
      this.$router.go(-1);
    },
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
  },

  computed: {
    isPropertyValues(): boolean {
      return (
        this.detailProduct.property_values &&
        this.detailProduct.property_values.length !== 0
      );
    },

    isNeedDetailProductDescriptionTruncate(): boolean {
      return (
        this.detailProduct.description &&
        this.detailProduct.description.length >= this.maxDescriptionLength
      );
    },
    detailProductDescriptionTruncate(): string {
      let description = this.detailProduct.description;
      if (!description) return '';

      description = this.isNeedDetailProductDescriptionTruncate
        ? description.slice(0, this.maxDescriptionLength)
        : description;

      if (!description) return '';
      return description.endsWith(' ') ? description : description + ' ';
    },

    getDetailProductImages(): ThumbnailType[] {
      return this.detailProduct.images || [];
    },

    showActions(): boolean {
      return this.isActionsBlock && !this.detailProduct.has_modifications;
    },
    hasShowProductSupplier(): boolean {
      return (
        !this.projectSettings.single_supplier_installation &&
        !this.catalogConfig.is_reference_mode &&
        (this.user.is_contractor ||
          this.user.is_control_manager ||
          !this.user.is_auth)
      );
    },

    isShowActions(): boolean {
      return (
        this.modulesEnabled.buy ||
        this.modulesEnabled.rest ||
        this.modulesEnabled.rest
      );
    },
  },
});
