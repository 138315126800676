
import { defineComponent, PropType, ref } from 'vue';
import {
  AjaxOptions,
  DataFormat,
  OptionData as Select2OptionData,
  Options as Select2Options,
} from 'select2';
import _ from 'lodash';
import { useState } from '../common/utils';

type SelectedValues = string | number | (string | number)[];

export default defineComponent({
  name: 'Select2',
  props: {
    id: {
      type: String as PropType<string>,
      required: false,
    },
    tags: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    placeholder: {
      type: String as PropType<string>,
      required: false,
      default: gettext('Нажмите чтобы выбрать'),
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    optionsList: {
      type: Array as PropType<DataFormat[]>,
      required: false,
    },
    ajax: {
      type: Object as PropType<AjaxOptions>,
      required: false,
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    allowClear: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    noResultText: {
      type: String as PropType<string>,
      default: gettext('Нет совпадений'),
    },
    searchText: {
      type: String as PropType<string>,
      default: gettext('Поиск...'),
    },
    minimumInputLength: {
      type: Number as PropType<number>,
      default: 2,
    },
    inputTooShortText: {
      type: String as PropType<string>,
      default: gettext('Введите слово для поиска'),
    },
    selected: {
      type: [Array, String, Number] as PropType<SelectedValues>,
      required: false,
    },
    minimumResultsForSearch: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  emits: ['onchange'],
  methods: {
    _onChangeSelect2(selected: boolean) {
      let values = this.select2?.select2('data') as Select2OptionData[];
      if (!this.multiple) {
        values = values.map((x) => ({ ...x, selected }));
      }
      this.$emit('onchange', values);
    },
    onRefSelect2(el: HTMLSelectElement) {
      if (!this.select2) {
        this.select2 = $(el);
        this.select2.on('select2:select', () => this._onChangeSelect2(true));
        this.select2.on('select2:unselect', () => this._onChangeSelect2(false));

        this.select2.select2(this.select2Options);
      }
      this.select2?.trigger('change');
    },
  },
  setup() {
    const select2 = ref<Nullable<JQuery<HTMLSelectElement>>>(null);
    const [options, setOptions] = useState<DataFormat[]>([]);
    return {
      select2,
      options,
      setOptions,
    };
  },
  watch: {
    selected(mutation: SelectedValues) {
      if (!Array.isArray(mutation)) {
        mutation = [mutation];
      }
      const selectedValues = mutation.filter(Boolean).map(String);
      this.setOptions(
        this.options.map((x) => ({
          ...x,
          selected: selectedValues.indexOf(x.id.toString()) > -1,
        }))
      );
      this.select2?.val(selectedValues);
      this.select2?.trigger('change');
    },
    optionsList(mutations: DataFormat[]) {
      this.setOptions(_.uniqBy(mutations, 'id'));
      this.select2?.trigger('change');
    },
  },
  computed: {
    select2Options(): Select2Options {
      const select2Options: Select2Options = {
        placeholder: this.placeholder,
        allowClear: this.allowClear,
        multiple: this.multiple,
        tags: this.tags,
        width: '100%',
        minimumInputLength: this.ajax && this.minimumInputLength,
        minimumResultsForSearch: this.minimumResultsForSearch,
        language: {
          noResults: () => this.noResultText,
          searching: () => this.searchText,
          inputTooShort: () => this.inputTooShortText,
        },
      };

      if (this.ajax) select2Options.ajax = this.ajax;
      if (this.optionsList) select2Options.data = this.optionsList;

      return select2Options;
    }
  }
});
