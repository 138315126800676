
import { PropType, defineComponent } from 'vue';
import { ProductType } from '@spa/api/schema/catalog';
import { useCatalogModals } from '@spa/apps/catalog/composables/modals';

export default defineComponent({
  name: 'FastViewButton',
  setup() {
    const { showProductDetailModal } = useCatalogModals();
    return { showProductDetailModal };
  },
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
  },
  computed: {
    isShow(): boolean {
      return !this.user.is_auth || this.user.is_contractor;
    }
  }
});
