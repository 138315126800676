
import { defineComponent, PropType } from 'vue';

interface DataForPercent {
  color: string;
  percent: number;
  text?: string;
}

const DefaultDatasForPercent: DataForPercent[] = [
  {
    percent: 0,
    color: 'danger',
    text: gettext('Требует заполнения'),
  },
  {
    percent: 40,
    color: 'secondary',
    text: gettext('Хорошо заполнено'),
  },
  {
    percent: 80,
    color: 'success',
    text: gettext('Отлично заполнено'),
  },
];

export default defineComponent({
  name: 'PercentOfFullness',

  props: {
    percent: {
      type: Number as PropType<number>,
      default: 0,
    },
    isShowText: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    datasForPercent: {
      type: Array as PropType<DataForPercent[]>,
      default: DefaultDatasForPercent,
    },
    cssWidth: {
      type: Number as PropType<number>,
      default: 120,
    },
    label: {
      type: String as PropType<string>,
      default: '',
    },
  },

  methods: {
    getCurrentDataForPrecent() {
      const datasForPercent = this.datasForPercent
        .slice()
        .sort(
          (prevEl: DataForPercent, nextEl: DataForPercent) =>
            nextEl.percent - prevEl.percent
        );

      for (const dataForPercent of datasForPercent) {
        if (this.percent >= dataForPercent.percent) {
          return dataForPercent;
        }
      }
      return null;
    },

    progressBarColor(): string {
      const data = this.getCurrentDataForPrecent();
      return data ? data.color : '';
    },

    progressBarText(): string {
      const data = this.getCurrentDataForPrecent();
      return data ? data.text : '';
    },
  },
  computed: {
    percentageOccupancy() {
      return (this.percent || 0).toFixed(2);
    },
  },
});
