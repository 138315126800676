import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: "select form-control warehouse-select",
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChangeWarehouse && _ctx.onChangeWarehouse(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouses, (warehouse) => {
      return (_openBlock(), _createElementBlock("option", {
        key: warehouse.id,
        value: warehouse.id,
        selected: warehouse.id === _ctx.selected?.id
      }, _toDisplayString(warehouse.rest && `(${_ctx.getDisplayRest(warehouse.rest)}) `) + _toDisplayString(warehouse.name), 9, _hoisted_1))
    }), 128))
  ], 32))
}