import catalogModule from '../store/catalog.module';
import { createStoreComposable } from '@spa/store/vuex-helpers';
import priceOfferModule from '../store/price-offer.module';

export const useCatalogStore = createStoreComposable(catalogModule, 'catalog');

export const usePriceOfferStore = createStoreComposable(
  priceOfferModule,
  'priceOffer'
);
