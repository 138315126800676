import { ref, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { PANEL_STATUSES } from '../common/config';
import CatalogGetDataMixin from './catalog-get-data.mixin';

export const CatalogViewMixin = defineComponent({
  inject: ['$pluginsManager'],
  setup() {
    const sideBarBlock = ref<JQuery<HTMLElement> | null>(null);
    const sideBar = ref<JQuery<HTMLElement> | null>(null);
    return {
      sideBarBlock,
      sideBar,
    };
  },
  mixins: [CatalogGetDataMixin],
  methods: {
    scrollAfterLoadingEnds() {
      const unwatch = this.$watch('isLoading', (value) => {
        if (!value) {
          unwatch();
          this.scrollTop();
        }
      });
    },
    onRefLeftBarBlock(ref: Nullable<HTMLElement>) {
      if (ref && !this.sideBarBlock) {
        this.sideBarBlock = $(ref);
        this.sideBar = this.sideBarBlock.find('.sidebar');
      }
    },
    setMobileView() {
      if (this.sideBarBlock && this.sideBar) {
        this.sideBarBlock.hide();
        const navCategories = $('.nested-nav-item__categories');
        navCategories.html('');
        navCategories.append(this.sideBar as JQuery<HTMLElement>);
      }
    },
    setDesktopView() {
      if (this.sideBarBlock && this.sideBar) {
        this.sideBarBlock.show();
        this.sideBarBlock.append(this.sideBar as JQuery<HTMLElement>);
      }
    },
    needShowExtraData() {
      return this.catalogConfig.extra_panel_status !== PANEL_STATUSES.disabled;
    },
    addResponsiveViewListeners() {
      const mediaQueryMobile = window.matchMedia('(max-width: 959px)');
      if (mediaQueryMobile.matches) {
        this.setMobileView();
      }
      mediaQueryMobile.addEventListener('change', (event) => {
        event.matches && this.setMobileView();
      });
      const mediaQueryDesktop = window.matchMedia('(min-width: 960px)');
      mediaQueryDesktop.addEventListener('change', (event) => {
        event.matches && this.setDesktopView();
      });
    },
  },
  computed: {
    isLoading() {
      return this.isLoadingProducts || this.isLoadingSettings;
    },
    selectedShowType() {
      return (
        this.catalogShowType || this.catalogConfig.products_view_mode_default
      );
    },
    ...mapGetters('settings', ['isLoadingSettings']),
    ...mapGetters('catalog', [
      'selectedCategory',
      'categories',
      'isLoadingProducts',
      'catalogShowType',
      'productsErrorMessage',
      'products',
      'brands',
      'extraData',
      'isLoadingCategories',
    ]),
  },
});
