import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-53507cf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "categories-block" }
const _hoisted_2 = { class: "panel-body__grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_item = _resolveComponent("category-item")!
  const _component_panel_collapsed = _resolveComponent("panel-collapsed")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isVisible)
      ? (_openBlock(), _createBlock(_component_panel_collapsed, {
          key: 0,
          title: _ctx.gettext('Каталог'),
          state: _ctx.hasOpen ? 'open' : 'close'
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCategories, (category) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: category.id
                }, [
                  _createVNode(_component_category_item, {
                    "route-name": _ctx.routeName,
                    "category-node": category,
                    "is-firs-node": true
                  }, null, 8, ["route-name", "category-node"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["title", "state"]))
      : _createCommentVNode("", true)
  ]))
}