import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "products-messages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["alert alert-condensed floating-alert", 'alert-' + _ctx.type])
    }, [
      (_ctx.showCloseBtn)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: "#",
            class: "close",
            "data-dismiss": "alert",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx._onClose && _ctx._onClose(...args))),
            textContent: '×'
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ]))
}