import { defineComponent } from 'vue';
import {
  FETCH_BANNERS,
  FETCH_BRANDS,
  FETCH_CATEGORIES,
  FETCH_EXTRA_DATA,
  FETCH_MODERATOR_SUPPLIERS,
  FETCH_PRICE_MIN_MAX,
  FETCH_PRODUCT_MODERATION_STATUSES,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_COUNT_BY_SUPPLIER,
  FETCH_PROMOTIONS,
  FETCH_PROPERTY_VALUES,
  FETCH_SIDEBAR_STICKERS,
  FETCH_SUPPLIERS,
  FETCH_WAREHOUSES,
  FETCH_ROOT_CATEGORIES,
} from '../store/actions.type';
import ApiService from "@spa/api/api.service";
import {LoginOuterResponse} from "@spa/api/schema/catalog";
import CatalogUrls from "@catalog/api/urls";

export default defineComponent({
  methods: {
    getPropertyValues() {
      const routeNamesUsesProperty = [
        'Catalog',
        'Category',
        'SupplierCatalog',
        'CatalogFilter',
      ];
      if (routeNamesUsesProperty.indexOf(this.$route.name as string) > -1) {
        this.$store.dispatch('catalog/' + FETCH_PROPERTY_VALUES);
      }
    },
    getRootCategories() {
      this.$store.dispatch('catalog/' + FETCH_ROOT_CATEGORIES);
    },
    getCategories() {
      return this.$store.dispatch('catalog/' + FETCH_CATEGORIES);
    },
    getStickers() {
      this.$store.dispatch('catalog/' + FETCH_SIDEBAR_STICKERS);
    },
    getPromotions() {
      if (this.catalogConfig.show_promotions) {
        this.$store.dispatch('catalog/' + FETCH_PROMOTIONS);
      }
    },
    getPriceMinMax() {
      if (this.showPriceRange) {
        this.$store.dispatch('catalog/' + FETCH_PRICE_MIN_MAX);
      }
    },
    getBrands() {
      this.$store.dispatch('catalog/' + FETCH_BRANDS);
    },
    getProductModerationStatuses() {
      this.$store.dispatch('catalog/' + FETCH_PRODUCT_MODERATION_STATUSES);
    },
    getModeratorSuppliers() {
      this.$store.dispatch('catalog/' + FETCH_MODERATOR_SUPPLIERS);
    },
    getExtraData() {
      // @ts-expect-error
      if (this.needShowExtraData) {
        this.$store.dispatch('catalog/' + FETCH_EXTRA_DATA);
      }
    },
    getProducts() {
      this.$store.dispatch('catalog/' + FETCH_PRODUCTS);
    },
    getSuppliers(supplier_id?: number) {
      this.$store.dispatch('catalog/' + FETCH_SUPPLIERS, supplier_id);
    },
    getWarehouses() {
      this.$store.dispatch('catalog/' + FETCH_WAREHOUSES);
    },
    getDocumentTitle() {
      return (
        // @ts-expect-error
        [...this.breadCrumbsItems].reverse()[0]?.title ||
        this.$route.params.title ||
        gettext('Каталог')
      );
    },
    getProductsCountBySupplier() {
      this.$store.dispatch('catalog/' + FETCH_PRODUCTS_COUNT_BY_SUPPLIER);
    },
    getBanners(category_id: Nullable<number>) {
      if (this.catalogConfig.display_banners_in_catalog) {
        this.$store.dispatch('catalog/' + FETCH_BANNERS, category_id);
      }
    },
  },
  computed: {
    showPriceRange() {
      return (
        this.catalogConfig.allow_view_prices &&
        this.catalogConfig.allow_view_all_catalog_prices
      );
    },
  },
});
