
import { defineComponent } from 'vue';
import { ProductItemMixin } from '../../mixins/product-item.mixin';
import AddToCartButton from '../add-to-cart-button.component.vue';
import WarehousesSelect from '../warehouses-select.component.vue';
import AddToCompareButton from '../add-to-compare-button.component.vue';
import AddToWaitingListButton from '../add-to-waiting-list-button.component.vue';
import FavoriteButton from '../favorite-button.component.vue';
import RestIndicator from '../rest-indicator.component.vue';
import PropertyValues from '../property-values.component.vue';
import ProductFiles from '../product-files.component.vue';
import EditButton from '../edit-button.component.vue';
import { containsHTML } from '@/entries/spa/common/utils';
import PercentOfFullness from '../percent-of-fullness.component.vue';
import FastViewButton from './../fast-view-button.component.vue';
import PriceWithCoupon from '../price-with-coupon.vue';
import { useCatalogModals } from '../../composables/modals';
import { useShowActionButtonsAndCheckBox } from '@catalog/composables';
import { API_V1_HOST_OUTER } from '@spa/api/urls';
import DefaultImage from '@components/default-image.vue';


export default defineComponent({
  name: 'ProductItemList',
  mixins: [ProductItemMixin],
  components: {
    DefaultImage,
    EditButton,
    ProductFiles,
    PropertyValues,
    RestIndicator,
    AddToCartButton,
    AddToCompareButton,
    FavoriteButton,
    AddToWaitingListButton,
    WarehousesSelect,
    PercentOfFullness,
    FastViewButton,
    PriceWithCoupon,
  },
  setup() {
    const EXTERNAL_HOST = API_V1_HOST_OUTER ? API_V1_HOST_OUTER : '';
    const { showProductDetailModal } = useCatalogModals();
    const showActions = useShowActionButtonsAndCheckBox();

    return { containsHTML, showProductDetailModal, showActions, EXTERNAL_HOST };
  },
});
