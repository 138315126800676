import { computed, Ref, provide, ComputedRef, InjectionKey } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { keyBy } from 'lodash';
import { useCatalogStore } from './store';
import { useSettingsStore } from '@composables/store';

/**
 * @file Функционал позволяющий настраивать отображение таблиц.
 *
 * Основная магия в хуке {@link useTable}.
 *
 * Для каталога покупателя и поставщика созданы обёртки, которые сохраняют настройки в localStorage
 * и инджектят все объекты/методы через {@link catalogInjectKey}.
 */

/** Ключ-идентификатор столбца */
export type ColumnKey = string;

/** Обязательные поля настроек столбца */
type RequiredColumnFields = 'id' | 'text';

/** Колонка со всеми полями */
export interface Column {
  /** Идентификатор колонки */
  id: ColumnKey;
  /** Название шаблона */
  template?: string;
  /** Название колонки */
  text: string;
  /** Не отображать название в хэдере */
  hideHeader: boolean;
  /** Отображать колонку */
  show: boolean;
}

/** Настройки столбца, которые нужно передавать в {@link useTable} */
export type ColumnSettings = PartialExcept<Column, RequiredColumnFields>;

/** Настройки таблицы */
export interface TableSettings {
  /** Скрытые поля таблицы */
  shown: ColumnKey[];
  /** Порядок отображения полец */
  order: ColumnKey[];
}

/** Дефолтные значения настроек столбца */
const defaultColumn: PartialBy<Column, RequiredColumnFields> = {
  hideHeader: false,
  template: undefined,
  show: true,
};

/**
 * Создаёт объекты для работы таблицы. Основная рабочая функция.
 * @param initialColumns Список полей
 * @param settings Настройки таблицы
 */
export function useTable(
  initialColumns: Ref<ColumnSettings[]>,
  settings: Ref<TableSettings>
) {
  /**
   * Извлекает порядок полей из списка столбцов и устанавливает в settings.
   * Полезно для отлавливаения событий v-model (напр. `@update:modeValue`)
   * @param columns Столбцы в актуальном порядке
   */
  function setExtractOrder(columns: Column[]): void {
    settings.value.order = columns.map((c) => c.id);
  }

  /**
   * Извлекает список скрытых полей из списка столбцов и устанавливает в settings.
   * Полезно для отлавливаения событий v-model (напр. `@update:modeValue`)
   * @param columns Столбцы с актуальными значениями show
   */
  function setExtractShown(columns: Column[]): void {
    settings.value.shown = columns.filter((c) => c.show).map((c) => c.id);
  }

  /**
   * Список Всех доступные поля в отсортированном порядке, включая скрытые.
   * При записи обновляет settings.order и settings.hidden, поэтому можно использовать с v-model.
   * Данный список стоит показывать в настройках полей.
   */
  const availableColumns = computed({
    get() {
      // Обогощаем входные параметры необязательными полями с дефолтными значениями
      const columns: Column[] = initialColumns.value.map((column) => ({
          ...defaultColumn,
          ...column,
      }));
      const columnsById = keyBy(columns, 'id');
      const sortedColumns = [...new Set(settings.value.order)]
        .filter((id) => id in columnsById)
        .map((id) => columnsById[id])
        .concat(
        columns.filter((column) => !settings.value.order.includes(column.id))
        );
      sortedColumns.forEach(
        (col) => (col.show = settings.value.shown.includes(col.id))
      );

      return sortedColumns;
    },
    set(value) {
      setExtractOrder(value);
      setExtractShown(value);
    },
  });

  /** Список полей для отображения в таблице */
  const columns = computed(() => {
    return availableColumns.value.filter((column) => column.show);
  });

  /** Переключить отображение поля */
  function toggleColumn(id: string) {
    if (settings.value.shown.includes(id)) {
      settings.value.shown = settings.value.shown.filter((_id) => _id !== id);
    } else {
      settings.value.shown = [...settings.value.shown, id];
    }
  }

  function resetSettings(): void {
    settings.value = undefined;
  }

  return {
    columns,
    availableColumns,
    toggleColumn,
    setExtractOrder,
    setExtractShown,
    resetSettings,
  };
}

const useNSI = window.user.contractor.useNSI;

/** Общие поля доступные во всех каталогах */
const commonColumns: ColumnSettings[] = [
  {
    id: 'photo',
    text: gettext('Фото'),
  },
  {
    id: 'name',
    text: gettext('Наименование'),
  },
  {
    id: 'description',
    text: gettext('Описание'),
  },
  {
    id: 'code',
    text: gettext('Артикул'),
  },
  ...(useNSI
    ? [
        {
          id: 'nsi_code',
          text: gettext('Код НСИ'),
        },
      ]
    : []),
  // {
  //   id: 'stickers',
  //   text: gettext('Тег'),
  // },
  {
    id: 'rest',
    text: gettext('Остаток'),
  },
  {
    id: 'price',
    text: gettext('Цена'),
  },
  {
    id: 'files',
    text: gettext('Файлы'),
  },
];

/** Реактивные поля со всеми свойствами */
export function usePropertyColumns(): ComputedRef<ColumnSettings[]> {
  const { propertyValues } = useCatalogStore().getters;
  return computed(() => {
    return propertyValues.value.map((propertyValue) => (
      {
      id: propertyValue.id.toString(),
      template: 'property',
      text: propertyValue.name,
      show: false,
    }));
  });
}

function useDefaultColumns() {
  const { catalogConfig } = useSettingsStore().getters;
  return computed(() => {
    const columns = Object.entries(
      catalogConfig.value.table_settings.columns_defaults
    ).filter(([, isOn]) => isOn)
     .map(([column]) => column);
    return columns;
  });
}

/**
 * Обёртка над {@link useTable} для таблицы каталога.
 *
 * Сохраняет настройки в localStorage под ключем `catalogTableSettings`.
 *
 * Инджектит через {@link catalogInjectKey}, чтобы было удобно использовать в разных компонентах:
 * в хэдере таблицы, в хэдере каталога, в строке товара.
 * Дополнительно отображает столбцы со свойствами товаров.
 *
 * Шаблоны для столбцов можно найти в product-item-table.component.vue
 * @param storageKey Ключ для хранения настроек в localStorage
 * @param extraColumns Дополнительные колонки
 */
function _useCatalogTable(storageKey: string, extraColumns: ColumnSettings[]) {
  const { catalogConfig } = useSettingsStore().getters;
  const tableSettings = useLocalStorage<TableSettings>(
    storageKey,
    {
      shown: useDefaultColumns().value,
      order: [],
    },
    { mergeDefaults: true }
  );
  const catalogColumns = computed<ColumnSettings[]>(() => {
    const columns = [...commonColumns];

    columns.splice(columns.length - 1, 0, ...extraColumns);

    catalogConfig.value.table_settings.use_property_columns &&
      columns.push(...usePropertyColumns().value);

    return columns.filter(
      (column) =>
        !(column.id in catalogConfig.value.table_settings.columns) ||
        catalogConfig.value.table_settings.columns[column.id]
    );
  });

  const table = useTable(catalogColumns, tableSettings);
  provide(catalogInjectKey, table);
  return table;
}

export function useCatalogTable() {
  return _useCatalogTable('catalogTableSettings', [
    {
      id: 'buy',
      text: gettext(`Кнопка «В корзину»`),
      hideHeader: true
    },
    {
      id: 'common_measure_unit_buyer_name',
      text: gettext('Единица измерения'),
    },
  ]);
}

export function useSupplierCatalogTable() {
  const { catalogConfig } = useSettingsStore().getters;

  let extrColumns = [
    {
      id: 'measure_unit_name',
      text: gettext('Ваша ЕИ'),
    },
    {
      id: 'common_measure_unit_seller_name',
      text: gettext('ЕИ на сайте'),
    },
  ];
  if (catalogConfig.value.product_moderation_on) {
    extrColumns.push(...[
      {
        id: 'status',
        text: gettext('Статус'),
      },
      {
        id: 'progress',
        text: gettext('Прогресс'),
      },
    ]);
  };

  return _useCatalogTable('supplierCatalogTableSettings', extrColumns);
}

/** Ключ для инджекта таблиц каталогов покупателя и поставщика */
export const catalogInjectKey = Symbol('catalogTableSettings') as InjectionKey<
  ReturnType<typeof useCatalogTable | typeof useSupplierCatalogTable>
>;
