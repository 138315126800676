
import { defineComponent } from 'vue';

import {
  ProductDetailModificationsMixin,
} from '@catalog/mixins/product-detail-modifications.mixin';


export default defineComponent({
  name: 'ModificationsTable',

  mixins: [
    ProductDetailModificationsMixin,
  ],
});
