
import { defineComponent, PropType } from 'vue';
import { ProductType } from '../../../api/schema/catalog';

export default defineComponent({
  name: 'ProductFiles',
  props: {
    product: {
      required: true,
      type: Object as PropType<ProductType>,
    },
  },
});
