import { defineAsyncComponent, inject } from 'vue';
import { ProductType } from '@/entries/spa/api/schema/catalog';
import { showModalKey } from '@/entries/spa/composables/modal';
import { useCatalogStore, usePriceOfferStore } from './store';
import axios from 'axios';
import Cookies from 'universal-cookie';

const ProductDetailInModalComponent = defineAsyncComponent(
  () => import('../components/modals/product-detail-in-modal.component.vue')
);
const PhoneNumberModal = defineAsyncComponent(
  () => import('../components/modals/phone-number-modal.component.vue')
)
const OrderLimitModal = defineAsyncComponent(
  () => import('../components/modals/order-limit-modal.component.vue')
)
const PriceOfferComponent = defineAsyncComponent(
  () => import('../components/price-offer-form.component.vue')
);
const AddToWaitingListForm = defineAsyncComponent(
  () => import('../components/add-to-waiting-list-form.component.vue')
);
const UpdateProductStatus = defineAsyncComponent(
  () =>
    import('../components/modals/update-products-status-modal.component.vue')
);
const DeleteSupplierProduct = defineAsyncComponent(
  () =>
    import('../components/modals/product-delete-modal.component.vue')
);

export function useCatalogModals() {
  const showModal = inject(showModalKey);
  const { add_product_to_waiting_list, update_product_status, clear_detail_product_in_modal } =
    useCatalogStore().actions;
  const { send_price_offer } = usePriceOfferStore().actions;


  async function showPhoneNumberModal() {
    showModal({
      componentOptions: {
        component: PhoneNumberModal,
      },
      onClose: () => {},
    })
  }

  async function showOrderLimitModal() {
    showModal({
      componentOptions: {
        component: OrderLimitModal,
      },
      onClose: () => {},
      noBtn: gettext('ОК'),
    })
  }

  async function showProductDetailModal(product: ProductType) {
    showModal({
      componentOptions: {
        component: ProductDetailInModalComponent,
        props: {
          product,
        },
      },
      size: 'xl',
      noBtn: false,
      onClose: () => {
        clear_detail_product_in_modal();
      },
    });
  }

  async function showPriceOfferModal(product: ProductType) {
    showModal({
      title: gettext('Предложить цену к товару'),
      componentOptions: {
        component: PriceOfferComponent,
        props: {
          product,
        },
      },
      yesBtn: gettext('Отправить'),
      onClose: (ans) => {
        if (ans) {
          send_price_offer();
        }
      },
    });
  }

  async function addToWaitingListModal(product: ProductType) {
    showModal({
      title: gettext('Добавить в лист ожидания'),
      yesBtn: gettext('Добавить'),
      noBtn: gettext('Отмена'),
      onClose: (ans, componentRef) => {
        if (ans) {
          add_product_to_waiting_list({
            product_id: product.id,
            count: componentRef?.value?.count,
          });
        }
      },
      componentOptions: {
        component: AddToWaitingListForm,
        props: {
          product,
        },
      },
      size: 'lg',
    });
  }

  async function updateProductsModerationStatus(product_ids: number[]) {
    showModal({
      title: gettext('Установить статус'),
      class: 'update-products-status-modal',

      yesBtn: gettext('Сохранить'),
      noBtn: gettext('Отмена'),
      onClose: (ans, componentRef) => {
        if (ans) {
          update_product_status({
            product_ids: product_ids,
            status_id: componentRef.value.chosenStatus.id,
            date_publication: componentRef.value.datePublication,
          });
        }
      },
      componentOptions: {
        component: UpdateProductStatus,
      },
      size: 'lg',
    });
  }

  async function deleteProduct(product_id: string) {
    showModal({
      class: 'delete-product-modal',
      yesBtn: gettext('Удалить'),
      noBtn: gettext('Отмена'),
      onClose: (ans, componentRef) => {
        function delProduct() {
          const cookies = new Cookies();
          const headers = {
              'X-CSRFToken': cookies.get('csrftoken'),
          };
          const data = {
              productId: product_id,
          };
          axios.post(
            '/api/rest/v1/catalog/supplier/product/delete/',
            data,
            { headers,}
          ).then(response => {
            window.location.href = '/catalog_supplier';
          })
          .catch(error => {
            window.location.href = '/catalog_supplier';
          });
        }
        if (ans) {
          delProduct();
        }
      },
      componentOptions: {
        component: DeleteSupplierProduct,
      },
      size: 'accept',
    });
  }

  return {
    showProductDetailModal,
    showPriceOfferModal,
    updateProductsModerationStatus,
    addToWaitingListModal,
    deleteProduct,
    showPhoneNumberModal,
    showOrderLimitModal,
  };
}
