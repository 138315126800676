
import {defineComponent, onMounted, ref} from 'vue';
import { mapGetters } from 'vuex';
import CategoryNode from './category-node.component.vue';
import { useState } from '../../../../../common/utils';

export default defineComponent({
  name: 'CategoriesTree',
  components: {
    CategoryNode,
  },

  methods: {
    onRef(nodeId: number, ref: any) {
      const findPathToSelectedCategory = <T extends typeof this.rootRefs>(categories: T, id: number, prevCategories?: T) => {
        if (id) {
          for (let refIndex in categories) {
            const category = categories[refIndex];
            if (category.categoryNode.id == id) {
              return (prevCategories || []).concat(category);
            } else {
              if (category.childRefs.length && category.categoryNode.product_count > 0) {
                return findPathToSelectedCategory(
                  category.childRefs,
                  id,
                  (prevCategories || []).concat(category)
                );
              }
            }
          }
        }

        return [];
      };

      const category = findPathToSelectedCategory(this.rootRefs, this.selectedCategory);
      category.map((cat) => cat.setOpened(true));

      this.rootRefs[nodeId] = ref;
    },
    onToggleRootNode(nodeId: number, opened: boolean) {
      if (opened) {
        if (this.openedRootId) {
          this.rootRefs[this.openedRootId]?.setOpened(false);
        }
        this.setOpenedRootId(nodeId);
      } else {
        this.setOpenedRootId(null);
      }
    },
  },
  watch: {
    selectedCategory(mutation: Nullable<number>) {
      if (!mutation && this.openedRootId) {
        this.setOpenedRootId(null);
      }
    },
  },
  setup() {
    const rootRefs = ref<KeyVal>({});
    const [openedRootId, setOpenedRootId] = useState<Nullable<number>>(null);
    const [showMore, setShowMore] = useState(false);
    return {
      showMore,
      setShowMore,
      rootRefs,
      openedRootId,
      setOpenedRootId,
    };
  },
  computed: {
    ...mapGetters('catalog', ['categories', 'isLoadingCategories', 'selectedCategory']),
  },
});
