import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: "#",
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.onClick(e)),
        class: _normalizeClass({ red: _ctx.hasInWaitingList })
      }, [
        (!_ctx.hasInWaitingList)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.gettext('Уведомить о поступлении')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.gettext('Удалить из списка ожидания')), 1))
      ], 2))
    : _createCommentVNode("", true)
}