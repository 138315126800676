import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "catalog-table" }
const _hoisted_2 = { class: "products-table" }
const _hoisted_3 = { class: "products-table__checkbox" }
const _hoisted_4 = {
  key: 0,
  class: "checkbox-wrapper"
}
const _hoisted_5 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_headless_thead = _resolveComponent("headless-thead")!
  const _component_product_item_table = _resolveComponent("product-item-table")!
  const _component_catalog_footer = _resolveComponent("catalog-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("header", {
          class: _normalizeClass(["products-table__header", {
       'grid-supplier': !_ctx.showActions,
       'grid-buyer': _ctx.showActions
  }])
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.showActions)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    id: "products-select-all",
                    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAllProducts && _ctx.toggleAllProducts(...args))),
                    checked: _ctx.isAllSelected
                  }, null, 40, _hoisted_5)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_headless_thead, { columns: _ctx.columns }, {
            default: _withCtx(({ id, text, hideHeader }) => [
              _createElementVNode("div", {
                class: _normalizeClass(`header-item products-table__${id}`)
              }, _toDisplayString(!hideHeader ? text : ''), 3)
            ]),
            _: 1
          }, 8, ["columns"])
        ], 2),
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
            return (_openBlock(), _createBlock(_component_product_item_table, {
              product: product,
              key: product.id
            }, null, 8, ["product"]))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_catalog_footer, { pagination: _ctx.pagination }, null, 8, ["pagination"])
  ], 64))
}