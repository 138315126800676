import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

import { ThumbnailType } from '@spa/api/schema/common';
import {
  ProductType,
  ModificationType,
  WarehouseByProductType,
} from '@spa/api/schema/catalog';

import RestIndicator from '@catalog/components/rest-indicator.component.vue';
import WarehousesSelect from '@catalog/components/warehouses-select.component.vue';
import AddToCartButton from '@catalog/components/add-to-cart-button.component.vue';
import ProductDetailPropertyValues from '@catalog/components/product-detail/product-detail-property-values.component.vue';

export const ProductDetailModificationsMixin = defineComponent({
  components: {
    RestIndicator,
    WarehousesSelect,
    AddToCartButton,
    ProductDetailPropertyValues,
  },

  props: {
    detailProduct: {
      type: Object as PropType<ProductType> | null,
      default: null,
    },
  },

  methods: {
    getCurrencyClass(currency: string): string {
      return currency.toString().toLocaleLowerCase();
    },

    getImageSrc(images: ThumbnailType[]): string {
      const noPhotoImageSrc = '/static/src/images/nophoto.png';

      images.filter((el: ThumbnailType) => !!el.small);
      return images.length !== 0 ? images[0].small : noPhotoImageSrc;
    },

    getSelectedWarehouse(
      modification: ModificationType
    ): WarehouseByProductType | undefined {
      if (this.selectedWarehouses && this.selectedWarehouses[modification.id])
        return this.selectedWarehouses[modification.id];

      if (modification.warehouses?.length) return modification.warehouses[0];

      return undefined;
    },
    getStockCount(modification: ModificationType) {
      const selectedWarehouse = this.getSelectedWarehouse(modification);
      if (selectedWarehouse) return selectedWarehouse.rest;
    },
  },

  computed: {
    ...mapGetters('catalog', ['selectedWarehouses']),
    modifications(): ModificationType[] {
      if (this.detailProduct.modifications_data.length !== 0) {
        return this.detailProduct.modifications_data;
      }
      return this.detailProduct.modifications_data_with_base;
    },

    isModificationsActions(): boolean {
      return (
        this.user.is_contractor || !this.user.is_auth
      );
    },
  },
});
