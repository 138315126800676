
import { defineComponent, PropType } from 'vue';
import Pagination from './pagination.component.vue';
import SelectPerPage from './select-per-page.component.vue';
import { CatalogPaginationType } from '@/entries/spa/api/schema/catalog';

export default defineComponent({
  name: 'CatalogFooter',
  components: {
    Pagination,
    SelectPerPage,
  },
  props: {
    pagination: {
      type: Object as PropType<CatalogPaginationType>,
    },
  },
});
