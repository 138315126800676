
import { defineComponent } from 'vue';
import { ProductListMixin } from '../../mixins/product-list.mixin';
import ProductItemList from './product-item-list.component.vue';
import CatalogFooter from '../catalog-footer.component.vue';
import {useShowActionButtonsAndCheckBox} from "@catalog/composables";

export default defineComponent({
  name: 'CatalogList',
  components: {
    ProductItemList,
    CatalogFooter,
  },
  mixins: [ProductListMixin],
  setup() {
    const showActions = useShowActionButtonsAndCheckBox();
    return { showActions };
  },
});
