
import { PropType, defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import {
  ADD_PRODUCT_TO_COMPARE,
  DELETE_PRODUCT_FROM_COMPARE,
} from '../store/actions.type';
import { ProductType } from '../../../api/schema/catalog';

export default defineComponent({
  name: 'AddToCompareButton',
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
  },
  methods: {
    addToCompare() {
      if (this.isLoading) return;

      if (!this.inCompared) {
        this.$store.dispatch(
          'catalog/' + ADD_PRODUCT_TO_COMPARE,
          this.product.id
        );
      } else {
        this.$store.dispatch(
          'catalog/' + DELETE_PRODUCT_FROM_COMPARE,
          this.product.id
        );
      }
    },
  },
  computed: {
    ...mapGetters('catalog', ['isLoading']),
    inCompared() {
      return !!this.product.in_comparison_list;
    },
    compareEnabled() {
      return this.catalogConfig.compare_enabled;
    },
  },
});
