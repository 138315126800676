import { defineComponent } from 'vue';

import { PropType } from 'vue';
import { DISPLAY_RESTS_MODES } from '../common/config';
import { ProductType } from '@spa/api/schema/catalog';
import { mapGetters } from 'vuex';

export const DisplayRestItemMixin = defineComponent({
  props: {
    product: Object as PropType<ProductType>,
  },
  methods: {
    getDisplayRest(rest: number | string) {
      const stockCount = Number(rest);
      let stockCountStr;
      if (stockCount) {
        const fractionDigits = this.catalogConfig.allow_use_floats ? 2 : 0;
        stockCountStr = stockCount.toFixed(fractionDigits);
      } else {
        stockCountStr = '0';
      }

      if (
        this.catalogConfig.show_rests_limit &&
        stockCount > this.catalogConfig.show_rests_limit
      ) {
        return (
          '> ' +
          this.catalogConfig.show_rests_limit +
          ' ' +
          this.measureUnitName
        );
      }
      if (
        this.catalogConfig.infinity_rests &&
        this.catalogConfig.infinity_rests != 2
      ) {
        return '';
      }

      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_BINARY_ALT']) {
        return '';
      }
      if (stockCount === 0) {
        return gettext('Нет в наличии');
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_BINARY']) {
        return gettext('В наличии');
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_MUCHLESS']) {
        if (stockCount > this.thresholdMode) {
          return gettext('Много');
        }
        if (stockCount <= this.thresholdMode) {
          return gettext('Мало');
        }
      }
      if (this.displayMode === DISPLAY_RESTS_MODES['RESTS_MUCH']) {
        if (stockCount > this.thresholdMode) {
          return gettext('Много');
        } else {
          return stockCountStr + this.measureUnitDisplay;
        }
      }
      return stockCountStr + this.measureUnitDisplay;
    },
  },
  computed: {
    ...mapGetters('settings', ['catalogConfig']),
    measureUnitName() {
      return this.product?.measure_unit_name;
    },
    measureUnitDisplay(): string {
      return this.measureUnitName ? (' ' + this.measureUnitName) : 'шт.';
    },
    displayMode() {
      return this.product?.rests_mode;
    },
    thresholdMode() {
      return this.product?.threshold_mode;
    },
  },
});
