import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c123c0f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-footer__per-page" }
const _hoisted_2 = ["title"]
const _hoisted_3 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      class: "form-control input-sm tip",
      title: _ctx.gettext('Товаров на странице каталога'),
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setPageSize && _ctx.setPageSize(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginateSizes, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option,
          value: option,
          selected: _ctx.pageSize === option
        }, _toDisplayString(option), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2)
  ]))
}