
import { defineComponent, PropType } from 'vue';
import {
  ADD_PRODUCT_TO_FAVORITE,
  DELETE_PRODUCT_FROM_FAVORITE,
} from '../store/actions.type';

export default defineComponent({
  name: 'InFavoriteButton',

  props: {
    active: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    productId: {
      type: Number as PropType<number>,
      default: 0,
    },
    isShowIcon: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isShowText: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  data() {
    return {
      state: this.active,
    };
  },

  watch: {
    active: function (newValue, oldValue){
      this.state = newValue;
    },
  },

  methods: {
    toggleFavorite() {
      const storeAction = this.state
        ? DELETE_PRODUCT_FROM_FAVORITE
        : ADD_PRODUCT_TO_FAVORITE;
      this.$store.dispatch('catalog/' + storeAction, this.productId);
      this.state = !this.state;
    },
  },
});
