import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c335ee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spinner-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "spinner-overlay",
      style: _normalizeStyle({ backgroundColor: _ctx.overlayColor })
    }, [
      _createElementVNode("div", {
        class: "spinner spinner-grow",
        style: _normalizeStyle({
          height: _ctx.size + 'px',
          width: _ctx.size + 'px',
          backgroundColor: _ctx.color,
          top: _ctx.topOffset + 'px',
        })
      }, null, 4)
    ], 4)
  ]))
}