
import { defineComponent } from 'vue';
import ProductItemGrid from './product-item-grid.component.vue';
import CatalogFooter from '../catalog-footer.component.vue';
import { ProductListMixin } from '../../mixins/product-list.mixin';
import {useShowActionButtonsAndCheckBox} from "@catalog/composables";
import CatalogGetDataMixin from '../../mixins/catalog-get-data.mixin';

export default defineComponent({
  name: 'CatalogGrid',
  components: {
    ProductItemGrid,
    CatalogFooter,
  },
  mixins: [ProductListMixin, CatalogGetDataMixin],
  updated() {
    this.getPriceMinMax();
  },
  setup() {
    const showActions = useShowActionButtonsAndCheckBox();
    return { showActions };
  },
});
