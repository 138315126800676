
import { defineComponent, PropType } from 'vue';
import { DELETE_PRODUCT_FROM_WAITING_LIST } from '../store/actions.type';
import { ProductType } from '../../../api/schema/catalog';
import { useCatalogModals } from '../composables/modals';

export default defineComponent({
  name: 'AddToWaitingListButton',
  props: {
    product: {
      required: true,
      type: Object as PropType<ProductType>,
    },
  },
  setup() {
    const { addToWaitingListModal } = useCatalogModals();
    return { addToWaitingListModal };
  },
  methods: {
    onClick(e: Event) {
      e.preventDefault();
      if (!this.hasInWaitingList) {
        this.addToWaitingListModal(this.product);
      } else {
        this.$store.dispatch(
          'catalog/' + DELETE_PRODUCT_FROM_WAITING_LIST,
          this.product.id
        );
      }
    },
  },
  computed: {
    isShow() {
      return (
        this.catalogConfig.can_use_cart &&
        !parseFloat(this.product.stock_count.toString())
      );
    },
    hasInWaitingList() {
      return this.product.in_waiting_list;
    },
  },
});
