import axios from 'axios';
import { getCookies } from '../common/utils';
import {
  API_V1_URL_PATH_INNER
} from "@spa/api/urls";

class ApiService {
  private _token?: string;
  private _HAS_LOGGED?: boolean = false;
  private cookies? : {} = {};

  init() {
    this.cookies = getCookies();
    this.setDefaultHeaders();

    // Раскомментировать при работе с внешним контуром через ngrok.

    // axios.interceptors.request.use(
    //   (config) => {
    //     config.headers['ngrok-skip-browser-warning'] = true;
    //     return config;
    //   },
    //   function (error) {
    //     return Promise.reject(error);
    //   }
    // );
  }

  refreshCookies() {
    this.cookies = getCookies();
  }

  setToken(token: string) {
    this._token = token || '';
  }

  getToken() {
    return this._token || '';
  }

  async setDefaultHeaders() {
  }

  loginExternal(headers?: KeyVal<string>) {
    if (this._HAS_LOGGED) {
      return;
    }
    if (!this.cookies['csrftoken']) {
      this.refreshCookies();
    }
    const request = new XMLHttpRequest();
    request.open('POST', `${API_V1_URL_PATH_INNER}/catalog/logino/`, false);
    request.setRequestHeader("X-CSRFToken", this.cookies['csrftoken']);
    request.send();

    if (request.status === 200) {
      const data = JSON.parse(request.response);
      if (data.success) {
        this._HAS_LOGGED = true;
      }
    }
  }

  syncGetHeader(resource) {
    const headers: KeyVal<string> = {};
    if (this.cookies['csrftoken']) {
      headers['X-CSRFToken'] = this.cookies['csrftoken'];
    }


    let token = null;
    if (resource !== null) {
      // if (resource.startsWith(API_V1_HOST_OUTER)) {
        if (!this._HAS_LOGGED || !this.cookies['outer_user_token']) {
          this.loginExternal(headers);
          this.refreshCookies();
        }
        token = getCookies()['outer_user_token'];
      // }
    } else {
      this.refreshCookies();
      token = this.getToken() || this.cookies['user_token'] || this.cookies['inner_user_token'];
    }
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }

    return headers;
  }

  async getHeader(resource=null) {
    return this.syncGetHeader(resource);
  }

  query<T = any>(resource: string, params?: KeyVal) {
    return axios
      .get<T>(resource, {
        params,
        headers: this.syncGetHeader(resource),
        paramsSerializer(params) {
          return Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        },
      })
      .catch((error) => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  }

  async get<T = any>(resource: string, slug = '') {
    return await axios.get<T>(resource + (slug ? `/${slug}` : '')).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  async post<T = any>(resource: string, data?: KeyVal) {
    return await axios.post<T>(resource, data, {
      headers: await this.getHeader(resource),
    });
  }

  async update<T = any>(
    resource: string,
    slug: string | number,
    data?: KeyVal
  ) {
    return await axios.put<T>(`${resource}/${slug}`, data, {
      headers: await this.getHeader(resource),
    });
  }

  async put<T = any>(resource: string, data: KeyVal) {
    return await axios.put<T>(resource, data, {
      headers: await this.getHeader(resource),
    });
  }

  async delete<T = any>(resource: string) {
    return await axios
      .delete<T>(resource, { headers: await this.getHeader(resource) })
      .catch((error) => {
        throw new Error(`[RWV] ApiService ${error}`);
      });
  }
}

export default new ApiService();
