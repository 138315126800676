import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4ac40e79"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "percent-of-fullness-label"
}
const _hoisted_2 = { class: "percent-of-fullness-percent" }
const _hoisted_3 = { class: "percent-of-fullness-bar" }
const _hoisted_4 = {
  key: 1,
  class: "percent-of-fullness-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "percent-of-fullness",
    class: _normalizeClass('percent-of-fullness-' + _ctx.progressBarColor()),
    style: _normalizeStyle({ width: _ctx.cssWidth + 'px' })
  }, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.percentageOccupancy) + "% ", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        style: _normalizeStyle({ width: _ctx.percentageOccupancy + '%' }),
        class: "percent-of-fullness-bar-value"
      }, null, 4)
    ]),
    (_ctx.isShowText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.progressBarText()), 1))
      : _createCommentVNode("", true)
  ], 6))
}