
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Alert',
  props: {
    type: {
      type: String as PropType<'success' | 'warning' | 'danger' | 'info'>,
      default: 'success',
    },
    showCloseBtn: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    onClose: Function as PropType<() => void>,
  },
  methods: {
    _onClose(e: Event) {
      e.preventDefault();
      this.onClose && this.onClose();
    },
  },
});
