import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1c471ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "property-values" }
const _hoisted_2 = { colspan: "2" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groups, ([groupID, groupName]) => {
      return (_openBlock(), _createElementBlock("table", {
        key: groupID,
        class: "table table-condensed table-responsive"
      }, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_2, [
              _createElementVNode("b", null, _toDisplayString(groupName), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyValues[groupID], (property) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: property.property_id
            }, [
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("span", null, [
                  _createElementVNode("img", {
                    src: property.property_image.icon,
                    alt: property.property_name
                  }, null, 8, _hoisted_3)
                ], 512), [
                  [_vShow, property.property_image?.icon]
                ]),
                _createElementVNode("span", null, _toDisplayString(property.property_name), 1)
              ]),
              _createElementVNode("td", null, [
                _withDirectives(_createElementVNode("span", null, [
                  _createElementVNode("img", {
                    src: property.value_image.icon,
                    alt: property.value.toString()
                  }, null, 8, _hoisted_4)
                ], 512), [
                  [_vShow, property.value_image?.icon]
                ]),
                _createElementVNode("span", null, _toDisplayString(property.value), 1)
              ])
            ]))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}