
import { defineComponent, PropType } from 'vue';

import {
  ModificationType,
  ProductType,
} from '@/entries/spa/api/schema/catalog';

import AddToCartButton from '@catalog/components/add-to-cart-button.component.vue';
import WarehousesSelect from '@catalog/components/warehouses-select.component.vue';
import PriceWithCoupon from '../price-with-coupon.vue';


export default defineComponent({
  name: 'ProductDetailActions',

  components: {
    AddToCartButton,
    WarehousesSelect,
    PriceWithCoupon,
  },

  props: {
    detailProduct: {
      type: Object as PropType<ProductType> | null,
      default: null,
    },
    isModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    asCard: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  methods: {
    isRoleCatalogInner(role: string): boolean {
      return (
        [`${role}Catalog`, `${role}CatalogCategory`].indexOf(
          this.$route.name.toString()
        ) > -1
      );
    },
  },

  computed: {
    baseModification(): ModificationType {
      return this.detailProduct.modifications_data_with_base.find(
        (el: ModificationType) => el.id === this.detailProduct.base_modification_id
      );
    },
    modification(): ModificationType | null {
      return this.detailProduct.modifications_data_with_base
        ? this.baseModification
        : null;
    },

    canAddToCart() {
      return (
        this.catalogConfig.can_use_cart &&
        !this.detailProduct.hide_price_in_open_catalog
      );
    },
    supplierId(): number {
      return this.detailProduct.supplier_id;
    },

    isShowNds(): boolean {
      if (
        !this.catalogConfig.suppliers_show_nds[this.supplierId] ||
        this.catalogConfig.catalog_demo_mode ||
        !this.showPriceRange ||
        !(!this.hasPriceOffer && this.detailProduct.price_range)
      ) return false;

      return (
        !!this.detailProduct &&
        !!this.detailProduct.price_range &&
        !!this.detailProduct.price_range.price_min
      );
    },

    ndsToShow(): string {
      const priceRange = this.detailProduct.price_range;
      let ndsString = `${gettext('С учетом НДС')} `;

      if (
        priceRange.nds_product_price_min &&
        priceRange.nds_product_price_max &&
        priceRange.nds_product_price_min !== priceRange.nds_product_price_max
      ) {
        ndsString += `
          ${gettext('от')} ${priceRange.nds_product_price_min}
          ${gettext('до')} ${priceRange.nds_product_price_max}
        `;
      } else ndsString += `${priceRange.nds_product_price_min || 0}`;

      return `${ndsString} ${this.detailProduct.currency}`;
    },

    isSupplierCatalog(): boolean {
      return this.isRoleCatalogInner('Supplier');
    },
    isModeratorCatalog(): boolean {
      return this.isRoleCatalogInner('Moderator');
    },

    isReferenceMode(): boolean {
      return this.catalogConfig.is_reference_mode;
    },
    hasPriceOffer(): boolean {
      return (
        !this.isReferenceMode &&
        this.detailProduct.has_zero_price &&
        !this.detailProduct.hide_price_in_open_catalog
      );
    },
    showPriceRange(): boolean {
      return !this.isReferenceMode;
    },
    hasRrcPrice() {
      return !!parseFloat(this.detailProduct.rrc_price?.toString());
    },

    showActions(): boolean {
      return (
        this.modulesEnabled.price &&
        (this.showPriceRange || this.isShowNds || this.hasRrcPrice)
      ) || !this.catalogConfig.is_reference_mode
    },
  }
});
