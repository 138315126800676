
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { BrandType } from '../../../api/schema/catalog';

export default defineComponent({
  name: 'BrandsSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
  computed: {
    ...mapGetters('catalog', ['brands']),
    sortedBrands(): BrandType[] {
      return (this.brands as BrandType[]).sort(
        (a, b) => a.position - b.position
      );
    },
  },
});
