import {
  createPopper,
  Placement,
  Instance as PopperInstance,
  Options,
} from '@popperjs/core';
import {
  ComponentPublicInstance,
  onMounted,
  ref,
  shallowRef,
  unref,
} from 'vue';
import {
  createFetch,
  useBreakpoints as defaultUseBreakpoints,
} from '@vueuse/core';
import { useRouteQuery } from '@vueuse/router';
import { useCookies } from '@vueuse/integrations/useCookies';
import { API_V1_URL_PATH } from '../api/urls';

/** Реактивный параметр page */
export function usePageQuery(defaultPage = '1') {
  return useRouteQuery<string>('page', defaultPage);
}

export function useBreakpoints() {
  const breakpoints = defaultUseBreakpoints({
    xs: 375,
    sm: 800,
    md: 960,
    lg: 1280,
    xl: 1440,
  });

  return {
    breakpoints,

    gtXS: breakpoints.greater('xs'),

    lteSM: breakpoints.smallerOrEqual('sm'),
    ltSM: breakpoints.smaller('sm'),
    gtSM: breakpoints.greater('sm'),

    lteMD: breakpoints.smallerOrEqual('md'),
    gtMD: breakpoints.greater('md'),

    gtLg: breakpoints.greater('lg'),
    ltLg: breakpoints.smaller('lg'),
    gteLg: breakpoints.greaterOrEqual('lg'),
    lteLg: breakpoints.smallerOrEqual('lg'),
    // Добавить по необходимости
  };
}

export type usePopperOptions = {
  placement: Placement;
  sameWidth: boolean;
  offset: [number, number];
};

export function usePopper(
  options: Partial<usePopperOptions> = { sameWidth: false, offset: [0, 6] }
) {
  const popperOptions: Partial<Options> = {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: options.offset || [0, 6],
        },
      },
    ],
  };

  if (options.sameWidth) {
    popperOptions.modifiers.push({
      name: 'sameWidth',
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      phase: 'beforeWrite',
    });
  }

  const referenceEl = ref<HTMLElement>(null);
  const setReference = (el: HTMLElement) => {
    if (referenceEl.value) return;
    referenceEl.value = el;
  };
  function setComponentReference(component: ComponentPublicInstance) {
    if (!component || component.$el === undefined) return;
    setReference(component.$el);
  }
  const popperEl = ref<HTMLElement>(null);
  const setPopper = (el: HTMLElement) => {
    if (popperEl.value) return;
    popperEl.value = el;
  };

  const popper = shallowRef<PopperInstance>(null);

  function initPopper(reference: HTMLElement, popper: HTMLElement) {
    return createPopper(reference, popper, popperOptions);
  }

  function update() {
    popper.value && popper.value.update();
  }

  onMounted(() => {
    popper.value = initPopper(unref(referenceEl.value), unref(popperEl.value));
  });

  return {
    popper,
    update,
    referenceEl,
    setReference,
    setComponentReference,
    popperEl,
    setPopper,
  };
}

async function beforeFetch({ options }) {
  const cookies = useCookies(['csrftoken']);

  options.headers = {
    ...options.headers,
    'X-CSRFToken': cookies.get<string>('csrftoken'),
  };
  return { options };
}

export const useBaseFetch = createFetch({
  options: { beforeFetch },
});

export const useApiFetch = createFetch({
  baseUrl: API_V1_URL_PATH,
  options: { beforeFetch },
});
