import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-34ea4f1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "extra-panel__body row" }
const _hoisted_2 = ["href", "target"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_panel_collapsed = _resolveComponent("panel-collapsed")!

  return (_openBlock(), _createBlock(_component_panel_collapsed, {
    title: _ctx.catalogConfig.extra_panel_name,
    state: _ctx.hasOpen ? 'open' : 'close'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraData, (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "extra-panel__item col-sm-3",
            key: i
          }, [
            _createElementVNode("a", {
              href: item.url || '#',
              target: { _blank: item.in_new_window }
            }, [
              _createElementVNode("img", {
                class: "img-responsive center-block",
                src: item.image?.small,
                alt: item.text?.slice(0, 10)
              }, null, 8, _hoisted_3),
              _createElementVNode("span", null, _toDisplayString(item.text), 1)
            ], 8, _hoisted_2)
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "state"]))
}