
import {PropType, defineComponent, ref} from 'vue';

import {mapGetters} from 'vuex';
import {ADD_PRODUCTS_TO_CART} from '../store/actions.type';
import NumberSpinner from '../../../components/number-spinner.components.vue';
import Counter from '../../../components/counter.component.vue';
import {
  AddProductsToCarItem,
  AddProductsToCartRequest,
  ModificationType,
  ProductType,
} from '../../../api/schema/catalog';
import {useState} from '../../../common/utils';
import {useCatalogModals} from '../composables/modals';

export default defineComponent({
  name: 'AddToCartButton',
  components: {
    NumberSpinner,
    Counter,
  },
  props: {
    product: {
      type: Object as PropType<ProductType>,
      required: true,
    },
    modification: {
      required: false,
      type: Object as PropType<ModificationType>,
    },
    onChangeCount: {
      type: Function as PropType<(product: ProductType, count: number) => void>,
      required: false,
    },
    stockCount: {
      type: [Number, String] as PropType<number | string>,
      required: false,
    },
  },
  methods: {
    navigateToProductDetail() {
      window.location.href = this.product.detail_url;
    },
    addToCart(e: Event) {
      e.preventDefault();

      if (!this.user.ad_contact_phone_number && !this.user.phone) {
        this.showPhoneNumberModal()
        return
      }

      if (!this.allowSubmit) {
        return;
      }

      // if (this.product.is_reference) {
      //   this.navigateToProductDetail();
      //   return;
      // }

      if (!this.waitAddingToCart) {
        if (!this.modification && this.product.has_modifications) {
          this.navigateToProductDetail();
          return;
        } else if (!this.hasPriceOffer) {
          let warehouseId = null;
          if (this.product.default_warehouse && this.product.default_warehouse.rest > 0) {
            warehouseId = this.product.default_warehouse.id;
          }
          const productItem: AddProductsToCarItem = {
            modification_id: this.modificationID,
            product_id: this.product.id,
            count: this.count,
            warehouse_id: warehouseId,
          };
          if (this.allowSelectWarehouse && this.selectedWarehouse) {
            if (this.modification) {
              const warehouse = this.modification.warehouses?.find(
                (x) => x.id === this.selectedWarehouse.id
              );
              productItem.warehouse_id = warehouse?.id;
            } else {
              productItem.warehouse_id = this.selectedWarehouse.id;
            }
          }
          const filteredSuppliersIds = $('.suppliers-select input:checked').map(function() {
            return $(this).data('supplier-id');
          }).get();
          const payload: AddProductsToCartRequest = {
            products: [productItem],
            filtered_suppliers: filteredSuppliersIds
          };
          this.allowSubmit = false;

          activeAddToCartRequest++;
          const $cartLink = $('#cart-link');
          $cartLink.addClass('loading');
          $cartLink.find('#new_smallcart').html('<i class="fa fa-spinner fa-pulse"></i>');
          $cartLink.removeAttr('href');
          
          this.$store.dispatch('catalog/' + ADD_PRODUCTS_TO_CART, payload).finally(() => {
            this.allowSubmit = true;
           
            if (activeAddToCartRequest > 0) {
              activeAddToCartRequest--;
            }
            if (activeAddToCartRequest === 0) {
              $cartLink.removeClass('loading');
              updateCartSum()
              $cartLink.prop('href', '/cart/');
            }
          });
        } else {
          this.showPriceOfferModal(this.product);
        }
      }
    },

    onBlurSpinner() {
      this.showWarningPopover();
    },

    // Проверка на кратность
    isMultiple(number, divisor) {
         // Проверка, является ли divisor целым числом
        if (Number.isInteger(divisor)) {
            return number % divisor === 0;
        }

        // Если divisor является числом с плавающей точкой
        let decimalPlaces = divisor.toString().split('.')[1].length;
        let multiplier = Math.pow(10, decimalPlaces);

        // Умножаем оба числа на multiplier и проверяем на целочисленное деление
        return (number * multiplier) % (divisor * multiplier) === 0;
    },

    showWarningPopover() {
      if (this.popoverTimerId) {
        clearTimeout(this.popoverTimerId);
      }

      let warnMessage = '';
      if (this.count < this.minCount) {
        warnMessage = gettext('Значение должно быть больше или равно ') + ` ${this.minCount}`;
        this.setCount(this.minCount);

        /// Закомментил в рамках задачи UGMK-2704 (https://jira.uptrade.ru/browse/UGMK-2704)
        /// } else if (this.count > this.maxCount && this.product.stock_count != 0 ) {
        ///  warnMessage = gettext('Значение должно быть меньше или равно ') + ` ${this.maxCount}`;
        ///  this.setCount(this.maxCount);

      } else if (
        !this.isMultiple(this.count, this.multiplicity)
      ) {
        warnMessage = gettext('Значение должно быть кратно ') + ` ${this.multiplicity}`;
        let newCount = this.count + this.multiplicity - (this.count % this.multiplicity);

        if (!this.hasPreorder && newCount > this.maxCount) {
          newCount = this.maxCount;
        }
        // @ts-expect-error
        this.setCount(this.parseProductCount(newCount));
      }
      if (warnMessage) {
        this.setChanged(true);
        // @ts-ignore
        this.$refs.counterRef?.showPopover(warnMessage)
      } else {
        // @ts-ignore
        this.$refs.counterRef?.destroyPopover()
      }
      // @ts-ignore
      this.popoverTimerId = setTimeout(() => this.$refs.counterRef?.destroyPopover(), 2000);
    },

    _onChangeCount(prevValue, newValue) {
      // @ts-expect-error
      const prevCount = this.parseProductCount(prevValue);
      // @ts-expect-error
      const newCount = this.parseProductCount(newValue, this.fixedCount);
      if (prevCount != newCount || newCount === 0) {
        this.setCount(newCount);
        this.setChanged(true);
        this.onChangeCount && this.onChangeCount(this.product, this.count);
        this.allowSubmit = true;
      }
    },

    isModificationPrice(modification: ModificationType): boolean {
      return !!parseInt(modification.price.toString());
    },
  },
  setup() {
    const [count, setCount] = useState(1);
    const [changed, setChanged] = useState(false);
    const popoverTimerId = ref<NodeJS.Timer | null>(null);
    const {
      showProductDetailModal,
      showPriceOfferModal,
      showPhoneNumberModal,
      showOrderLimitModal
    } = useCatalogModals();


    return {
      count,
      setCount,
      changed,
      setChanged,
      popoverTimerId,
      showPriceOfferModal,
      showProductDetailModal,
      showPhoneNumberModal,
      showOrderLimitModal,
      allowSubmit: true,
    };
  },
  mounted() {
    this.setCount(this.minCount);
  },
  watch: {
    isCreatedOrder(val: boolean) {
      if (val) {
        this.showOrderLimitModal();
      }
    },
    stockCount(mutation: number | string) {
      // @ts-expect-error
      const stockCount = this.parseProductCount(mutation);
      if (stockCount < this.count) {
        this.setCount(stockCount);
      }
    },
  },
  computed: {
    ...mapGetters('catalog', [
      'isLoadingCart',
      'productSelectedWarehouses',
      'modificationWaitAddingToCart',
      'modificationCountInCart',
      'showDemo',
      'isCreatedOrder'
    ]),
    /**
     * Договорная цена
     */
    hasPriceOffer() {
      let hasZeroPrice = false;
      if (this.modification) hasZeroPrice = !this.isModificationPrice(this.modification);
      else hasZeroPrice = this.product.has_zero_price;

      return !this.showDemo && !this.isReferenceMode && hasZeroPrice;
    },
    modificationID() {
      return this.modification
        ? this.modification.id
        : this.product.base_modification_id;
    },
    minCount() {
      // @ts-expect-error
      const min_count = this.parseProductCount(this.product.multiplicity) || 1
      // учитываем кратность при отображении минимального кол-ва для заказа
      return parseFloat((min_count + Number.EPSILON).toFixed(this.fixedCount));
    },
    multiplicity() {
      // @ts-expect-error
      return this.parseProductCount(this.product.multiplicity) || 1;
    },
    fixedCount() {
      // Кол-во нулей после запятой у кратности товара
      return (this.multiplicity.toString().split('.')[1] || '').length;
    },
    step() {
      return this.multiplicity;
    },
    hasPreorder() {
      return this.product.supplier_enable_preorder;
    },
    maxCount() {
      // @ts-expect-error
      return this.parseProductCount(this.stockCount);
    },
    countInCart() {
      let countInCart = 0;
      if (this.modificationCountInCart[this.modificationID]) {
        countInCart = this.modificationCountInCart[this.modificationID]
      } else {
        countInCart = this.product.count_in_cart
      }
      // @ts-expect-error
      return this.parseProductCount(countInCart);
    },
    inCart() {
      return !!this.countInCart;
    },
    inStock() {
      return !!this.maxCount;
    },
    waitAddingToCart() {
      const modification_id = this.modification
        ? this.modification.id
        : this.product.base_modification_id;
      return !!this.modificationWaitAddingToCart[modification_id];
    },
    measureUnit() {
      return this.product.common_measure_unit_short_name || '';
    },
    allowSelectWarehouse() {
      return (
        this.catalogConfig.multi_warehouse_in_catalog &&
        this.product.warehouses.length > 1 &&
        this.selectedWarehouse
      );
    },
    /**
     * Выбранный склад у товара
     */
    selectedWarehouse() {
      return this.productSelectedWarehouses[this.modificationID];
    },
    isReferenceMode() {
      return this.catalogConfig.is_reference_mode;
    },

    isShowNumberSpinner(): boolean {
      return (
        this.product.real_products_count > 0 &&
        (this.inStock || this.hasPreorder) &&
        // !this.isReferenceMode &&
        !this.hasPriceOffer &&
        (!this.product.has_modifications ||
          (this.modification && !this.modification.is_base))
      );
    },
  },
});
