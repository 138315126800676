import Mark from 'mark.js';

const mark = new Mark('body');

function parseUgettext(rawText) {
  function _parse(text) {
    const message = text.replace(/^\[\[(.+)]]$/g, (...args) => args[1]);
    let _message = message.replace(/\[\[([^]]+)]].*/, '');
    const [localeId, dehydratedText] = _message.split('||');
    return [
      localeId,
      dehydratedText?.trim() || '',
      message.replace(_message, ''),
    ];
  }

  const mapperText = {};
  let message = rawText;
  while (message) {
    const [localeId, dehydratedText, nextMessage] = _parse(message);
    if (!localeId) {
      break;
    } else {
      [mapperText[localeId], message] = [dehydratedText, nextMessage];
    }
  }
  return mapperText;
}

function markElement(el, placeholder) {
  let mapperText = parseUgettext(el.textContent);
  if (placeholder) {
    mapperText = parseUgettext(el.getAttribute('placeholder'));
  }
  let localeId = Object.keys(mapperText)[0];
  el.dataset.localeId = localeId;
  el.innerText = mapperText[localeId];
  if (placeholder) {
    el.setAttribute('placeholder', mapperText[localeId]);
  }
  $(el).popover({
    content: `<span class="marked__popover">${gettext(
      'Нажмите, чтобы редактировать'
    )}</span>`,
    trigger: 'hover',
    placement: 'bottom',
    html: true,
  });
  $(el).on('click', function (event) {
    event.preventDefault();
    event.stopPropagation();
    const $modal = $('#localeEditModal');
    const $form = $modal.find('form');

    $form.hide();
    $modal.modal('show');
    $.ajax({
      type: 'GET',
      url: $modal.data('locale-url'),
      data: {
        _id: localeId,
      },
      enctype: 'multipart/form-data',
      success: function (data) {
        $modal.find('[name="_id"]').val(localeId);
        $modal.find('#msgstrDefault').val(data['msgstr_default']);
        $modal.find('#msgstr').val(data['msgstr']);
        $form.show();
      },
    });
  });
}

function getPlaceholderTags() {
  let tags = document.querySelectorAll('input[placeholder]');
  tags.forEach(function (el) {
    el.className = `${el.className} marked__placeholder`;
    markElement(el, true);
  });
}

function enableAllElements() {
  let disabled_elements = document.querySelectorAll('[disabled]');
  disabled_elements.forEach(function (el) {
    el.removeAttribute('disabled');
  });
}

function initMark() {
  getPlaceholderTags();
  noEditElements();
  window.onload = enableAllElements;
  mark.markRegExp(/\[\[([^]]+?)\]\]/g, {
    className: 'marked',
    each: markElement,
  });
}

function bracketsValidator() {
  const bracketsPattern = /{}/g;
  let msgstrDefault = document.getElementById('msgstrDefault').value;
  let msgstr = document.getElementById('msgstr').value;

  let msgstrDefaultMatches = [...msgstrDefault.matchAll(bracketsPattern)];
  let msgstrMatches = [...msgstr.matchAll(bracketsPattern)];
  if (msgstrDefaultMatches.length >= msgstrMatches.length) {
    return true;
  } else {
    return false;
  }
}

function noEditElements() {
  const IGNORE_TAGS_LIST = ['title'];
  const pattern = /\[\[([^]]+?)\]\]/g;
  document.querySelectorAll(IGNORE_TAGS_LIST).forEach(function (elem) {
    let matched = elem.textContent.match(pattern);
    if (matched && matched.length > 0) {
      let mapperText = parseUgettext(matched[0]);
      let fullTitle = '';
      Object.values(mapperText).forEach((value) => {
        fullTitle += value + ' ';
      });
      elem.innerText = fullTitle;
    }
  });
}

document.addEventListener('DOMContentLoaded', function () {
  const $form = $('#localeEditModal form');

  if ($form.length) {
    $form.find('#msgstr').on('input', function (event) {
      let $submitButton = $form.find('button[type="submit"]');
      let $error = $('.msgstr-input-error');
      let $msgstrForm = $('.msgstr-form');
      if (!bracketsValidator()) {
        $submitButton.attr('disabled', true);
        $error.addClass('help-block');
        $error.show();
        $msgstrForm.addClass('has-error');
      } else {
        $submitButton.removeAttr('disabled');
        $error.hide();
        $msgstrForm.removeClass('has-error');
      }
    });
    $form.on('submit', function (event) {
      event.preventDefault();
      let _id = $form.find('input[name="_id"]').val();
      let msgstr = $form.find('input[name="msgstr"]').val();
      let oldMsgstr = '';

      if (!msgstr.length) {
        alert(gettext('Нельзя сохранить пустое значение'));
        return;
      }

      $(`[data-locale-id="${_id}"]`).each(function () {
        oldMsgstr = $(this).html();
        $(this).html(msgstr);
        if ($(this).attr('placeholder')) {
          oldMsgstr = $(this).attr('placeholder');
          $(this).attr('placeholder', msgstr);
        }
      });

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        dataType: 'json',
        data: $form.serialize(),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRFToken', $.cookie('csrftoken'));
          $('#localeEditModal').modal('hide');
        },
        error: function () {
          alert(gettext('Ошибка при сохранении данных'));
          $(`[data-locale-id="${_id}"]`).each(function () {
            $(this).html(oldMsgstr);
            if ($(this).attr('placeholder')) {
              $(this).attr('placeholder', oldMsgstr);
            }
          });
        },
      });
    });

    $('.localeEditMode').on('click', function (event) {
      event.preventDefault();
      $.ajax({
        type: 'POST',
        url: $(this).attr('href'),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRFToken', $.cookie('csrftoken'));
        },
        success: function () {
          window.location.reload();
        },
      });
    });

    window.LOCALE_EDIT_MODE && initMark();
    window.LOCALE_CATALOG_EDIT_MODE = initMark;
  }
});
