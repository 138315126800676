
import { defineComponent, inject } from 'vue';
import ProductItemTable from './product-item-table.component.vue';
import { ProductListMixin } from '../../mixins/product-list.mixin';
import HeadlessThead from './headless-thead.component.vue';
import { mapGetters } from 'vuex';
import { catalogInjectKey } from '../../composables/table';
import CatalogFooter from '../catalog-footer.component.vue';
import { useShowActionButtonsAndCheckBox } from '@catalog/composables';

export default defineComponent({
  name: 'CatalogTable',
  components: {
    ProductItemTable,
    HeadlessThead,
    CatalogFooter,
  },
  mixins: [ProductListMixin],
  setup() {
    const { columns } = inject(catalogInjectKey);
    const showActions = useShowActionButtonsAndCheckBox();

    return { columns, showActions };
  },
  computed: {
    ...mapGetters('catalog', ['selectedProductsLength']),
  },
});
