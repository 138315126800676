import { ProductType, Weights, ProductEditFormType } from '@spa/api/schema/catalog';
import { sum } from 'lodash';

export function computedProductPercentageOccupancy(
  product: Partial<ProductType>,
  weights: Weights
): number {
  if (!weights) {
    return 100;
  }

  let current_weights = 0;
  let full_weights = 0;

  full_weights = sum([
    weights.description,
    weights.images,
    weights.property_value,
    weights.rests,
    weights.price,
    weights.rate_nds,
    weights.multiplicity,
    weights.barcode,
  ]);

  if (product.description?.trim().replace(/\s+/g,' ' )) {
    current_weights += weights.description;
  }
  if (!!product.image) {
    current_weights += weights.images;
  }
  if (!!product.property_values) {
    current_weights += weights.property_value;
  }
  if (product.stock_count) {
    current_weights += weights.rests;
  }
  if (product.price_range || product.has_zero_price) {
    current_weights += weights.price;
  }
  if (product.rate_nds) {
    current_weights += weights.rate_nds;
  }
  if (product.multiplicity) {
    current_weights += weights.multiplicity;
  }
  if (product.barcode?.trim()) {
    current_weights += weights.barcode;
  }

  if (full_weights) {
    return (100 * current_weights) / full_weights;
  }
  // return 100;
  return 0;
}

export function computedProductEditFormPercentageOccupancy(
  product: ProductEditFormType,
  weights: Weights
): number {
  if (!weights) {
    return 100;
  }

  let current_weights = 0;
  let full_weights = 0;

  full_weights = sum([
    weights.description,
    weights.images,
    weights.property_value,
    weights.rests,
    weights.price,
    weights.rate_nds,
    weights.multiplicity,
    weights.barcode,
  ]);

  if (product.has_description) {
    current_weights += weights.description;
  }
  if (!!product.has_image) {
    current_weights += weights.images;
  }
  if (!!product.has_property_values) {
    current_weights += weights.property_value;
  }
  if (product.has_stock_count) {
    current_weights += weights.rests;
  }
  if (product.has_price_range || product.has_zero_price) {
    current_weights += weights.price;
  }
  if (product.has_rate_nds) {
    current_weights += weights.rate_nds;
  }
  if (product.has_multiplicity) {
    current_weights += weights.multiplicity;
  }
  if (product.has_barcode) {
    current_weights += weights.barcode;
  }

  if (full_weights) {
    return (100 * current_weights) / full_weights;
  }
  return 0;
}
