import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import {
  FETCH_CATEGORIES,
  FETCH_DETAIL_PRODUCT,
} from '@catalog/store/actions.type';
import { ModificationType } from '@spa/api/schema/catalog';

import RestIndicator from '@catalog/components/rest-indicator.component.vue';


export const ProductDetailMixin = defineComponent({
  components: {
    RestIndicator,
  },

  computed: {
    ...mapGetters('catalog', [
      'detailProduct',

      'selectedCategory',
      'categories',
    ]),

    modifications(): ModificationType[] {
      if (this.detailProduct.modifications_data.length !== 0) {
        return this.detailProduct.modifications_data;
      }
      return this.detailProduct.modifications_data_with_base;
    }
  },

  methods: {
    getDetailProduct(productId: number) {
      this.$store.dispatch('catalog/' + FETCH_DETAIL_PRODUCT, productId);
    },

    getCategories() {
      this.$store.dispatch('catalog/' + FETCH_CATEGORIES);
    },
  }
});
