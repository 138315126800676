import 'popper.js';
import 'bootstrap';
import 'jquery.cookie';
import 'daterangepicker';
import 'select2';
import {
  recalculateColHeihghtForTable,
  initSelect2,
  appendErrorIconForInputs,
  simpleDialogShow,
  get_select2_ajax_settings,
  getPreloader,
  disable_sending_form,
  enable_sending_form,
  scrollToElem,
  addMessages,
} from '../../utils';

$(function () {
  $('#id_q').on('keypress', function (e) {
    $('.bids-filter-form input[type="hidden"][name="query"]').val(
      $('#id_q').val()
    );
    const enter = 13;
    if (e.which === enter) {
      e.preventDefault();
      $('.bids-filter-form').submit();
    }
  });

  $('.page__heading-sort').on('click', function (e) {
    $('#id_ordering').val($(this).attr('data-sort-id'));
    e.preventDefault();
    $('.bids-filter-form').submit();
  });

  $('.pagination__limit .page-item').on('click', function (e) {
    $('#id_paginate_by').val($(this).data('count'));
    e.preventDefault();
    $('.bids-filter-form').submit();
  });

  $('.page__heading-label').on('click', function (e) {
    $('#id_active_group').val($(this).attr('data-status-id'));
    e.preventDefault();
    $('.bids-filter-form').submit();
  });

  $('.page__heading-filter').on('click', function (e) {
    $('#id_status').val($(this).attr('data-status-id'));
    e.preventDefault();
    $('.bids-filter-form').submit();
  });

  $('.page__heading-wishlist-filter').on('click', function (e) {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('wishlist') === 'yes') {
      $('#id_wishlist').val('no');
    } else {
      $('#id_wishlist').val('yes');
    }

    e.preventDefault();
    $('.bids-filter-form').submit();
  });

  initSelect2();
  // Поле лежит popover content, при инициализации в data-select2_setup нет экранирования -> атрибут кривой,
  // поэтому авто select не срабатывает -> инициализируем заново
  $('#id_category').select2({
    ajax: get_select2_ajax_settings('/api/rest/v1/categories/select2/'),
  });

  $('.create-bet').on('click', function (e) {
    e.preventDefault();
    const form = $(this).closest('form');
    const $this = $(this);
    var $modalBody = $this.closest('.modal-content').find('.modal-body');

    disable_sending_form($this);
    var form_data = new FormData(form[0]);
    form_data.set($this.data('action'), $this.data('lot-id'));
    form_data.set('lot-id', $this.data('lot-id'));

    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      enctype: 'multipart/form-data',
      data: form_data,
      processData: false,
      contentType: false,
      headers: { 'X-CSRFToken': $.cookie('csrftoken') },
      success: function (data) {
        if (data.success === true) {
          window.location.href = data.location;
        } else {
          appendErrorIconForInputs(data.errors, form);
          $($('.alert-danger')[0]).insertBefore($modalBody);
        }
      },
      error: function (response) {
        appendErrorIconForInputs(
          {
            __all__: [
              `${response.statusText} ${response.btn - bid - createstatus}`,
            ],
          },
          $modalBody
        );
      },
      complete: function (response) {
        scrollToElem($('.modal'));
        enable_sending_form($this);
      },
    });
  });

  $('[data-bid-wishlist]').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    const form_data = new FormData();
    form_data.set('add_wishlist', 'add_wishlist');
    form_data.set('bid', $(this).data('bid-wishlist'));
    $.ajax({
      type: 'POST',
      enctype: 'multipart/form-data',
      data: form_data,
      processData: false,
      contentType: false,
      headers: { 'X-CSRFToken': $.cookie('csrftoken') },
      success: (data) => {
        $(this).toggleClass(
          'bi-star_fill',
          data.success && data.action === 'add'
        );
        $('[data-bid-wishlist-count]').html(data.count);
        $('[data-bid-wishlist-count]').toggle(data.count > 0);
      },
    });
  });

  $('[data-clear-wishlist]').on('click', function (e) {
    e.preventDefault();
    simpleDialogShow({
      body: gettext(
        'Удалить закупки в разделе "Избранное"? Отменить действие будет невозможно.'
      ),
      yesText: gettext('Да, удалить'),
      noText: gettext('Нет'),
      onClose: (answer) => {
        if (answer) {
          const form_data = new FormData();
          form_data.set('clear_wishlist', true);
          $.ajax({
            type: 'POST',
            enctype: 'multipart/form-data',
            data: form_data,
            processData: false,
            contentType: false,
            headers: { 'X-CSRFToken': $.cookie('csrftoken') },
            success: (data) => {
              if (data.success) {
                window.location.reload();
              }
            },
          });
        }
      },
    });
  });

  function cancelBidRequest($btn, reasonText) {
    const $form = $btn.closest('form');
    $btn.attr('disabled', 'disabled');
    $btn.text(gettext('Обработка...'));
    let fd = new FormData($form[0]);

    fd.append($btn.attr('name'), $btn.val());
    fd.append('reason', reasonText);
    fd.append('username', 'Chris');
    $.ajax({
      type: 'POST',
      data: fd,
      dataType: 'json',
      processData: false,
      contentType: false,
      headers: {
        'X-CSRFToken': $.cookie('csrftoken'),
      },
      success: function (data) {
        if (data.success) {
          if (data.location) {
            return window.location.replace(data.location);
          }
          document.location.reload();
        } else {
          appendErrorIconForInputs(data.errors, $form);
        }
      },
    });
  }

  $('.cancel_bid_request').click(function (event) {
    event.preventDefault();
    var pressedBtn = $(this);
    const input = `<textarea required class="form-control" rows="10" cols="45" name="bid_request_cancel_reason" id="bid_request_cancel_reason"></textarea>`;
    simpleDialogShow({
      title: `${gettext('Отклонить заявку участника')} ${pressedBtn.data(
        'supplier-name'
      )}?`,
      onClose: (ans, $modal) => {
        if (!ans) {
          return;
        }
        let reasonText = $modal.find('#bid_request_cancel_reason').val();
        if (reasonText) {
          cancelBidRequest(pressedBtn, reasonText);
          $modal.modal('hide');
        } else {
          $modal.find('#bid_request_cancel_reason').addClass('is-invalid');
          if (!$modal.find('.invalid-feedback').length) {
            $modal
              .find('#bid_request_cancel_reason')
              .after(
                `<div class="invalid-feedback">${gettext(
                  'Причина отклонения заявки обязательна!'
                )}</div>`
              );
          }
        }
      },
      manualClose: true,
      body: `${input}`,
    });
  });

  $('.radio-winner').toggleClass('hidden');

  function selectWinners(url, formData) {
    $.ajax({
      url: url,
      type: 'POST',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
      headers: {
        'X-CSRFToken': $.cookie('csrftoken'),
      },
      success: function (data) {
        if (data.success_messages) {
          addMessages(data.success_messages, 'success');
        }
        if (data.success) {
          document.location.reload();
        } else {
          appendErrorIconForInputs(data.errors);
          $(window).resize();
        }
      },
    });
  }

  function switchControlCellOpacity($elem, val) {
    $elem
      .closest('.list_participants__col__item')
      .find('.list_participants__control_cell_opacity')
      .css('opacity', val);
  }

  function switchDeliveredCountDisplay($elem, val) {
    $elem
      .closest('.list_participants__col__item')
      .find('.delivered_count')
      .css('display', val);
  }

  $('input[name="selected-supplier-winner"]').click(function (e) {
    var $this = $(this);
    var supplierID = $this.val();
    var $lotForm = $this.closest('form');
    var winnerAcceptBtns = $lotForm.find('.radio-cancel-winner');

    if (supplierID) {
      winnerAcceptBtns = $lotForm.find(
        `.radio-winner-accept[data-supplier-id="${supplierID}"]`
      );
    }

    winnerAcceptBtns.prop('checked', true);
    winnerAcceptBtns.trigger('change');
  });

  /**
   * Выбор победителя
   */
  $('.select-winner-btn,.select-winner-btn-cancel').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    var $lotForm = $(`form[name="lot_form_${$this.val()}"]`);

    var $winnerRadioBtn = $lotForm.find('.radio-winner');
    $('.radio-cancel-winner').trigger('change');

    if ($winnerRadioBtn.hasClass('hidden')) {
      $('.select-winner-btn-cancel').show();
      $winnerRadioBtn
        .not('.radio-winner-always-hide')
        .closest('.block-hidden')
        .css('display', 'block');
      $winnerRadioBtn.removeClass('hidden');
    } else if ($this.hasClass('select-winner-btn-cancel')) {
      $this.hide();
      $winnerRadioBtn.addClass('hidden');
      $winnerRadioBtn
        .closest('.block-hidden')
        .attr('style', 'display:none !important');
      switchControlCellOpacity($winnerRadioBtn, '1');
    } else {
      var lotID = $lotForm.find('[name="lot_id"]').val();
      var fd = new FormData();
      $lotForm.find('.radio-winner-accept:checked').each(function () {
        var name = $(this).attr('name');
        var value = $(this).val();
        fd.append(name, value);
        const deliveryCount = $(this)
          .closest('.list_participants__col__item')
          .find('.delivery-count-input');
        if (deliveryCount.length) {
          fd.append(deliveryCount.attr('name'), deliveryCount.val());
        }
      });
      fd.append('lot_id', lotID);
      simpleDialogShow({
        body: gettext('Выбрать победителей?'),
        onClose: (ans) =>
          ans && selectWinners($lotForm.data('url-accept-offers'), fd),
      });
    }
    recalculateColHeihghtForTable($lotForm.find('.tables_auto_height'));
  });

  $('.radio-cancel-winner').change(function () {
    let opacityVal = '1';
    const productID = $(this).data('product-id');
    const radioWinnerAcceptProduct = $(`.radio-winner-accept_${productID}`);

    if ($(this).is(':checked')) {
      opacityVal = '0.5';
      radioWinnerAcceptProduct.prop('checked', false);
      switchDeliveredCountDisplay(radioWinnerAcceptProduct, 'none');
    }
    switchControlCellOpacity($(this), opacityVal);
    switchControlCellOpacity(radioWinnerAcceptProduct, opacityVal);
    $(window).resize();
  });

  $('.radio-winner-accept').change(function () {
    const productID = $(this).data('product-id');
    const $cancelRadio = $(`#id_selected-winner_${productID}`);
    const $inputs = $(`.radio-winner-accept_${productID}:checked`);
    const $this = $(this);
    if ($this.is(':checked')) {
      $cancelRadio.prop('checked', false);
      $cancelRadio.trigger('change');
      if ($this.attr('type') === 'radio') {
        $inputs.each(function () {
          if ($this[0] !== this) {
            $(this).prop('checked', false);
          }
        });
      } else {
        if ($inputs.length > 1) {
          switchDeliveredCountDisplay($inputs, 'block');
        }
      }
    } else {
      if (!$inputs.length) {
        $cancelRadio.prop('checked', true);
        $cancelRadio.trigger('change');
      }
      let $selectors = null;
      if ($inputs.length === 1) {
        $selectors = $(`.radio-winner-accept_${productID}`);
      } else {
        $selectors = $(`.radio-winner-accept_${productID}:not(:checked)`);
      }
      switchDeliveredCountDisplay($selectors, 'none');
    }
    $(window).resize();
  });

  function actionJointBid(url, action, value, reasonText = '') {
    const formData = new FormData();
    formData.append(action, value);
    if (reasonText) {
      formData.append('bid_customer_cancel_reason', reasonText);
    }

    $.ajax({
      url: url,
      type: 'POST',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
      headers: {
        'X-CSRFToken': $.cookie('csrftoken'),
      },
      success: function (data) {
        if (data.success_messages) {
          addMessages(data.success_messages, 'success');
        }
        if (data.success) {
          if (data.location) {
            return window.location.replace(data.location);
          }
          document.location.reload();
        } else {
          appendErrorIconForInputs(data.errors);
        }
      },
    });
  }

  $('.action-joint-bid').click(function (e) {
    e.preventDefault();
    const isAccept = !!parseInt($(this).data('accept'));
    const action = $(this).data('action');
    const value = $(this).data('value');
    const url = $(this).data('url');
    const acceptText = isAccept ? 'Подтвердить' : 'Отклонить';
    let bodyDialog = `<p>${gettext(acceptText + ' запрос на участие?')}</p>`;
    const inputReason = `<textarea required class="form-control" rows="10" cols="45" name="bid_customer_cancel_reason" placeholder="${gettext(
      'Причина отклонения..'
    )}"></textarea>`;

    if (!isAccept) {
      bodyDialog += `${inputReason}`;
    }
    simpleDialogShow({
      body: bodyDialog,
      manualClose: true,
      onClose: (ans, $modal) => {
        if (!ans) {
          return;
        }
        let reasonEl = $modal.find($('[name="bid_customer_cancel_reason"]'));
        let reasonText = (reasonEl.val() || '').trim();
        if (reasonText || isAccept) {
          actionJointBid(url, action, value, reasonText);
          $modal.modal('hide');
        } else {
          reasonEl.addClass('is-invalid');
          if (!$modal.find('.invalid-feedback').length) {
            reasonEl.after(
              `<div class="invalid-feedback">${gettext(
                'Причина отклонения обязательна!'
              )}</div>`
            );
          }
        }
      },
    });
  });

  $('.without_nds-field').on('change', function () {
    var nds_field = $(this).parents('td').find('.nds-field');
    if ($(this).is(':checked')) {
      nds_field.attr('disabled', true);
      nds_field.data('val', nds_field.val());
      nds_field.val('');
    } else {
      nds_field.attr('disabled', false);
      nds_field.val(nds_field.data('val'));
    }
  });

  function resizeBetsTableRowHeight() {
    $('.tables_auto_height').each(function (index) {
      recalculateColHeihghtForTable($(this));
    });
  }

  resizeBetsTableRowHeight();

  $(window).resize(function () {
    resizeBetsTableRowHeight();
  });

  $('#lots-info-tab a').on('click', function (e) {
    $('#lots-bets-tables-tab-content .tab-pane').hide();
    const block = $($(this).data('href-bets-tables'));
    block.show();
    resizeBetsTableRowHeight(block);
  });

  $('.show-all-documents').on('click', function () {
    const $this = $(this);
    const documentSelector = '.bid-document';
    const $documents = $(documentSelector);

    const countVisible = $this.data('count-visible');
    const action = $this.data('action');
    const actionText = $this.data('next-text');
    const nextAction = $this.data('next-action');
    const nextActionText = $this.text();

    $this.text(actionText);
    $this.data('action', nextAction);
    $this.data('next-action', action);
    $this.data('next-text', nextActionText);

    if (action === 'show') {
      $documents.css('display', 'block');
    } else {
      const countVisibleInd = countVisible - 1;
      $(documentSelector + ':gt(' + countVisibleInd + ')').css(
        'display',
        'none'
      );
    }
  });

  $('.bid-document a').on('click', function () {
    const $this = $(this);
    const documentId = $this.data('show-document');

    $.ajax({
      url: document.location.pathname,
      method: 'POST',
      data: {
        ACTION_SET_SHOWED_DOCUMENT: documentId,
        csrfmiddlewaretoken: $.cookie('csrftoken'),
      },
    });
  });

  const updateBidLotBetsPrice = (e) => {
    const container = $(e.target).closest('.modal-content');
    const currency = container
      .find('[id$="-currency"]')
      .find(':selected')
      .val();
    let maxFullPrice = 0;

    container
      .find('.product_bet_form__container')
      .each(function (index, element) {
        const price =
          parseFloat($(element).find('input[name$="-price"]').val()) || 0;
        const count =
          parseFloat(
            $(element).find('input[name$="-divisibility_count"]').val()
          ) || 1;

        maxFullPrice += price * count;
      });

    maxFullPrice = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
    }).format(maxFullPrice);

    container.find('.bets_all_price').text(maxFullPrice);
  };

  const changeRouter = {
    'input[id$="-divisibility_count"]': updateBidLotBetsPrice,
    'input[id$="-price"]': updateBidLotBetsPrice,
  };

  for (let selector in changeRouter) {
    $('form').on('change', selector, changeRouter[selector]);
    $(selector).trigger('change');
  }

  const select2selectRouter = {
    'select[id$="-currency"]': updateBidLotBetsPrice,
  };
  for (let selector in select2selectRouter) {
    $('form').on('select2:select', selector, select2selectRouter[selector]);
  }

  $('form').on('change', '.lot_bets-file-upload', function (e) {
    var $this = $(this);
    var url = $this.data('url');
    var $modalBody = $this.closest('.modal-body');
    var preloader = getPreloader();

    $modalBody.hide();
    $modalBody.after(preloader);
    disable_sending_form($this);
    $this.attr('disabled', true);
    $this.closest('label').addClass('disabled');

    function completeLoading() {
      scrollToElem($('.modal'));
      $modalBody.show();
      preloader.remove();
      enable_sending_form($this);
      $this.attr('disabled', false);
      $this.closest('label').removeClass('disabled');
    }

    var file_field = $this;
    var blob_file = file_field[0].files[0];
    var form_data = new FormData();
    form_data.append('document', blob_file);
    if (blob_file !== undefined) {
      $.ajax({
        type: 'POST',
        url: url,
        enctype: 'multipart/form-data',
        data: form_data,
        processData: false,
        contentType: false,
        headers: { 'X-CSRFToken': $.cookie('csrftoken') },
        success: function (data) {
          $this.val(null);
          if (data.success === true) {
            const res = data.results;
            for (const i in res) {
              for (const [key, value] of Object.entries(res[i])) {
                let $el = $modalBody.find(`[name="${key}"]`);
                if (!$el.length) {
                  continue;
                }
                if ($el[0].localName === 'select') {
                  $el.append(`<option value=${value[0]}>${value[1]}</option>`);
                  $el.val(value[0]);
                } else {
                  $el.val(value);
                }
                $el.trigger('change');
              }
            }
          }
          appendErrorIconForInputs(data.errors, $modalBody);
          $($('.alert-danger')[0]).insertBefore($modalBody);
        },
        error: function (response) {
          appendErrorIconForInputs(
            { __all__: [`${response.statusText} ${response.status}`] },
            $modalBody
          );
        },
        complete: function (response) {
          completeLoading();
        },
      });
    } else {
      completeLoading();
    }
  });

  if (/^#back-url=.+?/.test(window.location.hash)) {
    const backBtn = $('[data-back-btn]');
    backBtn.attr('href', window.location.hash.substring(10));
    backBtn.find('[data-back-btn-text]').text(backBtn.data('back-btn'));
  }

  // используем transform stranslate так как изменение этого свойства не вызывает перерисовку контекста и работает быстрее
  function translateXByScroll(event) {
    const $parent = $(this).closest($(this).data('scroll-parent'));
    const $target = $parent.find($(this).data('scroll-x-dependent'));
    const scrollLeft = $(this).scrollLeft();
    $target.css('transform', `translateX(-${scrollLeft}px)`);
  }
  $('[data-scroll-x-dependent]').on('scroll', translateXByScroll);
  $('[data-scroll-x-dependent]').each(function () {
    if ($(this).scrollLeft() > 0) {
      translateXByScroll.call(this);
    }
  });

  function translateYByScroll(event) {
    const $parent = $(this).closest($(this).data('scroll-parent'));
    const $target = $parent.find($(this).data('scroll-y-dependent'));
    const scrollTop = $(this).scrollTop();
    $target.css('transform', `translateY(-${scrollTop}px)`);
  }
  $('[data-scroll-y-dependent]').on('scroll', translateYByScroll);
  $('[data-scroll-y-dependent]').each(function () {
    if ($(this).scrollTop() > 0) {
      translateYByScroll.call(this);
    }
  });
});
