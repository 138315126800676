export default class Overlay {
  el;

  constructor(parent, extraClasses = '') {
    if (parent) {
      parent.insertAdjacentHTML(
        'beforeend',
        `<div class="overlay ${extraClasses}"></div>`
      );
      parent.classList.add('has-overlay');
      this.el = parent.lastElementChild;
    }
  }

  show(zIndex) {
    if (zIndex) {
      this.el.style.zIndex = zIndex;
    }
    this.el.classList.add('overlay_visible');
  }

  hide() {
    this.el.style.zIndex = null;
    this.el.classList.remove('overlay_visible');
  }
}
