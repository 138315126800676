
import { defineComponent, defineEmits, ref } from 'vue';
import { clamp } from 'lodash';

export default defineComponent({
  name: 'NumberSpinner',
  props: [
    'min',
    'max',
    'step',
    'multiplicity',
    'placeholder',
    'inputClass',
    'use_floats',
    'allow_negative',
    'value',
  ],

  setup() {
    const elementRef = ref<any>(null);

    return {
      elementRef,
    };
  },
  emits: {
    changeValue(prevValue: number, newValue: number) {
      return true;
    },
    blur($element: JQuery<HTMLElement>) {
      return true;
    },
  },

  methods: {
    onRef(element: HTMLElement) {
      this.elementRef = $(element);
    },
    onBlur(e: Event) {
      e.preventDefault();
      this.$emit('blur', this.elementRef);
    },
    showPopover(message: string) {
      this.elementRef?.popover({ content: message, placement: 'top' });
      this.elementRef?.popover('show');
    },
    spinUp() {
      const prevValue = this.value || 0;
      const newValue = this.getValue(prevValue + this.step);
      this.$emit('changeValue', prevValue, newValue);
    },
    spinDown() {
      const prevValue = this.value || 0;
      const newValue = this.getValue(prevValue - this.step);
      this.$emit('changeValue', prevValue, newValue);
    },
    onChangeValue(event) {
      const prevValue = this.value || 0;
      // @ts-expect-error
      const newValue = this.parseProductCount(event.target.value);
      this.$emit('changeValue', prevValue, newValue);
    },
    getValue(newValue: number) {
      return clamp(newValue, this.min, this.max);
    },
  },
});
