
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Spinner',
  props: {
    size: {
      type: [String, Number],
      default: 60,
    },
    color: {
      type: String,
      required: false,
    },
    overlayColor: {
      type: String,
      default: '#f7f7f7',
    },
    topOffset: {
      type: [String, Number] as PropType<number | string>,
      default: 150,
    },
  },
});
