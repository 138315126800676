
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'SwitchButton',
  model: {
    prop: 'isEnabled',
    event: 'toggle',
  },
  props: {
    isEnabled: Boolean as PropType<boolean>,
    style: {
      type: Object as PropType<KeyVal<string>>,
      required: false,
    },
  },
  emits: ['toggle'],
  methods: {
    toggle: function () {
      this.$emit('toggle', !this.isEnabled);
    },
  },
});
