function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
export function initCustomNumberInputs() {
  $(document).on('click', '.number .number_controls > div', function (e) {
    e.stopPropagation();

    let $element = $(this).closest('.number');
    let $input = $element.find('input');

    $element.toggleClass(
      'number_input-disabled',
      $element.find('input').prop('disabled')
    );

    if ($input.prop('disabled')) return;

    let step = parseFloat($input.attr('step')) || 1;
    let round = ($input.attr('step') || '0.0').split('.');
    round = round.length === 2 ? round[1].length : 0;
    var value = parseFloat($input.val()) || 0.0; // получаем value инпута или 0
    if ($(this).hasClass('nc-minus')) {
      let min = $input.attr('min');
      if ((min && value > min && value - step >= min) || !min) {
        value = value - step;
      }
    }
    if ($(this).hasClass('nc-plus')) {
      let max = $input.attr('max');
      if (
        (max && value < max && value + step <= max) ||
        !max ||
        max === 'None'
      ) {
        value = value + step;
      }
    }
    $input.val(value.toFixed(round)).change(); // выводим полученное value в инпут; триггер .change() - на случай, если на изменение этого инпута у вас уже объявлен еще какой-то обработчик
  });

  $(document).on('keypress', '.number input', function (e) {
    var x = e.charCode || e.keyCode;
    if (
      (isNaN(String.fromCharCode(e.which)) && x != 46) ||
      x === 32 ||
      x === 13 ||
      (x === 46 && e.currentTarget.innerText.includes('.'))
    )
      e.preventDefault();
  });

  $(document).on('keypress', '.number input', function (e) {
    let $input = $(this);
    if ($input.prop('disabled')) return;
    if (!isNumeric(e.key)) return false;
  });
}
