import {
  AddProductsToCartRequest,
  AddProductsToCartResponse,
  AddProductsToCompareRequest,
  AddToCompareListResponse,
  DeleteProductsFromCompareRequest,
  DeleteFromCompareListResponse,
  AddToWaitingListRequest,
  BannerFilters,
  BannersResponse,
  CatalogExtraDataType,
  CategoriesResponse,
  DeleteToWaitingListRequest,
  GetProductsResponse,
  PriceMinMaxResponse,
  PriceOfferRequest,
  ProductFilterParams,
  ProductPropertyValuesResponse,
  ProductsCountBySupplierType,
  ProductType,
  PromotionsListResponse,
  StickersResponse,
  SupplierPriceTypeResponse,
  ProductModerationStatusesResponse,
  ProductSuppliersResponse,
  SuppliersFilterParams,
  BrandsResponse,
  WarehousesInfoTypeResponse,
  UpdateProductStatusResponse,
  UpdateProductStatusRequest,
  VendorBrands,
} from '../../../api/schema/catalog';
import CatalogUrls from './urls';
import CommonUrls from '../../../api/urls';
import ApiService from '../../../api/api.service';
import { CountResponse, SuccessResponse } from '../../../api/schema/common';
import { SuppliersListResponse } from '../../../api/schema/supplier';

export const CatalogService = {
  /**
   * Получить список товаров
   **/
  async getProducts(params: ProductFilterParams) {
    return await ApiService.post<GetProductsResponse>(
      CatalogUrls.products,
      params
    );
  },
  /**
   * Детальная инф. о товаре
   * @param product_id
   */
  async productDetail(product_id: number) {
    return await ApiService.query<ProductType>(
      CatalogUrls.productDetail.replace('{id}', product_id.toString())
    );
  },
  async getPropertyValues(params: ProductFilterParams) {
    return await ApiService.post<ProductPropertyValuesResponse>(
      CatalogUrls.propertyValues,
      params
    );
  },
  /**
   * Получить список товаров в избранном
   **/
  async getFavorites() {
    return await ApiService.query(
      CatalogUrls.getFavorites,
    );
  },
  /**
   * Получить список товаров в избранном
   **/
  async clearFavorites() {
    return await ApiService.post(
      CatalogUrls.clearFavorites,
    );
  },
  /**
   * Добавить товар в избранное
   * @param id объекта
   * @param model название модели
   */
  async addProductToFavorite(id: number, model = 'Product') {
    const params = {
      object_id: id.toString(),
      model: model,
    };
    return await ApiService.query<CountResponse>(
      CatalogUrls.addProductToFavorite,
      params
    );
  },
  /**
   * Удалить из избранного
   * @param id объекта
   * @param model название модели
   */
  async deleteProductFromFavorite(id: number, model = 'Product') {
    const params = {
      object_id: id.toString(),
      model: model,
    };
    return await ApiService.query<CountResponse>(
      CatalogUrls.deleteProductFromFavorite,
      params
    );
  },
  /**
   * Очистить список товаров в сравнении
   **/
  async clearCompareList() {
    return await ApiService.post(
      CatalogUrls.clearCompareList,
    );
  },
  /**
   * Получить товары в избранном
   **/
  async getCompareItems() {
    return await ApiService.query(
      CatalogUrls.getCompareItems,
    );
  },
  /**
   * Добавить товары в список сравнения
   * @param product_ids список id товаров
   */
  async addProductsToCompare(product_ids: AddProductsToCompareRequest) {
    return await ApiService.post<AddToCompareListResponse>(
      CatalogUrls.addProductsToCompare,
      {
        ids: product_ids,
      }
    );
  },
  /**
   * Удалить товары из списка сравнения
   * @param product_ids список id товаров
   */
  async deleteProductFromCompare(
    product_ids: DeleteProductsFromCompareRequest
  ) {
    return await ApiService.post<DeleteFromCompareListResponse>(
      CatalogUrls.deleteProductsFromCompare,
      {
        ids: product_ids,
      }
    );
  },
  /**
   * Добавить товар в корзину
   * @param request
   * @param is_from_real
   * @type AddProductsToCartRequest
   */
  async addProductsToCart(
    request: AddProductsToCartRequest,
    is_from_real: boolean
  ) {
    return await ApiService.post<AddProductsToCartResponse>(
      CatalogUrls.addProductsToCart,
      { ...request, is_from_real: is_from_real }
    );
  },
  /**
   * Предложить цену
   */
  async sendPriceOffer(data: PriceOfferRequest) {
    return await ApiService.post<PriceOfferRequest>(
      CatalogUrls.sendPriceOffer,
      data
    );
  },
  /**
   * Добавить в список ожидания
   */
  async addToWaitingList(data: AddToWaitingListRequest) {
    return await ApiService.query<SuccessResponse>(
      CatalogUrls.addToWaitingList,
      data
    );
  },
  /**
   * Удалить из списка ожидания
   */
  async deleteFromWaitingList(data: DeleteToWaitingListRequest) {
    return await ApiService.query<SuccessResponse>(
      CatalogUrls.deleteFromWaitingList,
      data
    );
  },
  /**
   * Получить все доступные категории
   */
  async getCategories(params: ProductFilterParams) {
    return await ApiService.post<CategoriesResponse>(
      CatalogUrls.getCategories,
      params
    );
  },
  /**
   * Получить все категории
   */
  async getAllCategories(params: ProductFilterParams) {
    return await ApiService.post<CategoriesResponse>(
      CatalogUrls.getAllCategories,
      params
    );
  },
  /**
   * Получить все доступные статусы модерации товара
   */
  async getProductModerationStatuses() {
    return await ApiService.get<ProductModerationStatusesResponse>(
      CatalogUrls.getProductModerationStatuses
    );
  },
  /**
   * Установить товарам новый статус модерации
   */
  async updateProductStatus(params: UpdateProductStatusRequest) {
    return await ApiService.post<UpdateProductStatusResponse>(
      CatalogUrls.updateProductStatus,
      params
    );
  },
  /**
   * Получить всех поставщиков модератора
   */
  async getModeratorSuppliers() {
    return await ApiService.get<ProductSuppliersResponse>(
      CatalogUrls.getModeratorSuppliers
    );
  },
  /**
   * Список акций
   */
  async getPromotionsList() {
    return await ApiService.query<PromotionsListResponse>(
      CatalogUrls.getPromotionsList,
      { show_on_main: 1 }
    );
  },
  /**
   * Список стикеров в сайдбаре
   */
  async getSidebarStickers() {
    return await ApiService.query<StickersResponse>(CatalogUrls.getStickers, {
      show_on_sidebar: 1,
    });
  },
  /**
   * Список баннеров
   */
  async getBanners(data: Nullable<BannerFilters>) {
    return await ApiService.query<BannersResponse>(
      CatalogUrls.getBanners,
      data || {}
    );
  },
  /**
   * Список брендов
   */
  async getBrands() {
    return await ApiService.query<BrandsResponse>(CatalogUrls.getBrands);
  },
  /**
   * Дополнительные данные в каталоге
   */
  async getCatalogExtraData() {
    return await ApiService.query<CatalogExtraDataType[]>(
      CatalogUrls.getCatalogExtraData
    );
  },
  /**
   * Получить мин./макс. цену для выборки
   */
  async getPriceMinMax(params: ProductFilterParams) {
    return await ApiService.post<PriceMinMaxResponse>(
      CatalogUrls.getPriceMinMax,
      params
    );
  },
  /**
   * Получить кол-во товаров у поставщиков
   */
  async getProductsCountBySupplier(params?: ProductFilterParams) {
    return await ApiService.post<ProductsCountBySupplierType>(
      CatalogUrls.getProductsCountBySupplier,
      params
    );
  },
  /**
   * Получить все типы цены доступные поставщику
   */
  async getSupplierPriceTypes() {
    return await ApiService.get<SupplierPriceTypeResponse>(
      CatalogUrls.getSupplierPriceTypes
    );
  },
  /**
   * Получить все склады поставщика
   */
  async getSupplierWarehouses() {
    return await ApiService.get<WarehousesInfoTypeResponse>(
      CatalogUrls.getSupplierWarehouses
    );
  },
  /**
   * Изменить текущий тип цены у поставщика
   */
  async changeCurrentSupplierPriceTypes(price_type_id: number) {
    return await ApiService.get<SuccessResponse>(
      CatalogUrls.changeCurrentSupplierPriceTypes.replace(
        '{price_type_id}',
        price_type_id.toString()
      )
    );
  },
  /**
   * Получить поставщиков
   */
  async getSuppliers(params?: SuppliersFilterParams) {
    return await ApiService.query<SuppliersListResponse>(
      CommonUrls.suppliersList,
      params
    );
  },
  /**
   * Получить брэнды
   */
  async getVendorBrands(params: ProductFilterParams) {
    return await ApiService.post<VendorBrands>(
      CatalogUrls.vendorProducts,
      params,
    );
  },
  /**
   * Получить все доступные склады
   */
  getWarehouses: async function () {
    return await ApiService.query<WarehousesInfoTypeResponse>(
      CatalogUrls.getWarehouses
    );
  },
};
