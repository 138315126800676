
import { defineComponent, PropType } from 'vue';
import { ProductPropertyValues } from '@spa/api/schema/catalog';

const __default__ = defineComponent({
  name: 'ProductDetailPropertyValues',

  props: {
    label: {
      type: String as PropType<string> | null,
      default: null,
      required: false,
    },
    countryOfOrigin: {
      type: String as PropType<string> | null,
      default: null,
      required: false,
    },
    propertyValues: {
      type: Array as PropType<ProductPropertyValues[]>,
      default: () => [],
    },
    propertyValuesSimple: {
      type: Array as PropType<{[key: string]: string}[]>,
      default: () => [],
    },
    sliceTo: {
      type: Number as PropType<number> | null,
      default: null,
      required: false,
    },
    isAccordion: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    accordionLength: {
      type: Number as PropType<number>,
      default: 5,
      required: false,
    },
    inHeader: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data() {
    return {
      isAccordionOpened: false,
    }
  },

  methods: {
    checkPropertyValue(propertyValue: ProductPropertyValues): boolean {
      return (
        !!propertyValue.property_name &&
        !!propertyValue.value &&
        propertyValue.on_product_detail
      );
    },

    formatPropertyValue(propertyValue: ProductPropertyValues): {
      [key: string]: string;
    } {
      return {
        label: propertyValue.property_name,
        value: propertyValue.value.toString(),
      };
    },
    formatPropertyValueSimple(propertyValue: {[key: string]: string}): {
      [key: string]: string;
    } {
      return {
        label: propertyValue.value__property__name,
        value: propertyValue.value__value,
      }
    },

    groupPropertyValues(
      values: Record<string, string>[]
    ): Record<string, string>[] {
      const propertyValues = new Map<string, string[]>();
      for (const val of values) {
        if (propertyValues.has(val.label)) {
          propertyValues.get(val.label).push(val.value);
        } else {
          propertyValues.set(val.label, [val.value]);
        }
      }
      return Array.from(propertyValues.entries()).map(
        ([label, valueArr]) => ({label, value: valueArr.join(', ')})
      );
    },

    sortPropertValues(
      prev: ProductPropertyValues,
      next: ProductPropertyValues
    ): number {
      return next.priority - prev.priority;
    },

    toggleAccordion() {
      this.isAccordionOpened = !this.isAccordionOpened;
    },
  },

  computed: {
    isShowAccordion(): boolean {
      if (this.isAccordion && this.propertyValuesLength > this.accordionLength) {
        if (this.sliceTo) return this.accordionLength > this.sliceTo;
        return true;
      }
      return false;
    },
    propertyValuesData(): { [key: string]: string }[] {
      let propertyValues = [];

      if (this.propertyValues && this.propertyValues.length !== 0) {
        propertyValues = this.propertyValues
          .filter(this.checkPropertyValue)
          .sort(this.sortPropertValues)
          .map(this.formatPropertyValue);
      } else if (this.propertyValuesSimple && this.propertyValuesSimple.length !== 0) {
        propertyValues = this.propertyValuesSimple
          .map(this.formatPropertyValueSimple);
      }
      propertyValues = this.groupPropertyValues(propertyValues);
      propertyValues = this.sliceTo
        ? propertyValues.slice(0, this.sliceTo)
        : propertyValues;

      if (this.isShowAccordion && !this.isAccordionOpened) {
        propertyValues = propertyValues.slice(0, this.accordionLength);
      }

      if (this.countryOfOrigin) {
        propertyValues.push({"label": gettext("Страна производителя"), "value": this.countryOfOrigin})
      }

      return propertyValues;
    },
    propertyValuesLength(): number {
      if (this.propertyValues.length !== 0) return this.propertyValues.length;
      if (this.propertyValuesSimple.length !== 0) return this.propertyValuesSimple.length;
      return 0;
    },
    propertiesLabelColor(): string {
      return this.inHeader ? "#292929" : "#7D7D7D"
    }
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7dea6b53": (_ctx.propertiesLabelColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__