
import { defineComponent, PropType, ref } from 'vue';
import { CategoryNodeMixin } from '../../mixins/category-node.mixin';
import { useState } from '../../../../common/utils';

export default defineComponent({
  name: 'CategoryItem',
  mixins: [CategoryNodeMixin],
  props: {
    isFirsNode: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  mounted() {
    this.setOpened(this.isFirsNode as boolean);
  },
  setup() {
    const [opened, setOpened] = useState<boolean>(false);
    const childRefs = ref<any[]>([]);
    return {
      opened,
      setOpened,
      childRefs,
    };
  },
  watch: {
    opened(mutation) {
      if (!mutation) {
        this.childRefs?.forEach((el: any) => {
          el?.setOpened(false);
        });
      }
    },
  },
});
