import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-172bc714"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-list-header" }
const _hoisted_2 = { class: "catalog-list-header__item" }
const _hoisted_3 = {
  key: 0,
  class: "checkbox-wrapper"
}
const _hoisted_4 = ["checked"]
const _hoisted_5 = { class: "catalog-list" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_product_item_list = _resolveComponent("product-item-list")
  const _component_catalog_footer = _resolveComponent("catalog-footer")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showActions)
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
              _createElementVNode("input", {
                type: "checkbox",
                id: "products-select-all",
                onChange: _cache[0] || (_cache[0] = (...args) => (_ctx.toggleAllProducts && _ctx.toggleAllProducts(...args))),
                checked: _ctx.isAllSelected
              }, null, 40, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(_ctx.gettext('Выбрать все')), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
        return (_openBlock(), _createBlock(_component_product_item_list, {
          key: product.id,
          product: product
        }, null, 8, ["product"]))
      }), 128))
    ]),
    _createVNode(_component_catalog_footer, { pagination: _ctx.pagination }, null, 8, ["pagination"])
  ], 64))
}