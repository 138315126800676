
import { defineComponent, PropType } from 'vue';
import CategoryItem from './category-item.component.vue';
import PanelCollapsed from '../panel-collapsed.component.vue';
import { mapGetters } from 'vuex';
import { PANEL_STATUSES } from '../../common/config';
import { CategoryNodeType } from '../../../../types/app.types';

export default defineComponent({
  name: 'Categories',
  components: {
    CategoryItem,
    PanelCollapsed,
  },
  props: {
    routeName: {
      type: String as PropType<string>,
      default: 'Category',
      required: false,
    },
  },
  computed: {
    ...mapGetters('catalog', ['selectedCategory', 'categories']),
    selectedCategories(): CategoryNodeType[] {
      return this.categories;
    },
    hasOpen() {
      return this.catalogConfig.category_panel_status === PANEL_STATUSES.opened;
    },
    isVisible() {
      return (
        this.catalogConfig.category_panel_status !== PANEL_STATUSES.disabled
      );
    },
  },
});
