import {
  SELECT_ALL_PRODUCT,
} from '../store/actions.type';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export const ProductListMixin = defineComponent({
  methods: {
    toggleAllProducts() {
      this.$store.dispatch(
        'catalog/' + SELECT_ALL_PRODUCT,
        !this.isAllSelected
      );
    },
  },
  computed: {
    ...mapGetters('catalog', [
      'products',
      'selectedProductsLength',
      'pagination',
      'pageSize',
    ]),
    paginationShortMode() {
      // @ts-expect-error
      return this.projectSettings.pagination_short_mode;
    },
    isAllSelected() {
      return (
        this.selectedProductsLength &&
        this.selectedProductsLength === this.products.length
      );
    },
    isSupplierCatalog() {
      return (
        ['SupplierCatalog', 'SupplierCatalogCategory', 'SupplierCatalogWithSupplierCategory'].indexOf(
          this.$route.name as string
        ) > -1
      );
    },
  },
});
