
import { defineComponent, ref } from 'vue';
import { CategoryNodeMixin } from '../../../mixins/category-node.mixin';
import { useState } from '../../../../../common/utils';

export default defineComponent({
  name: 'CategoryNodeExtend',
  mixins: [CategoryNodeMixin],
  setup() {
    const [opened, setOpened] = useState<boolean>(false);
    const childRefs = ref<any[]>([]);
    return {
      opened,
      setOpened,
      childRefs,
    };
  },
  watch: {
    opened(mutation) {
      if (!mutation) {
        this.childRefs?.forEach((el: any) => {
          el?.setOpened(false);
        });
      }
    },
  },
});
