export function initAutocompleteSearch(
  $container,
  selectorInput,
  selectorDropdown
) {
  const $input = $container.find(selectorInput);
  $input.each(function () {
    const inputEl = this;

    inputEl.addEventListener('keyup', function () {
      const $this = $(this);
      const val = $this.val();
      const $dropdown = $container.find(selectorDropdown);

      changeLocationQuery({ pk: '', query: val });

      var minLength = this.getAttribute("autocomplete-min-length");
      minLength = parseInt(minLength);

      if (!minLength) {
        minLength = 3
      }

      if (val.length < minLength) {
        return;
      }

      $.get($this.data('url'), { search: val }).done((data) => {
        if (data?.results?.length) {
          const liItems = data.results.map((item) => {
            let text = '';
            if (item.code) {
              text = `${item.code} ${item.name}`;
            } else {
              text = `${item.name}`;
            }
            const highlighted = changeItem(text, val);
            return `<li data-id="${item.id}">${highlighted}</li>`;
          });
          const $optionsList = $(`<ul>${liItems.join('\n')}</ul>`);
          $optionsList.find('li').on('click', function () {
            const pk = $(this).data('id');
            const search = $(this).text();
            changeLocationQuery({ pk, query: search });
            $this.val(search);
            $dropdown.removeClass('show');
            $this.closest('form').submit();
          });
          $dropdown.html($optionsList);
          $dropdown.addClass('show');
        } else {
          $dropdown.removeClass('show');
        }
      });
    });
  });

  const clearButton = $('.clear-search');
  clearButton.on('click', function () {
    $input.val('');
    $(this).closest('form').submit();
  });

  $(document).click(function (event) {
    if (!$(event.target).closest('.autocomplete-container').length) {
      if ($(selectorDropdown + '.show').length) {
        $(selectorDropdown + '.show').removeClass('show');
      }
    }
  });

  function changeLocationQuery(newQueryObj) {
    const query = {};
    const queryList = window.location.search
      .slice(1)
      .split('&')
      .filter(Boolean)
      .map((x) => x.split('='));
    queryList.forEach(([k, v]) => (query[k] = v));
    const newQuery = Object.assign(query, newQueryObj);
    const url =
      window.location.pathname +
      Object.entries(newQuery)
        .filter(([, v]) => !!v)
        .reduce((acc, [k, v]) => acc + `${k}=${v}&`, '?')
        .slice(0, -1);
    window.history.replaceState({}, '', url);
  }

  function changeItem(item, query) {
    const html = $('<div></div>');
    let leftPart, middlePart, rightPart, strong;
    let query_splitted = query.split(' ');
    for (let q_index in query_splitted) {
      let query_part = query_splitted[q_index];
      let query_len = query_part.length;
      if (query_len === 0) {
        continue;
      }
      let i = item.toLowerCase().indexOf(query_part.toLowerCase());
      while (i > -1) {
        leftPart = item.substr(0, i);
        middlePart = item.substr(i, query_len);
        rightPart = item.substr(i + query_len);
        strong = $('<strong> </strong>').text(middlePart);
        html.append(document.createTextNode(leftPart)).append(strong);
        item = rightPart;
        i = item.toLowerCase().indexOf(query_part.toLowerCase());
      }
    }
    return html.append(document.createTextNode(item)).html();
  }
}
