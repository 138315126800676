import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b282ed4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "catalog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination = _resolveComponent("pagination")!
  const _component_select_per_page = _resolveComponent("select-per-page")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_pagination, {
      class: "catalog-footer__pagination",
      pagination: _ctx.pagination
    }, null, 8, ["pagination"]),
    _createVNode(_component_select_per_page, { class: "catalog-footer__select-per-page" })
  ]))
}