
import { defineComponent, PropType, ref } from 'vue';
import { useToggle } from '@vueuse/shared';
import { OptionData as Select2OptionData } from 'select2';
import Collapse from '@/entries/spa/components/collapse.component.vue';
import {
  ProductPropertyShort,
  ProductPropertyValueShort,
  PropertyValuesFilterParam,
} from '../../../api/schema/catalog';
import { useState } from '../../../common/utils';

export default defineComponent({
  name: 'FieldPropertyValues',
  components: {
    Collapse,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
    widgetName: {
      type: String as PropType<'select2' | 'checkboxes'>,
      default: 'checkbox',
      required: false,
    },
    property: {
      type: Object as PropType<ProductPropertyShort>,
      required: true,
    },
    selectedPropertyValues: {
      type: Array as PropType<PropertyValuesFilterParam[]>,
      default: () => [],
    },
    onChange: {
      required: false,
      type: Function as PropType<
        (property_id: number, values_ids?: Nullable<number[]>) => void
      >,
    },
  },
  methods: {
    toggleValuesRollUp(e: Event) {
      e.preventDefault();
      this.setValuesRollUp(!this.valuesRollUp);
    },
    getPropertyValueText(value: ProductPropertyValueShort) {
      if (
        !value.product_count ||
        !this.catalogConfig.show_count_products_property_values_in_sidebar
      ) {
        return this.truncateString(value.name, 50);
      }
      return `${this.truncateString(value.name, 50)} (${value.product_count})`;
    },
    hasSelectedValue(value: ProductPropertyValueShort) {
      return this.selectedValues.indexOf(value.id) > -1;
    },
    toggleCheckboxes(value: ProductPropertyValueShort) {
      const values = [...this.selectedValues];
      const idx = values.indexOf(value.id);
      if (idx > -1) {
        values.splice(idx, 1);
      } else {
        values.push(value.id);
      }
      this.setSelectedValues(values);
      this.onChange && this.onChange(this.property.id, values);
    },
    _onChangeSelect2() {
      if (this.onChange) {
        const values = (
          this.select2?.select2('data') as Select2OptionData[]
        ).map(({ id }) => parseInt(id));
        this.setSelectedValues(values);
        this.onChange(this.property.id, values);
      }
    },
    onRefSelect2(el: HTMLSelectElement) {
      if (!this.select2) {
        this.select2 = $(el);
        this.select2.on('select2:select', this._onChangeSelect2);
        this.select2.on('select2:unselect', this._onChangeSelect2);
      }
      this.select2.select2({
        placeholder: this.select2.data('placeholder'),
        allowClear: true,
        multiple: true,
        tags: true,
        width: '100%',
      });
    },
  },
  setup() {
    const [valuesRollUp, setValuesRollUp] = useState<boolean>(false);
    const [selectedValues, setSelectedValues] = useState<number[]>([]);
    const [collapseOpen, toggleCollapse] = useToggle(false);
    const select2 = ref<Nullable<JQuery<HTMLSelectElement>>>(null);
    return {
      valuesRollUp,
      setValuesRollUp,
      select2,
      selectedValues,
      setSelectedValues,
      collapseOpen,
      toggleCollapse,
    };
  },
  watch: {
    selectedProperty(mutations: Nullable<PropertyValuesFilterParam>) {
      this.setSelectedValues(mutations?.values || []);
      this.select2?.trigger('change');
    },
    property() {
      const selectedValues = this.property.values.filter(
        (el) => this.hasSelectedValue(el) && !el.disabled
      ).map((el) => el.id);
      this.setSelectedValues(selectedValues || []);
    },
  },
  mounted() {
    if (this.selectedProperty?.values) {
      this.setSelectedValues(this.selectedProperty.values);
    }
  },
  computed: {
    selectedProperty(): PropertyValuesFilterParam | undefined {
      return this.selectedPropertyValues?.find(
        (x) => x.property_id === this.property.id
      );
    },
    availableValues() {
      return this.property.values.slice(0, 5);
    },
    collapsedValues() {
      return this.property.values.slice(5);
    },
    showCollapse() {
      return this.property.values.length > 5;
    },
  },
});
