import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1fb22bf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "font-size-16"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = ["name", "id", "checked", "disabled", "onChange"]
const _hoisted_5 = ["for"]
const _hoisted_6 = ["name", "id", "checked", "disabled", "onChange"]
const _hoisted_7 = {
  class: "rollup-btn",
  style: {"padding":"0 15px"}
}
const _hoisted_8 = {
  key: 1,
  style: {"padding":"0 15px"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["data-placeholder", "data-property-id"]
const _hoisted_11 = ["value", "textContent", "disabled", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_collapse = _resolveComponent("collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.widgetName === 'checkboxes')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.showTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.property.name) + ":", 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableValues, (value) => {
            return (_openBlock(), _createElementBlock("div", {
              key: value.id,
              style: {"padding":"0 15px"}
            }, [
              _createElementVNode("label", {
                for: 'property-value-' + value.id,
                class: "checkbox-wrapper"
              }, [
                _createElementVNode("input", {
                  type: "checkbox",
                  name: 'property-value-' + value.id,
                  id: 'property-value-' + value.id,
                  checked: _ctx.hasSelectedValue(value),
                  disabled: value.disabled,
                  onChange: ($event: any) => (_ctx.toggleCheckboxes(value))
                }, null, 40, _hoisted_4),
                _createTextVNode(" " + _toDisplayString(_ctx.getPropertyValueText(value)), 1)
              ], 8, _hoisted_3)
            ]))
          }), 128)),
          (_ctx.showCollapse)
            ? (_openBlock(), _createBlock(_component_collapse, {
                key: 1,
                open: _ctx.collapseOpen
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collapsedValues, (value) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: value.id,
                      style: {"padding":"0 15px"}
                    }, [
                      _createElementVNode("label", {
                        for: 'property-value-' + value.id,
                        class: "checkbox-wrapper"
                      }, [
                        _createElementVNode("input", {
                          type: "checkbox",
                          name: 'property-value-' + value.id,
                          id: 'property-value-' + value.id,
                          checked: _ctx.hasSelectedValue(value),
                          disabled: value.disabled,
                          onChange: ($event: any) => (_ctx.toggleCheckboxes(value))
                        }, null, 40, _hoisted_6),
                        _createTextVNode(" " + _toDisplayString(_ctx.getPropertyValueText(value)), 1)
                      ], 8, _hoisted_5)
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["open"]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("div", _hoisted_7, [
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleCollapse()), ["prevent"]))
            }, _toDisplayString(_ctx.collapseOpen
            ? _ctx.gettext('Свернуть')
            : _ctx.gettext('Показать все') + ` (${_ctx.property.values.length})`), 1)
          ], 512), [
            [_vShow, _ctx.property.values.length > 5]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.widgetName === 'select2')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_ctx.showTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.property.name), 1))
            : _createCommentVNode("", true),
          _createElementVNode("select", {
            class: "select2-raw",
            ref: _ctx.onRefSelect2,
            "data-placeholder": _ctx.gettext('Нажмите чтобы выбрать'),
            "data-property-id": _ctx.property.id,
            multiple: ""
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.property.values, (value) => {
              return (_openBlock(), _createElementBlock("option", {
                key: value.id,
                value: value.id,
                textContent: _toDisplayString(_ctx.getPropertyValueText(value)),
                disabled: value.disabled,
                selected: _ctx.hasSelectedValue(value)
              }, null, 8, _hoisted_11))
            }), 128))
          ], 8, _hoisted_10)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}