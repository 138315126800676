function usernameValidation($field) {
  const username = $field.val().trim();
  $field.val(username);
  let errors = [
    /[^a-zA-Z0-9\-_.@]/g.test(username),
    /^\./.test(username),
    /\.$/.test(username),
  ];
  if (errors.some((error) => error === true)) {
    addFormFieldError($field, gettext('Логин содержит недопустимые символы'));
  }
}

function passwordValidation($field) {
  const password = $field.val().trim();
  $field.val(password);
  if (/[а-я]/gi.test(password)) {
    addFormFieldError($field, gettext('Пароль содержит недопустимые символы'));
  }
}

export function passwordLengthValid(password) {
  return password.length - password.replace(/[a-z]/gi, '').length >= 5;
}

export function passwordUpperValid(password) {
  return /[A-Z]/.test(password);
}

export function passwordDigitValid(password) {
  return /[0-9]/.test(password);
}

function passwordComplexValidation($field) {
  const password = $field.val().trim();
  $field.val(password);
  const errors = [
    !passwordLengthValid(password),
    !passwordUpperValid(password),
    !passwordDigitValid(password),
  ];

  if (errors.some((error) => error === true)) {
    addFormFieldError($field, gettext('Выполнены не все условия'));
  }

  const $form = $field.closest('form');
  const $confirmField = $form.find(
    `input[data-password-confirm="${$field.attr('name')}"]`
  );
  if ($confirmField && $confirmField.val()) {
    toggleFormFieldValid($confirmField);
  }
}

function passwordConfirmValidation($field) {
  const passwordConfirm = $field.val().trim();
  $field.val(passwordConfirm);
  const $passwordField = $field
    .closest('form')
    .find(`input[name="${$field.data('password-confirm')}"]`);
  const password = $passwordField.val();
  if (password !== passwordConfirm) {
    addFormFieldError($field, gettext('Пароли не совпадают'));
  }
}

function emailValidation($field) {
  const email = $field.val().trim().toLowerCase();
  $field.val(email);
  const parts = email.split('@');
  const rules = [
    parts.length === 2 && parts[0].length >= 1,
    /^[a-z0-9._%+-]+$/.test(parts[0]),
    /^[a-z0-9.-]+\.[a-z]{2,}$/i.test(parts[1])
  ];
  if (rules.some((rule) => rule !== true)) {
    addFormFieldError($field, gettext('Некорректный адрес электронной почты'));
  }
}

function fileSizeValidation($field) {
  const blobFile = $field[0].files[0];
  if (!blobFile) {
    return true;
  }
  const maxSize = parseInt($field.data('max-size'), 10) || 0;
  if (maxSize && blobFile.size > maxSize) {
    const mbSize = Math.round(maxSize / 1024 / 1024);
    addFormFieldError(
      $field,
      gettext(
        `Файл слишком большой, максимальный допустимый размер ${mbSize} Мб`
      )
    );
    return false;
  }
  return true;
}

function fileTypeValidation($field) {
  const blobFile = $field[0].files[0];
  if (!blobFile) {
    return true;
  }
  const fileTypes = $field.data('file-types')
    ? $field
        .data('file-types')
        .split(',')
        .map((fileType) => fileType.trim())
    : [];
  if (fileTypes.length && !fileTypes.includes(blobFile.type)) {
    addFormFieldError($field, gettext(`Выбран недопустимый тип файла`));
  }
  return true;
}

function fileExtValidation($field) {
  const blobFile = $field[0].files[0];
  if (!blobFile) {
    return true;
  }
  const fileExtensions = $field.data('file-extensions')
    ? $field
        .data('file-extensions')
        .split(',')
        .map((fileType) => fileType.trim())
    : [];
  if (
    fileExtensions.length &&
    !fileExtensions.includes(
      blobFile.name.split('.').slice(-1).pop().toLowerCase()
    )
  ) {
    addFormFieldError($field, gettext(`Выбран недопустимый тип файла`));
  }
  return true;
}

function numberLteValidation($field) {
  const $target = $($field.data('lte-field'));
  if ($target.length) {
    const maxVal = parseFloat($target.val());
    const value = parseFloat($field.val());
    if (maxVal && value > maxVal) {
      const error =
        $field.data('error') ||
        gettext('Значение "От" должно быть меньше значения "До"');
      addFormFieldError($field, gettext(error));
    }
  }
}

function numberGteValidation($field) {
  const $target = $($field.data('gte-field'));
  if ($target.length) {
    const minVal = parseFloat($target.val());
    const value = parseFloat($field.val());
    if (minVal && value < minVal) {
      const error =
        $field.data('error') ||
        gettext('Значение "До" должно быть больше значения "От"');
      addFormFieldError($field, error);
    }
  }
}

function dateGteValidation($field) {
  const $target = $($field.data('gte-field'));
  if ($target.length) {
    const minVal = $target.data('daterangepicker').startDate;
    const value = $field.data('daterangepicker').startDate;
    if (minVal && value < minVal) {
      const error =
        $field.data('error') ||
        gettext('Значение "До" должно быть больше значения "От"');
      addFormFieldError($field, error);
    }
  }
}

function targetValidation($field) {
  const $targetField = $($field.data('validation-target'));
  if ($targetField.length) {
    toggleFormFieldValid($targetField);
  }
}

function phoneNumberValidation($field) {
  const phone = $field.val().trim();
  $field.val(phone);
  if (
    !/^(?:(?:\([0-9]{3}\))|(?:[0-9]{3}))[\s-]{0,1}[0-9]{3}[\s-]{0,1}[0-9]{2}[\s-]{0,1}[0-9]{2}$/g.test(
      phone
    )
  ) {
    addFormFieldError($field, gettext('Некорректный номер телефона'));
  }
}

function additionalPhoneValidation($field) {
  const phone = $field.val().trim();
  $field.val(phone);
  if (!/^[0-9]{3,4}$/g.test(phone)) {
    addFormFieldError($field, gettext('Некорректный добавочный номер'));
  }
}

function innNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{4}\s?(?:(?:[0-9]{6})|(?:[0-9]{8}))$/g.test(value)) {
    addFormFieldError(
      $field,
      gettext(
        'ИНН должен быть в формате 0000 000000 для юрлиц или 0000 00000000 для ИП'
      )
    );
  }
}

function kppNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}$/g.test(value)) {
    addFormFieldError($field, gettext('КПП должен быть в формате 000 000 000'));
  }
}

function ogrnNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{4}$/g.test(value)) {
    addFormFieldError(
      $field,
      gettext('ОГРН должен быть в формате 000 000 000 0000')
    );
  }
}

function ogrnipNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{6}$/g.test(value)) {
    addFormFieldError(
      $field,
      gettext('ОГРНИП должен быть в формате 000 000 000 000000')
    );
  }
}

function bikNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}$/g.test(value)) {
    addFormFieldError($field, gettext('БИК должен быть в формате 000 000 000'));
  }
}

function payAccountNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{5}\s?[0-9]{5}\s?[0-9]{5}\s?[0-9]{5}$/g.test(value)) {
    addFormFieldError(
      $field,
      gettext('Расчетный счёт должен быть в формате 00000 00000 00000 00000')
    );
  }
}

function corrAccountNumberValidation($field) {
  const value = $field.val().trim();
  $field.val(value);
  if (!/^[0-9]{5}\s?[0-9]{5}\s?[0-9]{5}\s?[0-9]{5}$/g.test(value)) {
    addFormFieldError(
      $field,
      gettext(
        'Корреспондентский счет должен быть в формате 00000 00000 00000 00000'
      )
    );
  }
}

function getErrorParents($field) {
  let $parent = $field.closest('[data-append-errors]').length
    ? $field.closest('[data-append-errors]')
    : $field.closest('.form-group').find('[data-append-errors]');
  $parent = $parent.length ? $parent : $field.closest('.controls');
  $parent = $parent.length ? $parent : $field.parent();
  return $parent;
}

function addFormFieldError($field, msg) {
  $field.addClass('is-invalid');
  const $parent = getErrorParents($field);
  if (!$parent.find(`.invalid-feedback:contains("${msg}")`).length) {
    $parent.append(`<div class="invalid-feedback">${msg}</div>`);
  }
}

const validationFuncs = {
  username: usernameValidation,
  password: passwordValidation,
  passwordComplex: passwordComplexValidation,
  passwordConfirm: passwordConfirmValidation,
  email: emailValidation,
  fileSize: fileSizeValidation,
  fileType: fileTypeValidation,
  fileExt: fileExtValidation,
  numberLte: numberLteValidation,
  numberGte: numberGteValidation,
  dateGte: dateGteValidation,
  targetValidation: targetValidation,
  phoneNumber: phoneNumberValidation,
  additionalPhone: additionalPhoneValidation,
  innNumber: innNumberValidation,
  kppNumber: kppNumberValidation,
  ogrnNumber: ogrnNumberValidation,
  ogrnipNumber: ogrnipNumberValidation,
  bikNumber: bikNumberValidation,
  payAccountNumber: payAccountNumberValidation,
  corrAccountNumber: corrAccountNumberValidation,
};

export default function toggleFormFieldValid($field, removeErrors = true) {
  if (['checkbox', 'radio'].includes($field.attr('type'))) {
    return true;
  }

  if (removeErrors) {
    $field.removeClass('is-invalid');
    const $parent = getErrorParents($field);
    $parent.find('.invalid-feedback').remove();
  }

  let $label = $(`label[for='${$field.attr('id')}']`);
  if ($label) {
    let value = $field.val();

    if (!value && $label.closest('[data-upload-field]')) {
      const $uploadField = $label.closest('[data-upload-field]');
      const currentFile = $uploadField.find('[data-original] a').attr('href');
      const deleted = $uploadField
        .find('[data-original] input[type="checkbox"][name$="-clear"]')
        .is(':checked');
      if (currentFile && !deleted) {
        value = currentFile;
      }
    }

    let isValue = !!value;
    if (Array.isArray(value)) {
      isValue = value.length;
    }

    if ($field.data('validation') && isValue) {
      const funcNames = $field.data('validation').split(',');
      funcNames.forEach((funcName) => {
        if (validationFuncs[funcName]) {
          validationFuncs[funcName]($field);
        }
      });
    }

    if (isValue && !$field.hasClass('is-invalid')) {
      $label.find('.bi.asterisk').toggleClass('asterisk done');
    } else {
      $label.find('.bi.done').toggleClass('asterisk done');
    }
    $label
      .find('.bi.asterisk, .bi.done')
      .toggleClass('asterisk_red', $field.hasClass('is-invalid'));
  }
}
