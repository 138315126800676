import { toRefs } from '@vueuse/core';
import settingsStore from '../store/settings.module';
import { createStoreComposable } from '../store/vuex-helpers';
import {computed} from "vue";

export const useSettingsStore = createStoreComposable(
  settingsStore,
  'settings'
);

export function useCatalogConfig() {
  const { catalogConfig } = useSettingsStore().getters;
  return toRefs(catalogConfig);
}

/**
 * Хук с настройками проекта в Vuex
 */
export function useProjectSettings() {
  const { projectSettings } = useSettingsStore().getters;
  return toRefs(projectSettings);
}

export function useUser() {
  const { projectSettings } = useSettingsStore().getters;
  return computed(() => projectSettings.value.user);
}
