import 'bootstrap';
import toggleFormFieldValid from './js/validation';

var CURRENT_LANGUAGE = window.CURRENT_LANGUAGE || 'ru';
/**
 * Блокирование отправки формы в которой аяксово загружается файл
 **/
export const disable_sending_form = (file_field) => {
  const form = file_field.closest('form');
  form.find('[type="submit"]').attr('disabled', true);
};

/**
 * Разблокирование отправки формы к которой аяксово загружался файл
 */
export const enable_sending_form = (file_field) => {
  const form = file_field.closest('form');
  form.find('[type="submit"]').attr('disabled', false);
};

export const scrollToElem = (container = null) => {
  container = container ? container : $('html, body');
  container.animate({ scrollTop: container.offset().top }, 'slow');
};

export const checkFileSize = (
  fileField,
  maxSize,
  SuccessAction,
  ErrorAction
) => {
  if (fileField[0].files[0].size > maxSize * 1024 * 1024) {
    ErrorAction(fileField);
    return false;
  } else {
    SuccessAction(fileField);
    return true;
  }
};

export const initSelect2 = (container = null) => {
  const replace_languages_map = {
    'pt-br': 'pt-BR',
    'zh-cn': 'zh-CN',
  };
  const language = replace_languages_map[CURRENT_LANGUAGE] || CURRENT_LANGUAGE;
  container = container ? container : $('body');
  // eslint-disable-next-line no-undef
  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $.fn.select2.defaults.set('width', '100%');
  $.fn.select2.defaults.set('placeholder', '');
  $.fn.select2.defaults.set('language', CURRENT_LANGUAGE);
  $.fn.select2.amd.define(
    'select2/i18n/' + CURRENT_LANGUAGE,
    [],
    require('select2/src/js/select2/i18n/' + language)
  );

  container.find('span.select2').remove();
  container.find('span.select2-simple').remove();

  container.find('.select2-simple').each(function (i, el) {
    let $element = $(el);
    let opts = $element.data('select2_opts') || {};
    if (Object.keys(opts).length) {
      opts.ajax = get_select2_ajax_settings(opts.url);
      delete opts.url;
    }

    opts.minimumResultsForSearch = opts.minimumResultsForSearch || 5;

    $element.select2(opts);
    $element.on('change', function () {
      toggleFormFieldValid($(this));
    });
  });
};

/**
 * Берет в переданном контейнере пустую форму по классу .empty-form и вставляет в конец контейнера
 * @param container
 */
export const addFormsetFromEmpty = (container) => {
  const emptyForm = container.find('.empty-form').first();
  const newForm = emptyForm.clone();
  const $totalForm = container.find('input[id$="-TOTAL_FORMS"]').first();
  const elemNameStart = $totalForm.attr('name').replace('-TOTAL_FORMS', '');
  const totalForms = parseInt($totalForm.val());

  updateElementIndex(newForm, elemNameStart, totalForms);
  container.append(newForm.html());
  $totalForm.val(totalForms + 1);
};

export const deleteFormsetSlot = (container, formsetRow) => {
  const $totalForm = container.find('input[id$="-TOTAL_FORMS"]').first();
  const totalForms = $totalForm.val();
  const elemIdStart = $totalForm.attr('id').replace('-TOTAL_FORMS', '');
  const elemNameStart = $totalForm.attr('name').replace('-TOTAL_FORMS', '');
  const hasId = !!formsetRow
    .find(`input[id^="${elemIdStart}"][id$="-id"]`)
    .val();

  if (hasId) {
    formsetRow
      .find(`input[id^="${elemIdStart}"][id$="DELETE"]`)
      .prop('checked', true);
    formsetRow.hide();
  } else {
    formsetRow.remove();
    $totalForm.val(totalForms - 1 < 0 ? 0 : totalForms - 1);

    const formsetClassSel = `.${formsetRow[0].className
      .replace(/\bshow\b/g, ' ')
      .replace(/\bactive\b/g, ' ')
      .trim()
      .replace(/\s+/g, '.')}`;
    container.find(formsetClassSel).each(function (i, el) {
      if (i === 0) {
        // empty-form
        return;
      }
      updateElementIndex($(el), elemNameStart, i - 1);
    });
  }
};

/**
 * Обновляет префиксные индексы инпутов и прочих элементов формсета
 * @param form
 * @param prefix
 * @param idx
 */
export function updateElementIndex(form, prefix, idx) {
  const idRegex = new RegExp(prefix + '-(\\d+|__prefix__)-');
  const replacement = prefix + '-' + idx + '-';

  // childElementSelector - дочерние элементы в форме, а именно
  // input,select,textarea,label,div - данные в самом формсете
  // a,ul,li - в навигации табов
  // span,button - в кастомных виджетах
  const childElementSelector =
    'input,select,textarea,label,div,a,ul,li,span,button';
  // data-id, data-name, data-form-prefix - виджет асинхронной загрузки файла
  const childElementAttrNames = [
    'for',
    'id',
    'name',
    'href',
    'data-id',
    'data-name',
    'data-form-prefix',
  ];

  if (form.attr('id'))
    form.attr('id', form.attr('id').replace(idRegex, replacement));
  if (form.attr('href'))
    form.attr('href', form.attr('href').replace(idRegex, replacement));

  form.find(childElementSelector).each(function (i, el) {
    const $el = $(el);
    $.each(childElementAttrNames, function (i_, attrName) {
      if ($el.attr(attrName))
        $el.attr(attrName, $el.attr(attrName).replace(idRegex, replacement));
    });
  });
}

/**
 * Пересчет всех отображаемых индексов/порядковых номеров формсета
 * (отображаемый индекс на 1 больше индекса самого формсета)
 *
 * @param container - контейнер, в котором надо пересчитать,
 * @param indexSel - селектор, в котором прописано значение порядкового номера
 * @param withHidden - посчитать вместе со спрятанными формсетами (когда добавляется новый формсет лота,
 * все формсеты продуктов спрятаны + надо учитывать, что всегда есть одна скрытая empty-form)
 */
export const updateVisibleIndexForFormsets = (
  indexSel,
  container = null,
  withHidden = false
) => {
  if (!container) {
    container = $('form');
  }
  if (withHidden) {
    container.find(indexSel).each(function (index, value) {
      $(value).text(index);
    });
  } else {
    container.find(indexSel + ':visible').each(function (index, value) {
      $(value).text(index + 1);
    });
  }
};

export function addMessages(messages, type = 'danger', container = null) {
  container = container || $('form:not(.header-search__form)');
  for (let message of messages) {
    container.prepend(
      `<div class="alert alert-block alert-${type}"><p>${message}</p></div>`
    );
  }
}

/**
 * Добавляет инпутам переданные в словаре ошибки
 * @param errorsData {Object}
 * @param container: {JQuery Element}
 * @param withAppendNavlink {bool} с добавлением ошибок к навигационным вкладкам (меню) табов
 */

export const appendErrorIconForInputs = (
  errorsData,
  container = null,
  withAppendNavlink = true
) => {
  container = container ? container : $('form:not(.header-search__form)');
  $('.is-invalid').removeClass('is-invalid');
  $('.invalid-feedback').remove();
  $('form .alert-danger').remove();
  $('form .text-invalid').closest('.input-icon-append').remove();
  $('form .text-invalid').closest('.asteriskError').text('');
  $('.ajax-file-label').removeClass('btn-outline-warning');
  $('.ajax-file-label').addClass('btn-outline-white');

  for (let key in errorsData) {
    let errors = errorsData[key];
    let field = container.find(`[name=${key}]`);
    let fakeField;
    if (field.is(':hidden')) {
      fakeField = container.find(`[id="id_${key}"][name="${key}-fake"]`);
    }
    if (!field.length) {
      field = container.find(`#div_id_${key}`);
    }

    if (key === '__all__') {
      addMessages(errors, 'danger', container);
      continue;
    }

    for (let i = 0; i < errors.length; i++) {
      let error = errors[i];
      if (field.parent('.ajax-file-label').hasClass('ajax-file-label')) {
        field.parent('.ajax-file-label').removeClass('btn-outline-white');
        field.parent('.ajax-file-label').addClass('btn-outline-warning');
      } else {
        let label = $(`label[for="${field.attr('id')}"]`);
        if (label.children('.asteriskError').length) {
          label
            .children('.asteriskError')
            .html(
              ' <span class="pointer text-invalid"\n' +
                '       id="' +
                field.attr('id') +
                '-invalid"\n' +
                '       data-trigger="hover"\n' +
                '       data-toggle="invalid-popover"\n' +
                '       data-template=\'<div class="popover popover-invalid" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>\'\n' +
                '       data-placement="bottom"\n' +
                '       data-content=\'<div id="error_' +
                field.attr('id') +
                '">' +
                error +
                "</div>'>\n" +
                '     <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">\n' +
                '       <path fill-rule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>\n' +
                '     </svg>\n' +
                ' </span>'
            );
        } else {
          let $appendToEl = field
            .closest('.form-group')
            .find('[data-append-errors]');
          if (!$appendToEl.length) {
            $appendToEl = field.closest('.controls, .custom-control');
          }
          if (!$appendToEl.length) {
            $appendToEl = field.find('.controls, .custom-control');
          }
          if ($appendToEl.length) {
            $appendToEl.append(
              `<div id="error_${key}" class="invalid-feedback">${error}</div>`
            );
          }
        }
      }
      field.addClass('is-invalid');
      fakeField && fakeField.addClass('is-invalid');
      field
        .closest('.form-group')
        .find('.bi.asterisk, .bi.done')
        .addClass('asterisk_red');
    }
  }
  if (withAppendNavlink) {
    appendErrorIconForTabNavLinks(container);
  } else {
    const invalidPopover = $('[data-toggle="invalid-popover"]');
    invalidPopover.popover({
      html: true,
      sanitize: false,
    });
  }
};

/**
 * Обозначаем в навигации табов .nav-link ошибки (Добавляем восклицательный знак)
 * Полезно когда надо подсветить таб, в котором есть ошибки в формах
 * @param containerSel - селектор, в котором находится навигация
 * @param message - сообщение об ошибке
 */
export const appendErrorIconForTabNavLinks = (
  container = null,
  message = gettext('Исправьте ошибки')
) => {
  container = container ? container : $('form');
  container.find('.nav-link').each(function (index, element) {
    const $element = $(element);
    const hrefTab = $element.attr('href');

    $element.find('[data-toggle="invalid-popover"]').remove();
    if ($(`${hrefTab} .invalid-feedback`).length) {
      $element.append(
        '' +
          ' <span class="input-icon-text pointer text-invalid pl-1"\n' +
          '       data-trigger="hover"\n' +
          '       data-toggle="invalid-popover"\n' +
          '       data-template=\'<div class="popover popover-invalid" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>\'\n' +
          '       data-placement="bottom"\n' +
          "       data-content='<div>" +
          message +
          "</div>'>\n" +
          '     <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-exclamation-triangle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">\n' +
          '       <path fill-rule="evenodd" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 5zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>\n' +
          '     </svg>\n' +
          ' </span>\n'
      );
    }
  });
  const invalidPopover = $('[data-toggle="invalid-popover"]');
  invalidPopover.popover({
    html: true,
    sanitize: false,
  });
};

export function get_select2_ajax_settings(url) {
  return {
    url: url,
    dataType: 'json',
    delay: 400,
    data: function (params) {
      return {
        search: params.term,
        page_size: 50,
      };
    },
    processResults: function (data, params) {
      data = data.results.map(function (item) {
        item.text = item.name || item.text;
        return item;
      });

      return {
        results: data,
      };
    },
  };
}

/**
 * Вытащил все функции определяющие отрисовку модалки, т.к. подцепляется по идее всегда в main.js,
 * но в некоторых местах как-то перезатирается и приходится переинициализировать
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initFuncInfoModal() {
  $.fn.displayInfoModal = function (options) {
    options = $.extend(
      {
        title: '',
        body: '',
        modalDialogClassName: '',
      },
      options || {}
    );
    var $modal = $('#modal-info');

    var elTitle = $modal.find('.modal-title')[0];
    var elBody = $modal.find('.modal-body')[0];
    var elModalDialog = $modal.find('.modal-dialog')[0];

    // Insert title or hide it
    if (elTitle != null) {
      if (!options.title) {
        $(elTitle).hide();
      } else {
        $(elTitle).show();
      }
      elTitle.innerHTML = options.title;
    }

    // Insert body
    if (elBody != null) {
      if (options.body instanceof Node) {
        elBody.innerHTML = '';
        elBody.appendChild(options.body);
      } else {
        elBody.innerHTML = options.body;
      }
    }

    if (elModalDialog != null) {
      elModalDialog.className += ' ' + options.modalDialogClassName;
    }
    $modal.modal('show');
  };

  $.fn.hideInfoModal = function () {
    var $modal = $('#modal-info');
    $modal.modal('hide');
  };

  $.fn.confirm = function (options) {
    options = $.extend(
      {
        title: '',
        message: '',
        yesbtn: '',
        nobtn: '',
      },
      options || {}
    );
    var $this = $(this);
    var $wnd = $('#confirm');

    $this.click(function () {
      if ($this.attr('disabled')) return false;

      const $yesBtn = $wnd.find('#yesbtn');
      const $noBtn = $wnd.find('#nobtn');
      const $question = $wnd.find('#modal-question');
      const $title = $wnd.find('#confirm-title');

      $title.text(options.title || $title.data('default-text'));
      $question.text(options.message || $question.data('default-text'));
      $yesBtn.text(options.yesbtn || $yesBtn.data('default-text'));
      $noBtn.text(options.nobtn || $noBtn.data('default-text'));

      $wnd.modal('show');

      switch (this.tagName.toUpperCase()) {
        case 'A':
          $yesBtn.off('click').on('click', function () {
            $('#confirm').modal('hide');
            window.location.href = $this.attr('href');
          });
          break;

        case 'BUTTON':
          $yesBtn.off('click').on('click', function () {
            $('#confirm').modal('hide');
            $this.before(
              '<input type="hidden" name="' +
                $this.attr('name') +
                '" value="' +
                $this.attr('value') +
                '"/>'
            );
            $this[0].form.submit();
          });
          break;
        case 'INPUT':
          $yesBtn.off('click').on('click', function () {
            $('#confirm').modal('hide');
            $this.before(
              '<input type="hidden" name="' +
                $this.attr('name') +
                '" value="' +
                $this.val() +
                '"/>'
            );
            this.form.submit();
          });
          break;
      }

      return false;
    });
  };

  $('.confirm').each(function (i, el) {
    var $this = $(el);
    $this.confirm({
      title: $this.data('title'),
      message: $this.data('message'),
      yesbtn: $this.data('yesbtn'),
      nobtn: $this.data('nobtn'),
    });
  });
}

export function simpleDialogShow(params = {}) {
  const { title, body, yesText, noText, onClose, manualClose } = Object.assign(
    {
      title: gettext('Требуется подтверждение'),
      body: '',
      yesText: gettext('Да'),
      yesAction: null,
      noText: gettext('Закрыть'),
      noAction: null,
      onClose: null,
      manualClose: null,
    },
    params
  );
  const $modal = $('#modal-info').clone();
  $modal.find('.modal-title').text(title);
  $modal.find('.modal-body').html(body);

  const onCloseModal = (answer) => (e) => {
    e && e.preventDefault();

    if (manualClose) {
      onClose && onClose(answer, $modal);
      return;
    }

    $modal.on('hidden.bs.modal', () => {
      $modal.detach();
      onClose && onClose(answer);
    });
    $modal.modal('hide');
  };

  const $cancelBtn = $modal.find('.modal-footer .btn');
  $cancelBtn.text(noText);
  $cancelBtn.click(onCloseModal(false));

  if (yesText) {
    let $okBtn = $(`<button class='btn btn-success'>${yesText}</button>`);
    $okBtn.click(onCloseModal(true));
    $cancelBtn.before($okBtn);
  }
  $modal.modal('show');
}

export function initDateRangePicker(container = null) {
  container = container ? container : $('body');
  let dateRangeInput = container.find('.daterange');
  let dateTimeInput = container.find('.datetimeinput');
  let dateInput = container.find('.dateinput');

  let daysOfWeek = [
    gettext('Вс'),
    gettext('Пн'),
    gettext('Вт'),
    gettext('Ср'),
    gettext('Чт'),
    gettext('Пт'),
    gettext('Сб'),
  ];

  let monthNames = [
    gettext('Январь'),
    gettext('Февраль'),
    gettext('Март'),
    gettext('Апрель'),
    gettext('Май'),
    gettext('Июнь'),
    gettext('Июль'),
    gettext('Август'),
    gettext('Сентябрь'),
    gettext('Октябрь'),
    gettext('Ноябрь'),
    gettext('Декабрь'),
  ];

  dateRangeInput.daterangepicker({
    timePicker24Hour: true,
    autoUpdateInput: false,
    locale: {
      cancelLabel: gettext('Отменить'),
      applyLabel: gettext('Применить'),
      daysOfWeek: daysOfWeek,
      monthNames: monthNames,
      firstDay: 1,
    },
  });

  dateRangeInput.on('apply.daterangepicker', function (ev, picker) {
    $(this).val(
      picker.startDate.format('DD.MM.YYYY') +
        ' - ' +
        picker.endDate.format('DD.MM.YYYY')
    );
    toggleFormFieldValid($(this));
  });

  dateRangeInput.on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
    toggleFormFieldValid($(this));
  });

  dateTimeInput.daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    showDropdowns: false,
    timePicker24Hour: true,
    timePicker: true,
    locale: {
      format: 'DD.MM.YYYY H:mm',
      cancelLabel: gettext('Отменить'),
      applyLabel: gettext('Применить'),
      daysOfWeek: daysOfWeek,
      monthNames: monthNames,
      firstDay: 1,
    },
  });

  dateTimeInput.on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('DD.MM.YYYY H:mm'));
    toggleFormFieldValid($(this));
  });

  dateTimeInput.on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
    toggleFormFieldValid($(this));
  });

  dateTimeInput.on('show.daterangepicker', function (ev, picker) {
    if ($(this).attr('readonly')) {
      picker.isShowing = true;
      picker.hide();
    }
  });

  dateInput.daterangepicker({
    autoApply: true,
    singleDatePicker: true,
    autoUpdateInput: false,
    showDropdowns: false,
    timePicker: false,
    locale: {
      format: 'DD.MM.YYYY',
      daysOfWeek: daysOfWeek,
      monthNames: monthNames,
      firstDay: 1,
    },
  });

  dateInput.on('apply.daterangepicker', function (ev, picker) {
    $(this).val(picker.startDate.format('DD.MM.YYYY'));
    toggleFormFieldValid($(this));
  });

  dateInput.on('cancel.daterangepicker', function (ev, picker) {
    $(this).val('');
    toggleFormFieldValid($(this));
  });

  const hideDataRangePcikers = function ($this) {
    const dp = $this.data('daterangepicker');
    if (dp.isShowing) {
      dp.hide();
    }
  };

  const onScroll = function () {
    if ($('.daterangepicker.show-calendar').length) {
      container.find('.daterange').each(function () {
        hideDataRangePcikers($(this));
      });
      container.find('.datetimeinput').each(function () {
        hideDataRangePcikers($(this));
      });
    }
  };
  $('.overflow-auto').off('scroll', onScroll);
  $('.overflow-auto').on('scroll', onScroll);
}

/**
 * Выравнивает высоту ячеек для скролящихся таблиц.
 * Пробегается по двум разным таблицам -> ищет макс высоту ячейки левой и правой строк таблиц -> назначает новую.
 *
 * Необходимо добавить следующие классы:
 * '.tables_auto_height' - класс для всей таблицы
 *
 * '.tables_auto_height__row_one_el' - селектор строки и одновременно селектор элемента
 * фиксированной колонки таблицы (строка с одним элементом)
 *
 * '.tables_auto_height__row_many_els' - селектор строки скролящейся таблицы
 * (строка с несколькими элементами/ячейками)
 *
 * '.tables_auto_height__row_many_el' - селектор элемента/ячейки строки скролящейся таблицы
 * @param $table
 */
export function recalculateColHeihghtForTable($table = null) {
  $table = $table ? $table : $('.tables_auto_height');

  $table.find('.tables_auto_height__row_one_el').each(function (index) {
    let $rowManyEls = $table.find(
      '.tables_auto_height__row_many_els:eq(' + index + ')'
    );

    $rowManyEls.outerHeight('');
    $rowManyEls.find('.tables_auto_height__row_many_el').outerHeight('');
    $(this).outerHeight('');

    let newHeight = $(this).outerHeight(true);
    let oldHeight = getMaxHeightForElements(
      $rowManyEls.find('.tables_auto_height__row_many_el')
    );
    let needChange = newHeight !== oldHeight;
    if (oldHeight > newHeight) {
      newHeight = oldHeight;
    }
    if (needChange) {
      $rowManyEls.outerHeight(newHeight);
      $rowManyEls
        .find('.tables_auto_height__row_many_el')
        .outerHeight(newHeight);
      $(this).outerHeight(newHeight);
    }
  });

  function getMaxHeightForElements(elements) {
    return Math.max.apply(
      Math.max,
      elements.toArray().map(function (el) {
        return el.offsetHeight;
      })
    );
  }
}

export function getPreloader(text = gettext('Загружается..')) {
  const preloader = $('.cssload-container').first().clone();
  preloader.find('.load-text').text(text);
  preloader.show();
  return preloader;
}

/**
 * Проверяет ИНН на корректность и по длине. Вощвращает { isValid: boolean, msg: string }
 * @param {string} inn Проверяемый ИНН
 * @param {string?} company_type Проверять длину для конкретного типа компании '0' - Юр. лицо, '1' - ИП
 */
export function validate_inn(inn, company_type = null) {
  const messages = {
    valid: gettext('Валидный ИНН'),
    inValid: gettext('Некорретный ИНН'),
    length12: gettext('ИНН должен содержать 12 символов'),
    length10: gettext('ИНН должен содержать 10 символов'),
    length: gettext('ИНН должен содержать 10 или 12 символов'),
    NaN: gettext('ИНН содержит недопустимые символы'),
  }

  if ( inn.match(/\D/) ) return { isValid: false, msg: messages.NaN};

  inn = inn.match(/(\d)/g);
  if (!inn) return { isValid: false, msg: messages.length};

  if (company_type === '0' && inn.length !== 10) return { isValid: false, msg: messages.length10};
  if (company_type === '1' && inn.length !== 12) return { isValid: false, msg: messages.length12};

  if ( inn.length === 10 ) {
    return inn[9] == String(((
      2*inn[0] + 4*inn[1] + 10*inn[2] +
      3*inn[3] + 5*inn[4] +  9*inn[5] +
      4*inn[6] + 6*inn[7] +  8*inn[8]
    ) % 11) % 10)
    ? { isValid: true, msg: messages.valid}
    : { isValid: false, msg: messages.inValid};

  } else if ( inn.length === 12 ) {
    return inn[10] == String(((
      7*inn[0] + 2*inn[1] + 4*inn[2] +
      10*inn[3] + 3*inn[4] + 5*inn[5] +
      9*inn[6] + 4*inn[7] + 6*inn[8] +
      8*inn[9]
    ) % 11) % 10) && inn[11] === String(((
      3*inn[0] +  7*inn[1] + 2*inn[2] +
      4*inn[3] + 10*inn[4] + 3*inn[5] +
      5*inn[6] +  9*inn[7] + 4*inn[8] +
      6*inn[9] +  8*inn[10]
    ) % 11) % 10)
    ? { isValid: true, msg: messages.valid}
    : { isValid: false, msg: messages.inValid};

  } else {
    return { isValid: false, msg: messages.length}
  }
}
