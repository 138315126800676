
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import CategoryRootItem from './category-root-item.component.vue';
import CategoryNodeExtend from './category-node-extend.component.vue';
import { CategoryNodeType } from '../../../../../types/app.types';
import { findCategory, useState } from '../../../../../common/utils';

export default defineComponent({
  name: 'CategoriesTreeExtend',
  components: {
    CategoryNodeExtend,
    CategoryRootItem,
  },
  methods: {
    onToggleRootCategory(category: CategoryNodeType, opened: boolean) {
      if (opened) {
        this.setActiveRoot(category);
      } else {
        this.setActiveRoot(null);
      }
    },
  },
  watch: {
    selectedCategory(mutation: Nullable<number>) {
      if (!mutation && this.activeRoot) {
        this.setActiveRoot(null);
      }
    },
    categories(mutations: CategoryNodeType[]) {
      if (mutations?.length && this.selectedCategory && !this.activeRoot) {
        const result = findCategory(this.categories, this.selectedCategory);
        if (result.root) {
          this.setActiveRoot(result.root);
        }
      }
    },
  },
  setup() {
    const [activeRoot, setActiveRoot] =
      useState<Nullable<CategoryNodeType>>(null);
    return {
      activeRoot,
      setActiveRoot,
    };
  },
  computed: {
    ...mapGetters('catalog', [
      'categories',
      'selectedCategory',
      'isLoadingCategories',
    ]),
  },
});
