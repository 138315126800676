
import { defineComponent, PropType } from 'vue';
import { useState } from '../../../common/utils';

export default defineComponent({
  name: 'PanelCollapsed',
  props: {
    title: String as PropType<string>,
    state: String as PropType<'open' | 'close'>,
  },
  methods: {
    togglePanel(e: Event) {
      e.preventDefault();
      this.setPanelIsOpen(!this.panelIsOpen);
    },
  },
  created() {
    this.setPanelIsOpen(this.state === 'open');
  },
  setup() {
    const [panelIsOpen, setPanelIsOpen] = useState<boolean>(false);
    return {
      panelIsOpen,
      setPanelIsOpen,
    };
  },
});
