
import { defineComponent, PropType } from 'vue';

import {
  ProductType,
  ModificationType,
} from '@spa/api/schema/catalog';

import ModificationsTable from '@catalog/components/product-detail/product-detail-modifications/modifications-table.component.vue';
import ModificationsGrid from '@catalog/components/product-detail/product-detail-modifications/modifications-grid.component.vue';

export default defineComponent({
  name: 'ProductDetailModifications',

  components: {
    ModificationsTable,
    ModificationsGrid,
  },

  props: {
    displayType: {
      type: String as PropType<'table' | 'grid'>,
      default: 'table',
    },

    detailProduct: {
      type: Object as PropType<ProductType> | null,
      default: null,
      required: false,
    },
  },

  computed: {
    modifications(): ModificationType[] {
      if (this.detailProduct.modifications_data.length !== 0) {
        return this.detailProduct.modifications_data;
      }
      return this.detailProduct.modifications_data_with_base;
    },

    isTable(): boolean {
      return this.displayType === 'table';
    },
    isGrid(): boolean {
      return this.displayType === 'grid';
    },
  }
});
