import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5f98e721"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-item" }
const _hoisted_2 = {
  key: 0,
  class: "product-item__controls"
}
const _hoisted_3 = { class: "checkbox-wrapper" }
const _hoisted_4 = ["id", "checked"]
const _hoisted_5 = { class: "product-item__image" }
const _hoisted_6 = { class: "product-item__image__controls" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "product-item__main" }
const _hoisted_9 = { class: "product-item__main__stickers" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "badge badge-sticker" }
const _hoisted_12 = { class: "product-item__main__title" }
const _hoisted_13 = ["href", "title"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 2,
  class: "badge badge-warning"
}
const _hoisted_16 = {
  key: 0,
  class: "product-item__main__progress"
}
const _hoisted_17 = { class: "product-item__main__info" }
const _hoisted_18 = {
  key: 0,
  class: "product-item__main__info__line"
}
const _hoisted_19 = { class: "product-item__main__info__name" }
const _hoisted_20 = { class: "product-item__main__info__value" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 1,
  class: "product-item__main__info__line"
}
const _hoisted_23 = { class: "product-item__main__info__name" }
const _hoisted_24 = { class: "product-item__main__info__value" }
const _hoisted_25 = ["href"]
const _hoisted_26 = ["href"]
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 2,
  class: "product-item__main__info__line"
}
const _hoisted_29 = { class: "product-item__main__info__name" }
const _hoisted_30 = { class: "product-item__main__info__value" }
const _hoisted_31 = {
  key: 3,
  class: "product-item__main__info__line"
}
const _hoisted_32 = { class: "product-item__main__info__name" }
const _hoisted_33 = { class: "product-item__main__info__value" }
const _hoisted_34 = {
  key: 4,
  class: "product-item__main__info__line"
}
const _hoisted_35 = { class: "product-item__main__info__name" }
const _hoisted_36 = { class: "product-item__main__info__value" }
const _hoisted_37 = {
  key: 5,
  class: "product-item__main__info__line"
}
const _hoisted_38 = { class: "product-item__main__info__name" }
const _hoisted_39 = { class: "product-item__main__info__value" }
const _hoisted_40 = {
  key: 6,
  class: "product-item__main__info__line"
}
const _hoisted_41 = { class: "product-item__main__info__name" }
const _hoisted_42 = { class: "product-item__main__info__value" }
const _hoisted_43 = ["title"]
const _hoisted_44 = { key: 2 }
const _hoisted_45 = ["href"]
const _hoisted_46 = {
  key: 3,
  class: "product-item__main__fast-view"
}
const _hoisted_47 = { class: "product-item__side" }
const _hoisted_48 = {
  key: 0,
  class: "product-item__side__price"
}
const _hoisted_49 = {
  key: 0,
  class: "price-caption"
}
const _hoisted_50 = { class: "d-flex align-items-start" }
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { class: "text-gray" }
const _hoisted_53 = { class: "product-item__list__control" }
const _hoisted_54 = { class: "product-item__list__control__left" }
const _hoisted_55 = { class: "product-item__list__control__right mt-xs-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_button = _resolveComponent("edit-button")!
  const _component_favorite_button = _resolveComponent("favorite-button")!
  const _component_add_to_compare_button = _resolveComponent("add-to-compare-button")!
  const _component_default_image = _resolveComponent("default-image")!
  const _component_percent_of_fullness = _resolveComponent("percent-of-fullness")!
  const _component_rest_indicator = _resolveComponent("rest-indicator")!
  const _component_property_values = _resolveComponent("property-values")!
  const _component_product_files = _resolveComponent("product-files")!
  const _component_fast_view_button = _resolveComponent("fast-view-button")!
  const _component_price_with_coupon = _resolveComponent("price-with-coupon")!
  const _component_warehouses_select = _resolveComponent("warehouses-select")!
  const _component_add_to_cart_button = _resolveComponent("add-to-cart-button")!
  const _component_add_to_waiting_list_button = _resolveComponent("add-to-waiting-list-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("input", {
              type: "checkbox",
              id: 'select-' + _ctx.product.id,
              checked: _ctx.isSelected,
              onChange: _cache[0] || (_cache[0] = (e) => _ctx.onSelectProduct(e, _ctx.product.id))
            }, null, 40, _hoisted_4)
          ]),
          (_ctx.allowEditProduct)
            ? (_openBlock(), _createBlock(_component_edit_button, {
                key: 0,
                "product-id": _ctx.product.id
              }, null, 8, ["product-id"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.hasShowFavoriteBtn)
          ? (_openBlock(), _createBlock(_component_favorite_button, {
              key: 0,
              product: _ctx.product
            }, null, 8, ["product"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_add_to_compare_button, { product: _ctx.product }, null, 8, ["product"]), [
          [_vShow, _ctx.hasShowCompareBtn]
        ])
      ]),
      (_ctx.product.real_products_count > 0)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.product.detail_url
          }, [
            _createVNode(_component_default_image, {
              src: _ctx.EXTERNAL_HOST + _ctx.product.image?.small,
              "default-src": _ctx.plugImage,
              alt: _ctx.product.name
            }, null, 8, ["src", "default-src", "alt"])
          ], 8, _hoisted_7))
        : (_openBlock(), _createBlock(_component_default_image, {
            key: 1,
            src: _ctx.EXTERNAL_HOST + _ctx.product.image?.small,
            "default-src": _ctx.plugImage,
            alt: _ctx.product.name
          }, null, 8, ["src", "default-src", "alt"]))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.stickers, (sticker) => {
          return (_openBlock(), _createElementBlock("a", {
            class: "product-item__sticker",
            href: "#",
            key: sticker.code,
            onClick: (e) => _ctx.onSelectSticker(e, sticker.code)
          }, [
            _createElementVNode("span", _hoisted_11, _toDisplayString(sticker.name), 1)
          ], 8, _hoisted_10))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_12, [
        (_ctx.product.real_products_count > 0)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.product.detail_url,
              title: _ctx.product.name
            }, _toDisplayString(_ctx.product.name), 9, _hoisted_13))
          : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.product.name), 1)),
        (_ctx.isServiceProductType)
          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.gettext('Услуга')), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.showModerationStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(_component_percent_of_fullness, {
              percent: _ctx.product.percentage_occupancy
            }, null, 8, ["percent"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_17, [
        (_ctx.product.code || _ctx.isServiceProductType)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.gettext('Артикул')), 1),
              _createElementVNode("div", _hoisted_20, [
                (_ctx.product.code)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.product.code), 1))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("span", { class: "badge badge-warning" }, _toDisplayString(_ctx.gettext('Услуга')), 513), [
                  [_vShow, _ctx.isServiceProductType]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasShowProductSupplier)
          ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.gettext('Поставщик')), 1),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("a", {
                  href: '/suppliers/supplier/' + _ctx.product.supplier_id
                }, _toDisplayString(_ctx.product.supplier), 9, _hoisted_25),
                _createTextVNode("   "),
                _createElementVNode("a", {
                  href: '?supplier_id=' + _ctx.product.supplier_id,
                  onClick: _cache[1] || (_cache[1] = (e) => _ctx.navigateToSupplierProducts(e, _ctx.product.supplier_id))
                }, [
                  (
                  _ctx.productsCountBySupplier[_ctx.product.supplier_id] &&
                  !_ctx.selectedSupplier
                )
                    ? (_openBlock(), _createElementBlock("span", _hoisted_27, "(" + _toDisplayString(_ctx.gettext('ещё')) + " " + _toDisplayString(_ctx.productsCountBySupplier[_ctx.product.supplier_id] - 1) + " " + _toDisplayString(_ctx.productCountDecline(
                    _ctx.productsCountBySupplier[_ctx.product.supplier_id] - 1
                  )) + ") ", 1))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_26)
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showRestIndicator && _ctx.modulesEnabled.rest)
          ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.gettext('Количество')), 1),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_rest_indicator, {
                  "stock-count": _ctx.stockCount,
                  "measure-unit-name": _ctx.product.common_measure_unit_short_name,
                  "supplier-id": _ctx.product.supplier_id
                }, null, 8, ["stock-count", "measure-unit-name", "supplier-id"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSupplierCatalog)
          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.gettext('Ваша ЕИ')), 1),
              _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.product.measure_unit_name), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isSupplierCatalog)
          ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.gettext('ЕИ на сайте')), 1),
              _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.product.common_measure_unit_seller_name), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.gettext('Единица измерения')), 1),
              _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.product.common_measure_unit_buyer_name), 1)
            ])),
        (_ctx.showModerationStatus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
              _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.gettext('Статус')), 1),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("span", {
                  class: "badge",
                  style: _normalizeStyle({ backgroundColor: _ctx.product.moderation_status.color })
                }, _toDisplayString(_ctx.product.moderation_status.name), 5)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.hasShowDescription && !_ctx.containsHTML(_ctx.product.description))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "product-item__main__description",
            title: _ctx.stripHTMLSpecialChars(_ctx.product.description)
          }, [
            _createElementVNode("p", null, _toDisplayString(_ctx.truncateString(_ctx.product.description, 500)), 1)
          ], 8, _hoisted_43))
        : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_component_property_values, { product: _ctx.product }, null, 8, ["product"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_product_files, { product: _ctx.product }, null, 8, ["product"])
      ]),
      (_ctx.hasNewModerationMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
            _createElementVNode("a", {
              href: _ctx.product.detail_url + '?scroll-to-comments=True'
            }, _toDisplayString(_ctx.gettext('Новые сообщения!')), 9, _hoisted_45)
          ]))
        : _createCommentVNode("", true),
      (_ctx.product.real_products_count > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
            _createVNode(_component_fast_view_button, { product: _ctx.product }, null, 8, ["product"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_47, [
      (_ctx.modulesEnabled.price)
        ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
            (_ctx.showPriceRange)
              ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.gettext('Цена')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_50, [
              (_ctx.showPriceRange)
                ? (_openBlock(), _createBlock(_component_price_with_coupon, {
                    key: 0,
                    class: "price-large",
                    product: _ctx.product,
                    "has-price-offer": _ctx.hasPriceOffer
                  }, null, 8, ["product", "has-price-offer"]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.hasRrcPrice)
              ? (_openBlock(), _createElementBlock("p", _hoisted_51, [
                  _createElementVNode("small", _hoisted_52, [
                    _createTextVNode(_toDisplayString(_ctx.gettext('РРЦ')) + ": " + _toDisplayString(_ctx.transformMoney(_ctx.product.rrc_price)) + " ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.product.currency.toString().toLocaleLowerCase())
                    }, null, 2)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_53, [
        _createElementVNode("div", _hoisted_54, [
          (
              _ctx.allowSelectWarehouse &&
              !_ctx.product.has_modifications &&
              _ctx.modulesEnabled.rest
            )
            ? (_openBlock(), _createBlock(_component_warehouses_select, {
                key: 0,
                modification_id: _ctx.product.base_modification_id,
                warehouses: _ctx.product.warehouses,
                selected: _ctx.product.default_warehouse,
                measure_unit_name: _ctx.product.measure_unit_name
              }, null, 8, ["modification_id", "warehouses", "selected", "measure_unit_name"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_55, [
          (_ctx.modulesEnabled.buy)
            ? _withDirectives((_openBlock(), _createBlock(_component_add_to_cart_button, {
                key: 0,
                product: _ctx.product,
                "stock-count": _ctx.stockCount
              }, null, 8, ["product", "stock-count"])), [
                [_vShow, _ctx.canAddToCart]
              ])
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _withDirectives(_createVNode(_component_add_to_waiting_list_button, { product: _ctx.product }, null, 8, ["product"]), [
              [_vShow, 
                _ctx.catalogConfig.can_use_cart &&
                _ctx.catalogConfig.waiting_list_enabled &&
                !_ctx.isReferenceMode
              ]
            ])
          ])
        ])
      ])
    ])
  ]))
}