import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_node_extend = _resolveComponent("category-node-extend")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([{ active: _ctx.isActive }, "category-node-extend sidebar-item"])
    }, [
      _createElementVNode("a", {
        style: _normalizeStyle({ paddingLeft: _ctx.offset * 10 + 20 + 'px' }),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpened && _ctx.toggleOpened(...args))),
        href: "#",
        class: "category-node-extend__link sidebar-item__link"
      }, _toDisplayString(_ctx.getCategoryText(_ctx.categoryNode)), 5)
    ], 2),
    (_ctx.categoryNode.children?.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["category-node-extend__children", { opened: _ctx.opened }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryNode.children, (category) => {
            return (_openBlock(), _createBlock(_component_category_node_extend, {
              key: category.id,
              offset: _ctx.nextOffset,
              "on-toggle-open": _ctx.onToggleOpenChild,
              "category-node": category,
              ref_for: true,
              ref: _ctx.onRefChild
            }, null, 8, ["offset", "on-toggle-open", "category-node"]))
          }), 128))
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}