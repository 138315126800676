
import { defineComponent, PropType, ref } from 'vue';
import { ThumbnailType } from 'src/entries/spa/api/schema/common';

import "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

import Drift from 'drift-zoom';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Swiper as SwiperClass, ThumbsOptions, PaginationOptions } from 'swiper/types';
import { Thumbs, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/thumbs';
import "swiper/css/pagination";
import { API_V1_HOST_OUTER } from '@spa/api/urls';
import DefaultImage from '@components/default-image.vue';


export default defineComponent({
  name: 'ProductDetailSwiper',

  components: {
    DefaultImage,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      zooms: [],
      breakpoint: 992,
    }
  },

  props: {
    images: {
      type: Array as PropType<ThumbnailType[]>,
      default: () => [],
    },
  },

  computed: {
    isImagesEmpty(): boolean {
      return this.images.length === 0;
    },

    getThumbsImages(): string[] {
      return this.getImagesInner('small');
    },
    getImages(): string[] {
      return this.getImagesInner('zoom_large');
    },
    pagination(): PaginationOptions {
      return { enabled: true, clickable: true }
    },
    thumbs(): ThumbsOptions {
      return { swiper: this.thumbsSwiper, multipleActiveThumbs: false }
    },
  },

  setup() {
    const EXTERNAL_HOST = API_V1_HOST_OUTER ? API_V1_HOST_OUTER : '';
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper: SwiperClass) => {
      thumbsSwiper.value = swiper;
    };

    return {
      swiperModules: [Thumbs, Pagination],
      swiperThumbsModules: [Thumbs],
      thumbsSwiper,
      setThumbsSwiper,
      EXTERNAL_HOST,
    };
  },

  methods: {
    getImagesInner(imageType: string): string[] {
      const noPhotoImage = '/static/src/images/nophoto.png';
      if (this.isImagesEmpty) return [noPhotoImage];

      return this.images.map((el: ThumbnailType) => el[imageType]);
    },

    initZoom() {
      if (this.isImagesEmpty) return;

      const swipperContainer = document.getElementById('product-detail-swiper');
      if (!swipperContainer) return;

      const images = swipperContainer.querySelectorAll('.zoom-wrapper img');
      const zoomContainer = swipperContainer.querySelector('.zoom');

      for (const image of [].slice.call(images)) {
        const drift = new Drift(image, {
          paneContainer: zoomContainer,
          inlinePane: false,
          zoomFactor: 2,
          hoverBoundingBox: true,
          showWhitespaceAtEdges: true,
        });
        this.zooms.push(drift);
      }
    },
    destroyZoom() {
      while (this.zooms.length) this.zooms.pop().destroy();
    },

    handlePageSizeChange() {
      if (window.innerWidth < this.breakpoint && !!this.zooms.length) {
        this.destroyZoom();
      } else if (window.innerWidth >= this.breakpoint && !this.zooms.length) {
        this.initZoom();
      }
    },
    onThumbClick(swiper: SwiperClass) {
      swiper.slideTo(swiper.clickedIndex);
    },
  },

  mounted() {
    this.handlePageSizeChange();
  },

  created() {
    window.addEventListener("resize", this.handlePageSizeChange);
  },

  unmounted() {
    window.removeEventListener("resize", this.handlePageSizeChange);
  },
});
