
import { defineComponent, inject } from 'vue';
import AddToCartButton from '../add-to-cart-button.component.vue';
import FavoriteButton from '../favorite-button.component.vue';
import AddToCompareButton from '../add-to-compare-button.component.vue';
import WarehousesSelect from '../warehouses-select.component.vue';
import AddToWaitingListButton from '../add-to-waiting-list-button.component.vue';
import InFavoriteButton from '../in-favorite-button.component.vue';
import InComparisonButton from '../in-comparison-button.component.vue';
import RestIndicator from '../rest-indicator.component.vue';
import PercentOfFullness from '@catalog/components/percent-of-fullness.component.vue';
import ProductFiles from '../product-files.component.vue';
import HeadlessTrow from './headless-trow.component.vue';
import EditButton from '../edit-button.component.vue';
import Dropdown from '@components/dropdown.component.vue';
import { ProductItemMixin } from '../../mixins/product-item.mixin';
import { containsHTML } from '@/entries/spa/common/utils';
import { catalogInjectKey } from '../../composables/table';
import FastViewButton from './../fast-view-button.component.vue';
import { useCatalogModals } from '../../composables/modals';
import { useShowActionButtonsAndCheckBox } from '../../composables';
import { API_V1_HOST_OUTER } from '@spa/api/urls';

import PriceWithCoupon from '../price-with-coupon.vue';
import { useUser } from '@composables/store';
import DefaultImage from '@components/default-image.vue';

export default defineComponent({
  name: 'ProductItemTable',
  components: {
    DefaultImage,
    ProductFiles,
    RestIndicator,
    AddToCartButton,
    InComparisonButton,
    InFavoriteButton,
    AddToWaitingListButton,
    WarehousesSelect,
    EditButton,
    HeadlessTrow,
    PercentOfFullness,
    Dropdown,
    FastViewButton,
    PriceWithCoupon,
    AddToCompareButton,
    FavoriteButton,
  },
  mixins: [ProductItemMixin],
  setup() {
    const { columns } = inject(catalogInjectKey);
    const { showProductDetailModal } = useCatalogModals();
    const showActions = useShowActionButtonsAndCheckBox();
    const EXTERNAL_HOST = API_V1_HOST_OUTER ? API_V1_HOST_OUTER : '';
    const isSupplierUser = useUser().value.is_supplier;
    const useNSI = window.user.contractor.useNSI;

    return {
      containsHTML,
      columns,
      showProductDetailModal,
      showActions,
      EXTERNAL_HOST,
      isSupplierUser,
      useNSI,
    };
  },
  computed: {
    propertyValues() {
      const propertyMap = new Map();
      if (!this.product.property_values) return propertyMap;

      for (const prop of this.product.property_values) {
        propertyMap.set(prop.property_id.toString(), prop.value);
      }
      return propertyMap;
    },

    showWarehouseSelect(): boolean {
      return this.allowSelectWarehouse && !this.product.has_modifications;
    },
    hasActions(): boolean {
      return (
        this.allowEditProduct ||
        this.hasShowFavoriteBtn ||
        this.hasShowCompareBtn
      );
    },
  },
});
