import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "catalog-grid-wrapper" }
const _hoisted_2 = { class: "catalog-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_item_grid = _resolveComponent("product-item-grid")!
  const _component_catalog_footer = _resolveComponent("catalog-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
          return (_openBlock(), _createBlock(_component_product_item_grid, {
            key: product.id,
            product: product
          }, null, 8, ["product"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_catalog_footer, { pagination: _ctx.pagination }, null, 8, ["pagination"])
  ], 64))
}