import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5aeef996"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "product-detail-modifications"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modifications_table = _resolveComponent("modifications-table")!
  const _component_modifications_grid = _resolveComponent("modifications-grid")!

  return (_ctx.modifications.length !== 0 && _ctx.detailProduct.has_modifications)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.gettext('Модификации')), 1),
        (_ctx.isTable)
          ? (_openBlock(), _createBlock(_component_modifications_table, {
              key: 0,
              "detail-product": _ctx.detailProduct
            }, null, 8, ["detail-product"]))
          : (_ctx.isGrid)
            ? (_openBlock(), _createBlock(_component_modifications_grid, {
                key: 1,
                "detail-product": _ctx.detailProduct
              }, null, 8, ["detail-product"]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}