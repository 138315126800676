import { readonly, Ref, ref } from 'vue';
import { CategoryNodeType } from '../types/app.types';
import _ from 'lodash';

export function arrayNumbers(length: number) {
  return Array.from(new Array(length)).map((_, i) => i);
}

export function getCookies() {
  const parts = document.cookie.split(';');
  const cookies: { [key: string]: string } = {};
  for (const item of parts) {
    const [key, value] = item.split('=');
    if (key && value) {
      cookies[key.trim()] = value.trim();
    }
  }
  return cookies;
}

export function getOuterUserToken() {
  return getCookies()['outer_user_token'] || '';
}

export function getAuthorizationHeader() {
  const token = getOuterUserToken();
  if (token) {
    return { Authorization: `Token ${token}` };
  }
  return {};
}

export function useState<T = any>(
  initialState: T
): [Ref<T>, (state: T) => void] {
  const state = ref<T>(initialState);
  const setState = (newState: T) => {
    // @ts-expect-error
    state.value = newState;
  };
  // @ts-expect-error
  return [readonly(state), setState];
}

export function truncateString(text?: string, limit = 50, end = '…') {
  if (!text?.trim()) {
    return '';
  }
  let _text: string = text.trim();
  if (_text.length > limit) {
    const _text_list = _text.split(/\s/).map((x) => x.trim());
    let counter = 0;
    const words = [];
    for (const word of _text_list) {
      counter += word.length;
      words.push(word);
      if (counter > limit) {
        break;
      }
    }
    if (words.length > 1) {
      _text = words.slice(0, -1).join(' ') + end;
    } else {
      _text = words.join(' ').substring(0, limit) + end;
    }
  }
  return _text;
}

export function stripHTMLSpecialChars(text: string) {
  const cleanedText = text?.replace(/<\/?[^>]+(>|$)/g, '');
  return cleanedText.replace(/&[a-zA-Z0-9#]+;/g, '');
}

export function parseQuerySting(): FormData {
  const fd = new FormData();
  window.location.search
    .slice(1)
    .split('&')
    .filter(Boolean)
    .map((x) => x.split('='))
    .forEach(([k, v]) => {
      fd.append(k, v);
    });
  return fd;
}

export function parseQueryStingDecoded(): FormData {
  const fd = new FormData();
  decodeURIComponent(window.location.search)
    .slice(1)
    .split('&')
    .filter(Boolean)
    .map((x) => x.split('='))
    .forEach(([k, v]) => {
      fd.append(k, v);
    });
  return fd;
}

export function getQueryStringFromFormData(fd: FormData) {
  return [...fd.entries()]
    .filter(([, v]) => !!v)
    .reduce((acc, [k, v]) => acc + `${k}=${decodeURI(v.toString())}&`, '')
    .slice(0, -1);
}

export function getQueryObjectFromFormData(fd: FormData): {
  [key: string]: FormDataEntryValue[];
} {
  const query: { [key: string]: FormDataEntryValue[] } = {};
  for (const key of fd.keys()) {
    query[key] = fd.getAll(key);
  }
  return query;
}

export function changeLocationQuery(newFormData?: FormData) {
  const fd = newFormData || parseQuerySting();
  let url = window.location.pathname;
  if ([...fd.keys()].length) {
    url = window.location.pathname + '?' + getQueryStringFromFormData(fd);
  }
  window.history.replaceState({}, '', url);
}

export function findCategory(
  categories: CategoryNodeType[],
  desiredCategoryID: number | string
) {
  const path: CategoryNodeType[] = [];
  const _find = (category: CategoryNodeType): CategoryNodeType | undefined => {
    if (category.id.toString() === desiredCategoryID.toString()) {
      return category;
    }
    for (const child of category.children || []) {
      const found = _find(child);
      if (found) {
        path.unshift(category);
        return found;
      }
    }
  };
  for (const category of categories) {
    const found = _find(category);
    if (found) {
      return {
        root: category,
        found,
        isRoot: found.id === category.id,
        path,
      };
    }
  }
  return {};
}

export function num2word(
  value: Nullable<number>,
  words: FixedLengthArray<string, 3>
) {
  if (!value) {
    return words[2];
  }
  value = Math.abs(value) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}

export function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function numberWithSpaces(value: number | string) {
  return value
    .toString()
    .replace(/[^-\d]+/g, '')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function transformMoney(value: number | string) {
  if (value === undefined) {
    return '';
  }
  let [decimal, tail] = value.toString().split(/[,.]/);
  decimal = decimal.replace(/[^-\d]+/, '');
  if (tail !== undefined) {
    tail = tail.replace(/[^\d]{2}/, '').slice(0, 2);
    return numberWithSpaces(decimal) + ',' + tail;
  }
  return numberWithSpaces(value);
}

export function strip(str: string, remove: string | any[]) {
  while (str.length > 0 && remove.indexOf(str.charAt(0)) != -1) {
    str = str.substr(1);
  }
  while (str.length > 0 && remove.indexOf(str.charAt(str.length - 1)) != -1) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}

export function getUrlWithLang(
  ...args: (string | number | null | undefined)[]
) {
  return (
    `${global.BASE_URL}` +
    args
      .filter((x) => !!x)
      .map((x) => strip(x.toString().trim(), '/'))
      .join('/') +
    '/'
  );
}

export function toSnakeCase(text: string, sep = '_') {
  return (
    text[0].toLowerCase() +
    text.slice(1).replace(/[A-Z]/g, (letter) => sep + letter.toLowerCase())
  );
}

export function isEmpty(value: any) {
  return (
    [undefined, null, Infinity, -Infinity, ''].indexOf(value) > -1 ||
    Number.isNaN(value) ||
    (Array.isArray(value) && !value.length) ||
    (typeof value === 'object' && !Object.keys(value).length)
  );
}

export function hasObjectsDifference(obj1: KeyVal, obj2: KeyVal) {
  if (Object.keys(obj1).length < Object.keys(obj2).length) {
    [obj1, obj2] = [obj2, obj1];
  }
  for (const [key, val] of Object.entries(obj1)) {
    const val2 = obj2[key];
    if (!(isEmpty(val2) && isEmpty(val)) && val2 != val) {
      return true;
    }
  }
}

/**
 * Проверяет строки на наличие HTML-тэгов
 * @param str Строка для проверки
 */
export const containsHTML = (str: string) => /<[a-z][\s\S]*>/i.test(str);
