import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-64738aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel panel-gray" }
const _hoisted_2 = { class: "options" }
const _hoisted_3 = {
  class: "panel-collapse",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "header-panel panel-heading",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePanel && _ctx.togglePanel(...args)))
    }, [
      _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("i", {
            class: _normalizeClass(["fa", {
              'fa-chevron-up': _ctx.panelIsOpen,
              'fa-chevron-down': !_ctx.panelIsOpen,
            }])
          }, null, 2)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["panel-body panel-collapsed", { open: _ctx.panelIsOpen }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}