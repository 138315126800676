
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';
import { DEFAULT_PAGINATE_SIZE } from '@catalog/common/config';
import {
  CHANGE_PAGE_SIZE,
} from '@catalog/store/actions.type';

export default defineComponent({
  name: 'SelectPerPage',
  props: {
    isShowText: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  computed: {
    ...mapGetters('catalog', [
      'pageSize',
    ]),
    paginateSizes() {
      return (
        this.catalogConfig?.endless_pagination_per_page || DEFAULT_PAGINATE_SIZE
      );
    },
  },

  methods: {
    setPageSize(event: Event) {
      const pageSize = parseInt((event.target as HTMLInputElement).value);
      this.$store.dispatch('catalog/' + CHANGE_PAGE_SIZE, pageSize);
      this.scrollTop();
    },
  },
});
