import { clamp, merge } from 'lodash';
import { computed, ComputedRef, Ref, unref } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import {
  CatalogPaginationType,
  ProductType,
  Weights,
  ProductEditFormType
} from '@spa/api/schema/catalog';
import { computedProductPercentageOccupancy, computedProductEditFormPercentageOccupancy } from '../utils';
import {useCatalogConfig, useUser} from '@/entries/spa/composables/store';
import { MaybeRef } from '@vueuse/shared';
import { parseQueryStingDecoded } from '@spa/common/utils';

/**
 * Возвращает функции для работы с пагинацией через vue-router
 * @param pagination Объект пагинации, возвращаемый из API
 * @param route Объект смерджится с роутем
 */
export function useCatalogRoutes(
  pagination: Ref<CatalogPaginationType>,
  route: Ref<RouteLocationRaw>
) {
  const router = useRouter();
  const currentRoute = useRoute();

  /**
   * Возвращает объект из url query параметров, без параметра page
   */
  function getUrlQueryObject()  {
    const fd = parseQueryStingDecoded();
    const queryObj = Object.fromEntries(fd.entries());
    delete queryObj["page"];
    return queryObj
  }

  /**
   * Перейти к первой странице
   */
  function goToFirstPage(): void {
    router.push({
      name: currentRoute.name,
      query: {
        ...getUrlQueryObject(),
        page: 1
      }
    });
  }

  /**
   * Перейти к предыдущей странице
   */
  function goToPrevPage(): void {
    router.push({
      name: currentRoute.name,
      query: {
        ...getUrlQueryObject(),
        page: pagination.value.count_pages - 1
      }
    });
  }

  /**
   * Переёти к следующей странице
   */
  function goToNextPage(): void {
    router.push({
      name: currentRoute.name,
      query: {
        ...getUrlQueryObject(),
        page: pagination.value.current_page + 1
      }
    });
  }

  /**
   * Перейти к последней странице
   */
  function goToLastPage(): void {
    router.push({
      name: currentRoute.name,
      query: {
        ...getUrlQueryObject(),
        page: pagination.value.count_pages
      }
    });
  }

  /**
   * Перейти на страницу
   * @param page Номер страницы
   */
  function goToPage(page: number) {
    router.push({
      name: currentRoute.name,
      query: {
        ...getUrlQueryObject(),
        page: clamp(page, 1, pagination.value.count_pages)
      }
    })
  }

  return {
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    goToPage,
  };
}

/**
 * Возвращает commputed'ы для проверки на какой странице находится юзер.
 */
export function useCheckCatalogRoute() {
  const route = useRoute();

  const isCatalog = computed(() => {
    return route.meta?.section === 'catalog';
  });

  const isSupplierCatalog = computed(() => {
    return (
      ['SupplierCatalog', 'SupplierCatalogCategory', 'SupplierCatalogSearch', 'SupplierCatalogWithSupplierCategory'].indexOf(
        route.name as string
      ) > -1
    );
  });

  return { isCatalog, isSupplierCatalog };
}

export function useProductPercentageOccupancy(
  product: MaybeRef<Partial<ProductType>>,
  product_weights: Ref<Weights>
): ComputedRef<number> {
  return computed(() => {

    return computedProductPercentageOccupancy(
      unref(product),
      product_weights.value
    );
  });
}

export function useProductFormInstancePercentageOccupancy(
  product: MaybeRef<ProductEditFormType>,
  product_weights: Ref<Weights>
): ComputedRef<number> {
  return computed(() => {

    return computedProductEditFormPercentageOccupancy(
      unref(product),
      product_weights.value
    );
  });
}

/**
 * Метод отвечает за отображение кнопки действий для выбранных элементов
 * и чек боксов на которые распространяются действия для каталога
 */
export function useShowActionButtonsAndCheckBox(): ComputedRef<boolean> {
  const user = useUser();
  return computed(() => {
    return !user.value.is_supplier;
  });
}
