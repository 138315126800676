
import { defineComponent } from 'vue';
import PanelCollapsed from './panel-collapsed.component.vue';
import { mapGetters } from 'vuex';
import { PANEL_STATUSES } from '../common/config';

export default defineComponent<any>({
  name: 'CatalogExtraData',
  components: {
    PanelCollapsed,
  },
  computed: {
    ...mapGetters('catalog', ['extraData']),
    hasOpen() {
      return this.catalogConfig.extra_panel_status === PANEL_STATUSES.opened;
    },
  },
});
