
import { defineComponent, inject, PropType } from 'vue';

import { mapGetters } from 'vuex';
import {
  SORT_KEYS,
  SORT_TYPE,
  CATALOG_SHOW_TYPE_NAMES,
  CATALOG_SHOW_TYPES,
} from '../common/config';
import {
  ADD_PRODUCTS_TO_COMPARE,
  CHANGE_CATALOG_SHOW_TYPE,
  SELECT_ALL_PRODUCT,
  TOGGLE_SORT_BY,
  CHANGE_PRODUCT_MODERATION_STATUS,
  CHANGE_MODERATOR_SUPPLIER,
  CHANGE_IN_STOCK_FOR_WAREHOUSES,
} from '../store/actions.type';
import Alert from '../../../components/alert.component.vue';
import { CatalogViewModeType } from '../../../api/schema/catalog';
import { useState } from '../../../common/utils';
import { AlertData } from '../../../types/app.types';
import Dropdown from '@components/dropdown.component.vue';
import DropdownAction from '@components/dropdown-action.component.vue';
import ColumnsSettings from './columns-settings.component.vue';
import { catalogInjectKey } from '../composables/table';
import { isEmpty } from 'lodash';
import { useBreakpoints } from '@/entries/spa/composables';
import {
  useShowActionButtonsAndCheckBox,
  useCheckCatalogRoute,
} from '../composables';
import { SortingDirections } from '@/entries/spa/api/schema/catalog';
import { useCatalogModals } from '../composables/modals';
import { useCatalogConfig } from '@/entries/spa/composables/store';
import { CatalogService } from '@catalog/api/api.catalog.service';
import categoryItemComponent from "@catalog/components/categories/category-item.component.vue";

interface ViewModeMap {
  mode: CatalogViewModeType;
  title: string;
}

export default defineComponent({
  name: 'CatalogHeader',
  components: { Alert, Dropdown, ColumnsSettings, DropdownAction },
  props: {
    selectedShowType: {
      type: String as PropType<CatalogViewModeType>,
      required: true,
    },
    isModeratorCatalog: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    openOverlay: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    isShowActions: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    customTitle: {
      type: String as PropType<string>,
      required: false,
      default: "Каталог",
    }
  },
  emits: ['update:openOverlay', 'update:openActions'],
  setup() {
    const { compare_enabled } = useCatalogConfig();
    const [alertData, setAlertData] = useState<Nullable<AlertData>>(null);
    const [alertSortInfo, setAlertSortInfo] = useState<boolean>(false);
    const [headerWidth, setHeaderWidth] = useState<number>(0);
    const { gtSM, lteSM, gtMD, lteMD } = useBreakpoints();
    const { availableColumns, toggleColumn } = inject(catalogInjectKey);
    const { isSupplierCatalog } = useCheckCatalogRoute();
    const { updateProductsModerationStatus } = useCatalogModals();
    const showActions = useShowActionButtonsAndCheckBox();

    return {
      updateProductsModerationStatus,
      compare_enabled,
      alertData,
      availableColumns,
      toggleColumn,
      setAlertData,
      headerWidth,
      setHeaderWidth,
      isSupplierCatalog,
      gtSM,
      lteSM,
      gtMD,
      lteMD,
      showActions,
      alertSortInfo,
      setAlertSortInfo
    };
  },
  data: () => ({
    sortTypes: SORT_TYPE,
    showTypeNames: CATALOG_SHOW_TYPE_NAMES,
    showTypes: Object.entries(CATALOG_SHOW_TYPES),
    selectedCategoryName: "",
  }),
  methods: {
    onRefViewModeBtnControl(ref: HTMLElement) {
      $(ref).tooltip();
    },
    toggleAllProducts() {
      this.$store.dispatch(
        'catalog/' + SELECT_ALL_PRODUCT,
        !this.isAllSelected
      );
    },
    onCloseAlert() {
      this.setAlertData(null);
    },
    changeShowType(e: Event, viewMode: CatalogViewModeType) {
      e.preventDefault();
      this.$store.dispatch('catalog/' + CHANGE_CATALOG_SHOW_TYPE, viewMode);
    },
    toggleSorting(e: Event, key: string) {
      e.preventDefault();
      if (this.getNextSortingByKey('price')) {
        this.setAlertSortInfo(true)
      } else {
        this.setAlertSortInfo(false)
      }
      const payload = { key: key, nextSort: this.getNextSortingByKey(key) };
      this.$store.dispatch('catalog/' + TOGGLE_SORT_BY, payload);
    },
    toggleProductModerationStatus(statusId: number) {
      this.$store.dispatch(
        'catalog/' + CHANGE_PRODUCT_MODERATION_STATUS,
        statusId
      );
    },
    toggleInStockForWarehouses(inStockForWarehouses: Nullable<boolean>) {
      this.$store.dispatch('catalog/' + CHANGE_IN_STOCK_FOR_WAREHOUSES, inStockForWarehouses);
    },
    toggleModeratorSupplier(e: Event) {
      const target = e.target as HTMLInputElement;
      const selectedSupplier = target.value ? parseInt(target.value) : null;
      this.$store.dispatch(
        'catalog/' + CHANGE_MODERATOR_SUPPLIER,
        selectedSupplier
      );
    },
    addProductsToCompareList() {
      this.$store.dispatch('catalog/' + ADD_PRODUCTS_TO_COMPARE);
    },
    getNextSortingByKey(key: string): string {
      let sorting = this.sorting[key];
      if (!sorting) sorting = null;
      const nextSort = this.sortingDirection[key][sorting];
      return nextSort;
    },
    async setSelectedCategoryName() {
      var allCategories = JSON.parse(localStorage.getItem("AllCategoriesResults"));
      if (!allCategories) {
        console.log("Finding categories without cache...");
        allCategories = await CatalogService.getAllCategories({ page: 1, level: 2 }).then(({ data}) => {
          return data;
        });
      }
      this.selectedCategoryName = allCategories.filter(cat => cat.id == this.selectedCategory)?.[0]?.name;

      if (this.gtMD && this.selectedCategory) {
        document.title = this.selectedCategoryName;
      } else {
        document.title = this.gettext(this.customTitle);
      }
    },
  },
  computed: {
    ...mapGetters('catalog', [
      'catalogShowType',
      'sorting',
      'selectedProducts',
      'selectedProductsLength',
      'categories',
      'showDemo',
      'pageSize',
      'products',
      'productModerationStatuses',
      'productModerationStatus',
      'selectedCategory',
      'customTitle',
      'moderatorSuppliers',
      'selectedSupplier',
      'inStockForWarehouses',
    ]),
    isAllSelected() {
      return (
        this.selectedProductsLength &&
        this.selectedProductsLength === this.products.length
      );
    },
    needShowControls() {
      return (
        this.projectSettings.user.is_contractor &&
        !this.catalogConfig.is_reference_mode
      );
    },
    actionsLabel() {
      return this.selectedProductsLength
        ? `${gettext('Выбранное')} (${this.selectedProductsLength})`
        : gettext('Выбранное');
    },
    isTableView() {
      return this.selectedShowType === CATALOG_SHOW_TYPES.table;
    },
    activeSort() {
      return (
        !isEmpty(this.sorting) &&
        Object.values(this.sorting)[0] &&
        this.sortItems.find(
          (item) => item[0] == Object.keys(this.sorting)[0]
        )[1]
      );
    },
    selectedProductsIds() {
      return Object.entries(this.selectedProducts as KeyVal<boolean>)
        .filter(([id, isSelected]) => isSelected)
        .map(([id, isSelected]) => Number(id));
    },
    catalogViewModes(): ViewModeMap[] {
      const viewModesMap: ViewModeMap[] = [
        {
          mode: CATALOG_SHOW_TYPES.grid,
          title: CATALOG_SHOW_TYPE_NAMES[CATALOG_SHOW_TYPES.grid].title,
        },
        {
          mode: CATALOG_SHOW_TYPES.list,
          title: CATALOG_SHOW_TYPE_NAMES[CATALOG_SHOW_TYPES.list].title,
        },
        {
          mode: CATALOG_SHOW_TYPES.table,
          title: CATALOG_SHOW_TYPE_NAMES[CATALOG_SHOW_TYPES.table].title,
        },
      ]
      return viewModesMap.filter(
        (el: ViewModeMap) => this.catalogConfig.catalog_view_modes.includes(el.mode)
      );
    },
    sortingDirection(): SortingDirections {
      return this.catalogConfig.table_settings.sortings;
    },
    sortItems(): [string, string][] {
      const sotrings = {};
      for (const [sotrName, sortLabel] of Object.entries(SORT_KEYS)) {
        if (Object.keys(this.sortingDirection).includes(sotrName)) sotrings[sotrName] = sortLabel;
      }
      return Object.entries(sotrings);
    },
    isShowSortItems(): boolean {
      return !isEmpty(this.sortItems);
    },
  },
  watch: {
    selectedCategory(mutation) {
      this.setSelectedCategoryName();
    },
  },
});
