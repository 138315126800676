
import { defineComponent, PropType } from 'vue';
import { SELECT_MODIFICATION_WAREHOUSE } from '../store/actions.type';
import { mapGetters } from 'vuex';
import { DisplayRestItemMixin } from '../mixins/display-rest.mixin';
import {
  ProductType,
  WarehouseByProductType,
} from '../../../api/schema/catalog';

export default defineComponent({
  name: 'WarehousesSelect',
  mixins: [DisplayRestItemMixin],
  props: {
    modification_id: {
      required: false,
      type: Number as PropType<number>,
    },
    selected: {
      required: false,
      type: Object as PropType<WarehouseByProductType>,
    },
    warehouses: {
      required: true,
      type: Array as PropType<WarehouseByProductType[]>,
    },
    measure_unit_name: {
      type: String as PropType<string>,
      default: gettext('шт.'),
    },
    onChange: Function as PropType<(warehouse: WarehouseByProductType) => void>,
    product: Object as PropType<ProductType>,
  },
  methods: {
    onChangeWarehouse(e: Event) {
      const target = e.target as HTMLSelectElement;
      const warehouse_id = parseInt(target.value);
      const warehouse = this.warehouses.find(
        (x) => x.id === warehouse_id,
        this.modification_id
      );
      if (!this.onChange && this.modification_id) {
        if (warehouse) {
          this.$store.dispatch('catalog/' + SELECT_MODIFICATION_WAREHOUSE, {
            modification_id: this.modification_id,
            warehouse,
          });
        }
      } else if (warehouse) {
        this.onChange && this.onChange(warehouse);
      }
    },
  },
  computed: {
    ...mapGetters('settings', ['projectSettings']),
  },
});
