import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3869e910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "property-values" }
const _hoisted_2 = {
  key: 0,
  class: "property-values-label"
}
const _hoisted_3 = { class: "property-values-table" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!!_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("tbody", null, [
          _renderSlot(_ctx.$slots, "top-rows", {}, undefined, true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyValuesData, (data, index) => {
            return (_openBlock(), _createElementBlock("tr", { key: index }, [
              _createElementVNode("td", null, _toDisplayString(data.label), 1),
              _createElementVNode("td", null, _toDisplayString(data.value), 1)
            ]))
          }), 128)),
          _renderSlot(_ctx.$slots, "bottom-rows", {}, undefined, true)
        ])
      ]),
      (_ctx.isShowAccordion)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "accordion-controller",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleAccordion && _ctx.toggleAccordion(...args)))
          }, _toDisplayString(_ctx.isAccordionOpened ? _ctx.gettext('Показать меньше') : _ctx.gettext('Показать все')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.propertyValuesData.length === 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.gettext('Нет характеристик')), 1))
      : _createCommentVNode("", true)
  ], 64))
}