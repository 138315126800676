import { defineComponent, PropType } from 'vue';
import { LocationQueryRaw } from 'vue-router';
import { mapGetters } from 'vuex';
import { CategoryNodeType } from '../../../types/app.types';
import {
  getQueryObjectFromFormData,
  parseQuerySting,
} from '../../../common/utils';
import {SELECT_CATEGORY} from "@catalog/store/actions.type";

export const CategoryNodeMixin = defineComponent({
  props: {
    offset: {
      type: Number as PropType<number>,
      required: false,
      default: 0,
    },
    categoryNode: {
      type: Object as PropType<CategoryNodeType>,
      required: true,
    },
    onToggleOpen: {
      required: false,
      type: Function as PropType<(nodeId: number, opened: boolean) => void>,
    },
    routeName: {
      type: String as PropType<string>,
      default: 'Category',
      required: false,
    },
  },
  methods: {
    hasChildren() {
      return this.categoryNode.children.length > 0;
    },
    toggleSelectedCategory(e: Event) {
      e.preventDefault();
      // disable open category
      this.$store.dispatch('catalog/' + SELECT_CATEGORY, {
        category_id: this.categoryNode.id,
      });
      this.routeToCategory();
    },
    toggleOpened(e: Event) {
      e.preventDefault();
      // @ts-expect-error
      const state = !this.opened;
      // @ts-expect-error
      this.setOpened(state);
      if (this.isRoot) {
        this.onToggleOpen &&
          this.onToggleOpen(this.categoryNode.id, state);
      } else if (!this.hasChildren()) {
        this.toggleSelectedCategory(e);
      }
    },
    getCategoryText(category: CategoryNodeType, limit = 50) {
      if (!this.catalogConfig.show_count_products_category_in_sidebar) {
        return this.truncateString(category.name, limit);
      }
      return `${this.truncateString(category.name, limit)}`;
    },
    routeToCategory() {
      const query = getQueryObjectFromFormData(parseQuerySting()) as LocationQueryRaw;
      this.$router.replace({
        name: this.routeName,
        params: {
          id: this.categoryNode.id,
        },
        query,
      });
    },
    onToggleOpenChild(child_id: number, opened: boolean) {
      // @ts-expect-error
      this.setOpened(opened);
      this.onToggleOpen && this.onToggleOpen(this.categoryNode.id, opened);
    },
    onRefChild(ref: any) {
      // @ts-expect-error
      this.childRefs.push(ref);
    },
  },
  created() {
    if (this.isActive) {
      // @ts-expect-error
      this.setOpened(true);
      this.onToggleOpen && this.onToggleOpen(this.categoryNode.id, true);
    }
  },
  computed: {
    ...mapGetters('catalog', ['selectedCategory', 'selectedSupplier']),
    isRoot() {
      return !this.categoryNode.parent_id;
    },
    isActive() {
      return (
        this.selectedCategory?.toString() === this.categoryNode.id.toString()
      );
    },
    nextOffset() {
      return this.offset + 1;
    },
  },
});
