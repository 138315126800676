import { CatalogSettingsType } from '../catalog';

export type AllAppSettingsType = {
  catalog?: Nullable<CatalogSettingsType>;
};

export enum FeedbackType {
  PRODUCT = 0,
  CATEGORY = 1,
  OTHER = 10,
}

export type FeedbackItem = {
  id: number;
  type: FeedbackType;
  btn_title: string;
  head_title: string;
  head_subtitle: string;
  start_text: string;
};

export type UserSettingsType = {
  /**
   * Является ли покупателем
   */
  is_contractor: boolean;
  /**
   * Есть ли доступ к разделу Юр. Лицо
   */
  access_to_company_profile: boolean;
  /**
   * ID текущего покупателя
   */
  contractor_id: Nullable<number>;
  /**
   * Является ли поставщиком
   */
  is_supplier: boolean;
  /**
   * Может ли пользователь редактировать категории поставщика
   */
  can_edit_s_categories: boolean;
  /**
   * ID текущего поставщика
   */
  supplier_id: Nullable<number>;
  /**
   * Контролирующий орган
   */
  is_control_manager: boolean;
  /**
   * Модерирующий орган
   */
  is_moderator: boolean;
  /**
   * Бренд-менеджер
   */
  is_brand_manager: boolean;
  /**
   * Маркетолог
   */
  is_marketing_manager: boolean;
  /**
   * Админ компании
   */
  is_company_admin: boolean;
  /**
   * Название компании
   */
  company_name: Nullable<string>;
  /**
   * Прошел аутентификацию
   */
  is_auth: boolean;
  /**
   * Полное имя пользователя
   */
  full_name: Nullable<string>;
  /**
   * Проверяем права доступа к каталогу
   */
  can_use_catalog: boolean;
  /**
   * Категории, доступные для бренд менеджера (если бренд менеджера)
   */
  brand_manager_categories: number[];
  /**
   * Номер телефона AD пользователя
   */
  ad_contact_phone_number: string;
  /**
   * Номер телефона пользователя НЕ AD
   */
  phone: string;
};

export type SettingsType = {
  /**
   * Текущий язык
   */
  lang: string;
  /**
   * Используется дефолтный язык
   */
  use_default_lang: boolean;
  /**
   * User config
   */
  user: UserSettingsType;
  /**
   * Портал или маркетплейс(по умолчанию)
   */
  is_portal: boolean;
  forms: {
    /**
     * Максимальный размер чанка (bytes)
     */
    chunk_size: number;
  };
  /**
   * Один поставщик на портале
   */
  single_supplier_installation: boolean;
  /**
   * Эталонный каталог
   */
  is_reference_mode: boolean;
  /**
   * Настройки форм обратной связи
   */
  feedback: {
    items: FeedbackItem[];
  };
  /**
   * Зарегистрированные приложения
   */
  registered_apps: string[];
  /**
   * Настройки зарегистрированных приложений
   */
  apps_configs: AllAppSettingsType;
};
