import { API_V1_URL_PATH, API_V1_URL_PATH_INNER } from '../../../api/urls';

export default {
  login: `${API_V1_URL_PATH_INNER}/catalog/logino/`,
  products: `${API_V1_URL_PATH}/catalog/products/`,
  vendorProducts: `${API_V1_URL_PATH}/catalog/brand-products/`,
  productDetail: `${API_V1_URL_PATH}/catalog/detail-product/{id}`,
  propertyValues: `${API_V1_URL_PATH}/catalog/property-values/`,
  settings: `${API_V1_URL_PATH}/catalog/settings/`,
  getFavorites: `${API_V1_URL_PATH}/wishlist/me/`,
  clearFavorites: `${API_V1_URL_PATH}/wishlist/clear/`,
  addProductToFavorite: `${API_V1_URL_PATH}/wishlist/add/`,
  deleteProductFromFavorite: `${API_V1_URL_PATH}/wishlist/delete/`,
  getCompareItems: `${API_V1_URL_PATH}/products/compare_list/`,
  addProductsToCompare: `${API_V1_URL_PATH}/products/add_compare_multi/`,
  deleteProductsFromCompare: `${API_V1_URL_PATH}/products/remove_compare_multi/`,
  clearCompareList: `${API_V1_URL_PATH}/products/clear_compare_list/`,
  addProductsToCart: `${API_V1_URL_PATH_INNER}/cart/catalog/multi-add/`,
  sendPriceOffer: `${API_V1_URL_PATH}/products/send_price_request/`,
  addToWaitingList: `/waitinglist/add/`,
  deleteFromWaitingList: `/waitinglist/remove/`,
  getCategories: `${API_V1_URL_PATH}/catalog/categories/`,
  getAllCategories: `${API_V1_URL_PATH}/catalog/all_categories/`,
  getPromotionsList: `${API_V1_URL_PATH}/promo/`,
  getStickers: `${API_V1_URL_PATH}/stickers/`,
  getBanners: `${API_V1_URL_PATH}/banners/`,
  getBrands: `${API_V1_URL_PATH}/brands/`,
  getCatalogExtraData: `${API_V1_URL_PATH}/catalog/extra-data/`,
  getPriceMinMax: `${API_V1_URL_PATH}/catalog/price-min-max/`,
  getProductsCountBySupplier: `${API_V1_URL_PATH}/catalog/products-count-by-supplier/`,
  getSupplierPriceTypes: `${API_V1_URL_PATH}/catalog/supplier/price-types`,
  getSupplierWarehouses: `${API_V1_URL_PATH}/catalog/supplier/warehouses`,
  changeCurrentSupplierPriceTypes: `${API_V1_URL_PATH}/catalog/supplier/change-current-price-type/{price_type_id}`,
  getProductModerationStatuses: `${API_V1_URL_PATH}/product_moderation_statuses`,
  getModeratorSuppliers: `${API_V1_URL_PATH}/moderator_suppliers`,
  updateProductStatus: `${API_V1_URL_PATH}/update_product_status`,
  getWarehouses: `${API_V1_URL_PATH}/catalog/warehouses/`,
};
